<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Plan d'actions</li>
                </ol>
                <h2>{{ dossier.name }}</h2>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="row">
                    <div id="side1" class="col-md-12 col-sm-12" >
                        <div class="card" style="background:#f7f7f7;">
                            <div class="card-body">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-12">
                                            <div style="height: auto; ">
                                                <img style="max-width: 100%; width: fit-content; max-height: 200px; height: fit-content;" :src="dossier.photo" alt="">
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-12 infos">
                                            <span class="badge badge-success">ETAPE {{ dossier.step }}</span>
                                            <p>PROMOTEUR : <span class="text-bold">{{ dossier.owner?dossier.owner.name:'-' }}</span></p>
                                            <p>CONSULTANT : <span class="text-bold">{{ dossier.consultant?dossier.consultant.name:'-' }}</span></p>
                                            <p>DATE DE CREATION : <span class="value"> {{ dossier.created }}</span></p>
                                            <p><span>VILLE : </span> {{ dossier.ville?dossier.ville.name:'-' }}</p>
                                            <p>SECTEUR :  <span class="text-bold">{{ dossier.secteur?dossier.secteur.name:'-' }}</span></p>
                                            <input type="hidden" id="pl_id" value="{{ dossier.plan_id }}"/>

                                            
                                        </div>
                                        <div class="col-md-4 col-sm-12">
                                            <div class="card bg-success-subtle">
                                                <div class="card-body">
                                                    <p>{{ dossier.description }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div id="plan" class="table-responsive">
                        <table class="table table-sm table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>TYPE DE RISQUE</th>
                                    <th>DEFAILLANCE</th>
                                    <th>ACTION D'AMELIORATION</th>
                                </tr>
                            </thead>
                            <tbody id="tab">
                                <tr v-for="(ligne,index) in plan.lignes" :key="index" :data-id="ligne.id">
                                    <td style="width: 13%">{{ ligne.type.name }}</td>
                                    
                                    <td><router-link :to="{name:'owner_pligne', params: {tkn: ligne.token}}">{{ligne.risque.name }}</router-link></td>
                                    <td style="width: 37%">{{ligne.amelioration }}</td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
        </div>
    </main>


</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"PlanPreseed",
    props: ['tkn'],
    components:{
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            dossier:{},
            plan:{
                id:0,
                lignes:[],
            },
            toaster: createToaster({ position:'top-right'}),
            componentKey: 0,
        }
    },
    methods:{
        setData(data){
            this.dossier = data.dossier;

        },
        async load(){
            let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    //onCancel: this.onCancel,
                });
            await axios.get(this.path+'owner/preseeds/'+this.tkn)
                        .then((res)=>{
                            this.setData(res.data)
                            axios.get(this.staging+'plan/'+res.data.dossier.plan_id)
                            .then((rep)=>{
                                this.plan = rep.data;
                                console.log(rep.data);
                            })
                            .catch((err)=>{
                                console.log(err);
                                this.toaster.error("Le chargement du plan d'action a echoue !!!");
                            })
                        })
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        async reload(){
            await axios.get(this.path+'owner/preseeds/'+this.tkn)
                        .then((res)=>{
                            this.setData(res.data)
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.dossier))
        
    }

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    a {
        color: #3d9970;
        
    }
    .infos p{
        margin-bottom: 2px;
        font-size: smaller;
    }
    .table thead th{
        font-size: smaller;
    }
</style>