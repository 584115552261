<template>
  <div id="app">
    <div>
            <!-- ======= Top Bar ======= -->
    <section id="topbar" class="d-flex align-items-center">
      <div class="container d-flex justify-content-center justify-content-md-between">
        <div class="contact-info d-flex align-items-center">
          <i class="bi bi-envelope d-flex align-items-center"><a href="mailto:contact@investorsclub4africa.org">contact@investorsclub4africa.org</a></i>
          <i class="bi bi-phone d-flex align-items-center ms-4"><span>+242 06 970 48 98</span></i>
          <i class="bi bi-file-code d-flex align-items-center ms-4"><span style="font-family: change; font-size: small;">&numero; d’immatriculation: 0073/MIDDL/DPN/P/SG/DDAT-SR</span></i>
        </div>
        <div class="social-links d-none d-md-flex align-items-center">
          <a href="https://instagram.com/investorsclub4africa?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D" class="twitter"><i class="bi bi-twitter"></i></a>
          <a href="https://www.facebook.com/profile.php?id=100092560654171" class="facebook"><i class="bi bi-facebook"></i></a>
          <a href="https://www.linkedin.com/company/91621737/admin/feed/posts/ " class="linkedin"><i class="bi bi-linkedin"></i></a>
        </div>
      </div>
    </section>
    <!-- ======= Header ======= -->
    <div id="header"  class="d-flex align-items-center">
      <div class="container d-flex justify-content-between align-items-center">
        <div class="logo">
          <h1><router-link to="/">{{ title }} - <strong> {{ entreprise?entreprise.name:'' }} </strong></router-link></h1>
          <!-- Uncomment below if you prefer to use an image logo -->
          <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
        </div>

        <nav v-if="!authenticated" id="navbar" class="navbar">
          <FrontNavbar />
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->
        <nav v-if="user.role_id==1" id="navbar" class="navbar">
          <SuperNavbar />
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->
        <nav v-if="user.role_id==11" id="navbar" class="navbar">
          <RevendeurNavbar />
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->
        <nav v-if="user.role_id==2" id="navbar" class="navbar">
          <ConsultantNavbar />
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->
        <nav v-if="user.role_id == 3" id="navbar" class="navbar">
          <OwnerNavbar />
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->
        <nav v-if="user.role_id == 7" id="navbar" class="navbar">
          <AdvisorNavbar />
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->
        <nav v-if="user.role_id == 5" id="navbar" class="navbar">
          <CompanyNavbar />
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->
        <nav v-if="user.role_id == 12" id="navbar" class="navbar">
          <SupplierNavbar />
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->
        <nav v-if="user.role_id == 13" id="navbar" class="navbar">
          <CgaNavbar />
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->
      </div>
    </div><!-- End Header -->
    </div>
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
  import Footer from './components/Layout/Footer/Footer.vue'
  import SuperNavbar from './components/Navbar/Super.vue'
  import RevendeurNavbar from './components/Navbar/Revendeur.vue'
  import OwnerNavbar from './components/Navbar/Owner.vue'
  import ConsultantNavbar from './components/Navbar/Consultant.vue'
  import AdvisorNavbar from './components/Navbar/Advisor.vue'
  import CompanyNavbar from './components/Navbar/Company.vue'
  import SupplierNavbar from './components/Navbar/Supplier.vue'
  import CgaNavbar from './components/Navbar/Cga.vue'
  import FrontNavbar from './components/Navbar/Front.vue'
  import axios from "axios";
  import {mapActions} from 'vuex'
  import path from './services/path'
export default {
    name:'AppLayout',
  components: {
      Footer,
      SuperNavbar,
      RevendeurNavbar,
      FrontNavbar,
      ConsultantNavbar,
      OwnerNavbar,
      AdvisorNavbar,
      CompanyNavbar,
      SupplierNavbar,
      CgaNavbar,
  },
    data: function(){
      return {
        authenticated:this.$store.state.auth.authenticated?this.$store.state.auth.authenticated:false,
        user:this.$store.state.auth.user!=null?this.$store.state.auth.user:{},
        entreprise:this.$store.state.auth.entreprise,
        title: process.env.VUE_APP_TITLE
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.user;
      },
    },
    methods: {
      ...mapActions({
                signOut:"auth/signOut"
  }),
            async logout(){
                await axios.post(path.getApi()+'auth/logout').then(()=>{
                    this.signOut()
                    this.authenticated = false;
                    window.location.href="/"
                    //this.$router.push({name:"home"})
                })
            }
    },
    mounted(){
     // console.log(this.currentUser);
}
  };
</script>
<style scoped>
    #content{
        min-height: 200px;
    }

    .navbar a{
        text-decoration: none;
    }

    #header .logo h1 a, #header .logo h1 a:hover{
      color: #fff;
      text-decoration: none;
    }


    #header{
      background: #013E01;
      
    }
    #header a{
      color: #fff;
    }

    .breadcrumbs a{
      text-decoration:none;
      color: #013E01;
    }
</style>
<style>

@font-face {
  font-family: "changa";
  src: local("changa"),   url(./assets/fonts/Changa-VariableFont_wght.ttf) format("truetype");
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6,p,a{
  font-family: changa;
}
body{
  font-family: changa;
}

#topbar, #footer{
  background: #ca2e05;
}

#topbar .contact-info i {
  font-style: normal;
  color: #fff;
}

.navbar a, .navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: changa;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  transition: 0.3s;
}

#footer .footer-top{
  background: #013E01;
}
</style>
