<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Dossiers SERIE A</li>
                </ol>
                <h2>{{ dossier.name }}</h2>
                <div class="">
                    <div class="dropdown">
                        <a class="dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Actions</a>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#bmModal">1- EDITER LE MODELE ECONOMIQUE</a></li>

                            <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#audioModal">FICHIERS AUDIO</a></li>
                            <li><router-link class="dropdown-item" :to="{name:'owner_plan', params: {tkn: dossier.token}}">PLAN D'ACTION</router-link></li>

                        </ul>
                    </div>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="row">
                    <div id="side1" class="col-md-12 col-sm-12" >
                        <div class="card" style="background:#f7f7f7;">
                            <div class="card-body">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-12">
                                            <div style="height: auto; ">
                                                <img style="max-width: 100%; width: fit-content; max-height: 220px; height: fit-content;"  :src="dossier.photo" alt="">
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-12 infos">
                                            <span class="badge badge-success">ETAPE {{ dossier.step }}</span>
                                           
                                            <p>CONSULTANT : <span class="text-bold">{{ dossier.consultant?dossier.consultant.name:'-' }}</span></p>
                                            <p>DATE DE CREATION : <span class="value"> {{ dossier.created }}</span></p>
                                            <p><span>VILLE : </span> {{ dossier.ville?dossier.ville.name:'-' }}</p>
                                            <p>SECTEUR :  <span class="text-bold">{{ dossier.secteur?dossier.secteur.name:'-' }}</span></p>
                                            <input type="hidden" id="pl_id" value="{{ dossier.plan_id }}"/>

                                            
                                        </div>
                                        <div class="col-md-4 col-sm-12">
                                            <div class="card bg-light">
                                                <div class="card-body">
                                                    <p>{{ dossier.description }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div id="side2" style="overflow: scroll" class="col-md-12 col-sm-12">
                        <div class="">
                            
                            <div class="">
                            <div class="" id="">
                                
                                <div class="" id="custom-tabs-four-profile" role="tabpanel" aria-labelledby="custom-tabs-four-profile-tab">
                                    <fieldset>
                                        <legend>Cartographie des risques</legend>
                                        <Diagnostic :choices="choices" />
                                    </fieldset>
                                   
                                    <div>
                                    </div>
                                </div>
                  
                            </div>
                            </div>
                            <!-- /.card -->
                        </div>
                    </div>
                </div>
        </div>
        <div class="modal fade" id="bmModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">LE MODELE ECONOMIQUE</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" id="bmclose" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <EditBM :data="modele" @saved="reload" />
                    </div>
                </div>
            </div>
        </div>
    </main>




</template>
<script>
import axios from "axios";
import Diagnostic from "../../Includes/Diagnostic.vue";
import { createToaster } from "@meforma/vue-toaster";
import EditBM from "../../Includes/EditBM.vue";
export default {
    name:"IndexPreseed",
    props: ['tkn'],
    components:{
        Diagnostic,
        EditBM,
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            dossier:{},
            modele:{},
            choices:[],
            toaster: createToaster({ position:'top-right'}),
            consultant_id:0,
            componentKey: 0,
        }
    },
    methods:{
        setData(data){
            this.choices = data.choices;
            this.dossier = data.dossier;
            this.modele = data.dossier.modele;
            console.log(this.modele);
        },
        async load(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.get(this.path+'owner/startups/'+this.tkn)
                        .then((res)=>this.setData(res.data))
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        async reload(){
            await axios.get(this.path+'owner/startups/'+this.tkn)
                        .then((res)=>{
                            this.setData(res.data)
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.dossier))
        
    }

}
</script>

<style scoped>

</style>