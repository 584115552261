<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>LISTE DES ENTREPRISES</li>
                </ol>
                <div class="d-flex justify-space-between">
                    <h2>LISTE DES ENTREPRISES</h2>
                    <div class="mr-3"><router-link class="btn bg-green-darken-4" to="/supplier/entreprises/create"><i class="bi bi-plus-square-fill" title="Creer un nouveau dossier"></i> NOUVELLE ENTREPRISE</router-link></div>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="d-flex justify-center gap-3 flex-wrap">
                <div v-for="(dossier,index) in entreprises" :key="index" class="card bg-light-green-lighten-5">
                    <div class="card-body">
                        <router-link :to="{name:'supplier_entreprise',params:{tkn:dossier.token}}">
                            <div class="text-center">
                                <div>
                                    <img class="img img-circle" :src="dossier.photo" style="height: 100px; width: 100px;" alt="">
                                </div>
                                <h5>{{ dossier.name }}</h5>
                                <div><small>{{ dossier.phone }}</small></div>
                                <div> <strong>{{ dossier.email }}</strong></div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import axios from "axios";
export default {
    name:"IndexPreseed",
    data(){
        return {
            user:this.$store.state.auth.user,
            entreprises:[]
        }
    },
    methods:{
        async load(){
            await axios.get(this.path+'supplier/entreprises')
                        .then((res)=>this.entreprises=res.data)
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load();
    },
    computed:{
    }

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    a{
        color: #3d9970;
        text-decoration: none;
    }

    div.card-body a {
        color: inherit;
    }

    a.btn{
        color:#fff;
    }
</style>