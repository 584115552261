<template>
<section id="hero">
    <div class="hero-container">
        <div>
            <!-- Slider main container -->
            <div class="swiper">
                <!-- Additional required wrapper -->
                <div class="swiper-wrapper">
                    <!-- Slides -->
                    <div id="slide1" class="swiper-slide">
                        <div class="">
                            <div class="carousel-container">
                                <div class="carousel-content">
                                    <h2 class="animate__animated animate__fadeInDown"><span>FINANCEZ  VOS PROJETS </span> EN PHASE DE <span>CREATION</span></h2>
                                    <p class="animate__animated animate__fadeInUp">
                                        <ul style="color: #013e01; font-weight: bold; list-style: none;">
                                            <li><span style="color: #ca2e05;"> Structurez vos projets de startup</span> et professionnalisez vos campagnes de love money</li>
                                            <li>Découvrez les solutions de financements de projets nécessaire à la réalisation des projets d’infrastructures</li>  
                                        </ul>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="slide2" class="swiper-slide">
                        <div  class="" style="background-image: url({{ asset('Eterna/assets/img/slide/slide-2.jpg')}})">
                            <div class="carousel-container">
                            <div class="carousel-content">
                                <h2 class="animate__animated fanimate__adeInDown">FINANCEZ <span>VOS PROJETS </span> EN PHASE DE <span>CROISSANCE</span></h2>
                                <p class="animate__animated animate__fadeInUp">Etablissez vos besoins de financements et négociez les meilleures conditions avec notre réseau de business angels, de Venture Capital et de Banques.</p>

                            </div>
                            </div>
                        </div>
                    </div>
                    <div id="slide3" class="swiper-slide">
                        <div class="">
                                <div class="carousel-container">
                                    <div class="carousel-content">
                                        <h2 class="animate__animated animate__fadeInDown"><span>FINANCEZ </span> VOS PROJETS DE <span>RESTRUCTURATION</span></h2>
                                        <p class="animate__animated animate__fadeInUp">Elaborez votre plan de restructuration et négociez les meilleures conditions avec notre réseau d’investisseurs.</p>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <!-- If we need pagination -->
                <div class="swiper-pagination"></div>

                <!-- If we need navigation buttons -->
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>

                <!-- If we need scrollbar -->
                <div class="swiper-scrollbar"></div>
            </div>
        </div>
    </div>
</section>

</template>

<script>
    import Swiper from 'swiper/bundle';
    import 'swiper/css';
    import 'swiper/css/navigation';
    import 'swiper/css/pagination';
    export default{
        name:'MySwiper',
        nethods:{
            load(){
                //const swiper = new Swiper('.hero-container');
            }
        },
        mounted(){
            //const swiper = 
            new Swiper('.swiper',
                {
                    speed: 800,
                    loop: true,
                    autoplay: {
                        delay: 15000,
                        disableOnInteraction: false
                    },
                    slidesPerView: 'auto',
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                        clickable: true
                    },
                      // Navigation arrows
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },

                }
            );
        }
    }

</script>

<style scoped>
    .swiper {
        width: 100%;
        height: 70vh;
    }

    .swiper-button-next, .swiper-button-prev{
        color:#3d9970;
    }
   div.swiper-pagination>span.swiper-pagination-bullet-active {
        opacity: var(--swiper-pagination-bullet-opacity, 1);
        background:#3d9970;
    }

    #slide1{
        background-image:url("./slide/slide-1.jpg")
    }

    #slide2{
        background-image:url("./slide/slide-3.jpg")
    }

    #slide3{
        background-image:url("./slide/slide-1.jpg")
    }

    .swiper-pagination-bullet-active{
        background-color: #3d9047;
    }
</style>
