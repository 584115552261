<template>
<div>
    <MySwiper></MySwiper>
    <main id="main">
  

        <!-- ======= Missions Section ======= -->
        <section id="about" class="about">
        <div class="container">

        <div class="section-title">
            <h2>COMMENT CA MARCHE ?</h2>
            <div class="pt-4 pt-lg-0 content">
                <ul>
                    <li>
                        <div class="section-subtitle">
                            <i class="">1-</i>Créez votre compte de gestion de projets
                        </div>
                        <p>Ce compte vous permettra d’effectuer vos dépôts de dossiers de financement</p>
                        
                    </li>
                    <li>
                        <div class="section-subtitle">
                            <i class="">2-</i>Structurez vos dossiers avec l’aide d’un conseiller 
                        </div>
                        <p>La structuration de votre dossier est importante pour le succès de vos opérations de mobilisation de capitaux. Un conseiller vous accompagne dans cette étape. </p>
                    </li>
                    <li>
                        <div class="section-subtitle">
                            <i class="">3-</i>Mobilisez des capitaux
                        </div>
                        <p>La mobilisation de capitaux est un processus jalonné d’étapes importantes. Un conseiller vous accompagne dans cette étape. </p>
                    </li>
                    <li>
                        <div class="section-subtitle">
                            <i class="">4-</i>Informez les investisseurs de l’évolution de vos projets
                        </div>
                        <p>Le processus de communication avec les investisseurs après le financement est une étape cruciale et contribue au maintien de la confiance entre les parties. Un conseiller vous accompagne dans cette étape. </p>
                    </li>
                </ul>
            </div>
        </div>

        </div>
        </section><!-- End Missions Section -->



    </main><!-- End #main -->
</div>
</template>

  <script>
    import MySwiper from './Includes/MySwiper.vue'
    import Swiper from 'swiper/bundle';
    import 'swiper/css';
    import 'swiper/css/navigation';
    import 'swiper/css/pagination';

  export default {
    name: 'HomeComponent',
    components: {
      MySwiper,
    },
    methods:{
        intClients(){
            new Swiper('.clients-slider', {
                speed: 400,
                loop: true,
                autoplay: {
                delay: 5000,
                disableOnInteraction: false
                },
                slidesPerView: 'auto',
                pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
                },
                breakpoints: {
                320: {
                    slidesPerView: 2,
                    spaceBetween: 40
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 60
                },
                640: {
                    slidesPerView: 4,
                    spaceBetween: 80
                },
                992: {
                    slidesPerView: 6,
                    spaceBetween: 120
                }
                }
            });
        }
    },
  }
  </script>

<style scoped>
   .section-subtitle{
    font-weight: 800;
   }

   #about ul{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        text-align: start;
   }

   #about ul>li{
    padding: 2rem;
   }


</style>
