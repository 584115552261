<template>
    <ul>
        <li><router-link to="/advisor/dashboard"> ACCUEIL</router-link></li>
            <li class="dropdown">
                <a class="">
                    <div class="text-center">
                        <p class="m-0">AFFAIRES<i class="bi bi-chevron-down"></i></p>
                    </div>
                </a>
                <ul>
                    <li><router-link to="/advisor/incubateurs">LICENSES</router-link></li>
                   
                </ul>
            </li>
            <div style="display: inherit;" v-if="authenticated">
            <li class="dropdown">
                <a class="">
                    <div class="text-center">
                        <i style="font-size:25px;" class="bi bi-person"></i><i class="bi bi-chevron-down"></i>
                    </div>
                </a>
                <ul>
                    <li><router-link to="/profil">Profil</router-link></li>
                   
                    <li><a class="dropdown-item" href="javascript:void(0)" @click="logout">Se deconnecter</a></li>
                </ul>
            </li>
            </div>
    </ul>
</template>
<script>
    import {mapActions} from 'vuex';
    import axios from "axios";
    export default{
        name:'ConsultantNavbar',
        data: function(){
            return {
                authenticated:this.$store.state.auth.authenticated?this.$store.state.auth.authenticated:false,
            }
        },
        methods:{
            ...mapActions({
                signOut:"auth/signOut"
            }),
            async logout(){
                await axios.post(this.path+'auth/logout').then(()=>{
                    this.signOut()
                    this.authenticated = false;
                    window.location.href="/";
                })
                .catch(()=>window.location.href="/")
            }
        }
    }

</script>
<style scoped>
    .navbar a{
        text-decoration: none;
    }
</style>