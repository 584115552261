<template>
  <div>
      <h3 class="text-center">EDITION DES INFORMATIONS DU DOSSIER</h3>
      <h5 class="text-center">DOSSIER : {{ dossier.name }}</h5>
        <div style="max-width: 600px; margin: 30px auto;" class="container">
          <div class="row">
          <div class="col-md-5 col-sm-12">
              <div class="form-group">
               <div class="">
                 <label class="label">INTITULE DU PROJET</label>
                 <input name="name" id="name" class="form-control" v-model="dossier.name"  type="text" placeholder="Intitule du projet">
             </div>
              </div>
          </div>
          <div class="col-md-4 col-sm-12">
              <div class="form-group">
                  <label class="control-label">PHOTO DU PROJET</label>
                  <input id="photo" name="imageUri" @change="handlePhoto($event)"  ref="photo" required="required" type="file"  class="form-control">
              </div>
          </div>

          <div class="col-md-3 col-sm-12">
              <div class="form-group">
                  <label class="control-label">PAYS</label>
                  <select class="form-control" @change="changePays($event)" name="pay_id" id="pay_id">
                       <option value="">Choisir le pays ...</option>
                        <option v-for="p in pays" :value="p.id" :key="p.id" >{{ p.name }}</option>
                    
                  </select>
              </div>
          </div>
          <div class="form-group">
             <label for="">Petite description du projet</label>
             <textarea name="description" v-model="dossier.description" class="form-control" id="" cols="30" rows="5"></textarea>
          </div>
      </div>
      <div class="text-center">
        <button @click="save()" class="btn btn-sm btn-success"><i class="bi bi-save"></i> ENREGISTRER</button>
      </div>
      </div>
    </div>   
</template>

<script>
import axios from 'axios';
import { createToaster } from "@meforma/vue-toaster";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    props: ['tkn'],
    name: "PreseedUpdateInfos",
    data() {
      return {
        step: 1,
        toaster: createToaster({ position:'top-right'}),
        editor: ClassicEditor,
        editorConfig: this.editorConfig,
        dossier:{
          name:null,
          secteur_id:0,
          ville_id:0,
          description:null,
        },
        photo:null,
        pays:[],
      };
    },
  
    mounted(){
        axios.get(this.path+'countries')
        .then((res)=>this.pays = res.data)
        .catch((err)=>console.error(err));

        this.load();
        
    },
    
    methods: {
      handlePhoto(event){
        this.photo = event.target.files[0];
      },
      changePays(event){
        this.dossier.pay_id = event.target.value;
      },
  
      async save(){
        let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
        const form = new FormData();
        if(this.photo != null){
          form.append("photo",this.photo);
        }
        form.append("dossier",JSON.stringify(this.dossier));
        axios.post(this.path+"owner/preseed/infos", form, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
              function(event){
                this.progress = Math.round((100 * event.loaded) / event.total);
              }
            })
            .then((response)=>{
                console.log(response.data)
                this.$router.push({path:'/owner/preseeds'});
                this.reload();
              })
            .catch((err)=>{
              console.error(err);
              this.toaster.error("Echec lors de l'enregistrement du dossier !!!");
            })
            .finally(()=>loader.hide());

      },

      async reload(){
            await axios.get(this.path+'owner/preseeds/'+this.tkn)
                        .then((res)=>{
                            this.dossier = res.data.dossier;
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        },

      async load(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.get(this.path+'owner/preseeds/'+this.tkn)
              .then((res)=>this.dossier=res.data.dossier)
              .catch((err)=>console.error(err))
              .finally(()=>loader.hide());
        },
      
    },
  };

</script>