<template>
<div class="modal fade" id="besoinModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">DESCRIPTION DU BESOIN</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form  @submit.prevent="submit" method="post">
                        <div class="form-group">
                            <ckeditor :editor="editor" v-model="text" :config="editorConfig"></ckeditor>
                        </div>

                       <div class="form-group">
                         <button class="btn btn-success" data-bs-dismiss="modal" type="submit"><i class="bx bi-save"></i> ENREGISTRER</button>
                       </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    export default{
        name:'CollateralComponent',
        props:{
            dossier:{
                'type':Object,
                'required':true,
            }
        },
        data(){
            return {
                text:'',
                editor: ClassicEditor,
                editorConfig: this.editorConfig,

            }
        },
        methods:{
            fileChange(event){
                this.file = event.target.files[0];
            },
            submit(){
                   // document.getElementById('closecol').click();
                    let loader = this.$loading.show({
                        container: this.fullPage ? null : this.$refs.formContainer,
                        canCancel: false,
                    });
                    let form = new FormData();
                    form.append("text",this.text);
                    form.append("id",this.dossier.id);
                    axios.post(this.path+'cga/pme/besoin',form,{
                        headers:{
                            'Accept':'multipart/form-data'
                        }
                    })
                    .then((res)=>{
                        console.log(res)
                        this.$emit('success');
                    })
                    .catch((err)=>{
                        this.$emit('error')
                        //this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
                        console.log(err)
                    })
                    .finally(()=>loader.hide())
                
            }
        },
        computed:{
        },
        watch:{
            dossier(newValue,oldValue){
                console.log(oldValue)
                console.log(newValue)
                this.text = this.dossier.besoin;
            }
        },
        mounted(){
            this.text = this.dossier.besoin;
        }
    }

</script>

<style scoped>


</style>