<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Programmes / Cohorte</li>
                </ol>
                <h2>Cohorte : {{ cohorte.name }}</h2>
                
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="row">
                    <div id="side1" class="col-md-12 col-sm-12" >
                        <div class="card">
                            <div class="card-body">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-12">
                                            <div style="height: auto; ">
                                                <img style="width: 100%;" :src="cohorte.photo" alt="">
                                            </div>
                                        </div>
                                       
                                        <div class="col-md-8 col-sm-12">
                                            <div class="card bg-light">
                                                <div class="card-body">
                                                    <p>{{ cohorte.description }}</p>
                                                </div>
                                                <div class="card-footer">
                                                    <ul class="list-inline">
                                                        <li class="list-inline-item badge bg-danger">
                                                            <strong>{{ cohorte.preseeds.length }} <small>Dossiers preseed</small></strong>
                                                        </li>
                                                        <li class="list-inline-item badge bg-warning">
                                                            <strong>{{ cohorte.seeds.length }} <small>Dossiers seed</small></strong>
                                                        </li>
                                                        <li class="list-inline-item badge bg-success">
                                                            <strong>{{ cohorte.startups.length }} <small>Dossiers serie A</small></strong>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div id="side2" style="overflow: scroll" class="col-md-12 col-sm-12">
                        <fieldset v-if="cohorte.preseeds.length>0">
                            <legend>LISTE DES DOSSIERS PRESEED</legend>
                            <div class="d-flex justify-content-center gap-2" style="width:240px;">
                                <div v-for="(dossier,index) in cohorte.preseeds" :key="index" class="">
                                    <DossierCohorteItem @deleted="reload" :dossier="dossier" :cohorte_id="cohorte.id" :type="'PRESEED'"></DossierCohorteItem>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset v-if="cohorte.seeds.length>0">
                            <legend>LISTE DES DOSSIERS SEED</legend>
                            <div class="d-flex justify-content-center gap-2" style="width:240px;">
                                <div v-for="(dossier,index) in cohorte.seeds" :key="index" class="">
                                    <DossierCohorteItem @deleted="reload" :dossier="dossier" :cohorte_id="cohorte.id" :type="'SEED'"></DossierCohorteItem>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset v-if="cohorte.startups.length>0">
                            <legend>LISTE DES DOSSIERS SERIE A</legend>
                            <div class="row">
                                <div v-for="(dossier,index) in cohorte.startups" :key="index" class="col-md-4 col-sm">
                                    <router-link :to="{name:'admin_preseed', params: {tkn: dossier.token}}">
                                        <div class="card">
                                            <div class="card-header">
                                                <h6>{{ dossier.name }}</h6>
                                            </div>
                                            <div class="card-bod">
                                                <img :src="dossier.photo" style="width: 240px; height: 240px" alt="">
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </fieldset>

                    </div>
                </div>
        </div>
    </main>

</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import DossierCohorteItem from "@/components/Includes/DossierCohorteItem.vue"
export default {
    name:"IndexCohorte",
    props: ['tkn'],
    components:{
        DossierCohorteItem,
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            cohorte:{},
            toaster: createToaster({ position:'top-right'}),
        }
    },
    methods:{
        setData(data){
            this.cohorte = data;
        },
        async load(){
            let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    //onCancel: this.onCancel,
                });
            await axios.get(this.path+'revendeur/cohorte/'+this.tkn)
                        .then((res)=>this.setData(res.data))
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        async reload(){
            await axios.get(this.path+'revendeur/cohorte/'+this.tkn)
                        .then((res)=>{
                            this.setData(res.data)
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.cohorte))
        
    }

}
</script>

<style scoped>
    .d-flex{
        display:flex;
    }

    .justify-content-center{
        justify-content: center;
    }

    .nav-pills .nav-link:hover {
        color: #3d9970;
        
    }
    .infos p{
        margin-bottom: 2px;
        font-size: smaller;
    }
</style>