<template>
  <div class="container m-4">
      <h2 class="text-center">NOUVEAU DOSSIER DE FINANCEMENT DU BFR</h2>
      <form @submit.prevent="submit">
        <div style="max-width: 700px; margin: 20px auto;" class="card">
            <div class="card-body">
              <div>
                <div class="form-group">
                  <label for="">Intitule</label>
                  <input v-model="dossier.name" type="text" class="form-control">
                </div>
                <div class="form-group">
                  <label for="">Diagnostic Interne</label>
                  <ckeditor :editor="editor" v-model="dossier.diagnostic_interne" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                  <label for="">LETTRE DE DEMANDE DE FINANCEMENT</label>
                  <ckeditor :editor="editor" v-model="dossier.demande_financement" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-success">ENGISTRER</button>
                </div>
              </div>
            </div>
        </div>
      </form>
  </div>
</template>
<script>
  import axios from 'axios';
  import { createToaster } from "@meforma/vue-toaster";
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  export default {

    name: "CreateBfr",
    data() {
      return {
        step: 1,
        loading: false,
        toaster: createToaster({ position:'top-right'}),
        editor: ClassicEditor,
        editorConfig: this.editorConfig,
        dossier:{
          name:'',
          demande_financement:'',
          diagnostic_interne:'',
        },
        // NB: Use computed properties just in case you intend using translations (e.g this.$t)
      };
    },
    methods: {
      async submit(){
        await axios.post(this.path+"owner/bfrs",this.dossier)
        .then((response)=>{
                console.log(response.data)
                this.toaster.success("Enregistrement effectue avec succes !!!");
                this.$router.push({path:'/owner/bfrs'});
              })
            .catch((err)=>{
              console.error(err);
              this.toaster.error("Echec lors de l'enregistrement du dossier !!!");
            });
      }
    },
  };
  </script>

<style scoped>
  
     
</style>
