<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Programmes</li>
                </ol>
                <h2>{{ programme.name }}</h2>
                <div class="d-flex justify-content-between">
                    <a class="btn btn-sm btn-danger text-white" data-bs-toggle="modal" data-bs-target="#myModal" href=""><i class="bi bi-pen-fill"></i> Postuler</a> 
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="text-center" style="max-width: 600px; margin: 5px auto;">
            </div>
        </div>
        <div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <form @submit.prevent="saveCandidature()" method="post">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">NOUVELLE CANDIDATURE</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" id="close" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-group">
            <select name="" id="" required @change="setDossier($event)" class="form-control">
                <option value="">Choix du dossier ...</option>
                <option v-for="(dossier, index) in dossiers" :key="index" :secteur_id="dossier.content.secteur_id" :type_id="dossier.type" :value="dossier.content.id">{{ dossier.content.name }}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="description">DESCRIPTION</label>
            <textarea  class="form-control" placeholder="Description de la cohorte" v-model="candidature.description"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-success">Valider</button>
      </div>
    </div>
    </form>
  </div>
</div>
    </main>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"OwnerShowProgramme",
    props: ['tkn'],
    components:{
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            programme:{},
            candidature:{type:0,dossier_id:0,programme_id:0,secteur_id:0},
            dossiers:[],
            toaster: createToaster({ position:'top-right'}),
        }
    },
    methods:{
        setDossier(event){
            console.log(event.target);
            this.candidature.type = event.target.options[event.target.options.selectedIndex].getAttribute('type_id');
            this.candidature.dossier_id = event.target.value;
            this.candidature.secteur_id = event.target.options[event.target.options.selectedIndex].getAttribute('secteur_id');
        },
        async saveCandidature(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });

            this.candidature.programme_id = this.programme.id;   
            await axios.post(this.path+'owner/programmes',this.candidature)
                        .then(()=>{
                            this.reload();
                        })
                        .catch(()=>{
                            this.toaster.error("Cette operation a echoue !!!");
                        })
                        .finally(()=>{
                            loader.hide();
                            document.getElementById('close').click();
                        })
        },
        setData(data){
            this.programme = data.programme;
            this.dossiers = data.dossiers;
        },
        async load(){
            let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    //onCancel: this.onCancel,
                });
            await axios.get(this.path+'owner/programmes/'+this.tkn)
                        .then((res)=>this.setData(res.data))
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        async reload(){
            await axios.get(this.path+'revendeur/programmes/'+this.tkn)
                        .then((res)=>{
                            this.setData(res.data)
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        },

        async publish(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.get(this.path+'revendeur/programme/publish/'+this.tkn)
                        .then((res)=>{
                            console.log(res);
                            this.reload();
                        })
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        async unpublish(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.get(this.path+'revendeur/programme/unpublish/'+this.tkn)
                        .then((res)=>{
                            console.log(res);
                            this.reload();
                        })
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
    },
    mounted(){
        this.load().then(()=>console.log(this.programme))
        
    }

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: var(--bs-nav-pills-link-active-color);
        background-color: #3d9970;
    }
    a {
        color: #3d9970;
        
    }
    .infos p{
        margin-bottom: 2px;
        font-size: smaller;
    }
    .table thead th{
        font-size: smaller;
    }
    fieldset {
        padding: 15px;
        border: 1px #b9b9b9 solid;
        border-radius: 5px;
        margin: 10px 0;
        min-width: 0;
    }
    legend {
        font-weight: 700;
        font-size: 11px;
        font-family: "segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
        color: #000000;
        padding: 5px;
        width: auto;
        display: block;
        max-width: 100%;
        margin-bottom: .5rem;
        line-height: inherit;
        white-space: normal;
    }
</style>