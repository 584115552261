<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <div class="d-flex justify-content-between">
                    <ol>
                        <li><a href="/">ACCUEIL</a></li>
                        <li>MES DOSSIERS </li>
                        <li>{{ dossier.type.name }} </li>
                    </ol>
                    <div>
                        <div class="dropdown">
                            <a class="dropdown-toggle text-bold" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Actions</a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#besoinModal">Description du besoin</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#dmModal">Description des matériels à financer</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#montantModal">Montant et conditions du crédit-bail</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#planModal">Plan d'utilisation des matériels</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#infModal">Informations financières de l'entreprise</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#collateralModal">Collatéral</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#lettreModal">Lettre de motivation</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#rexModal">Résumé exécutif</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#docModal">Ajouter un document</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-center gap-4">
                    <div v-if="dossier.entreprise!=null && dossier.entreprise!=undefined">
                        <div>
                            <router-link :to="{name:'supplier_entreprise',params:{tkn:dossier.entreprise.token}}">
                                <div class="text-center">
                                    <div>
                                        <img class="img img-circle" :src="dossier.entreprise.photo" style="height: 100px; width: 100px;" alt="">
                                    </div>
                                    <h6>{{ dossier.entreprise.name }}</h6>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="align-content-center">
                        <h2>{{ dossier.type.name }}</h2>
                        <h5><strong>{{ dossier.montant_transaction }}</strong></h5>
                        <small>{{ dossier.created }}</small>
                    </div>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="d-flex justify-center">
                <div style="width:700px;" class="d-flex flex-column justify-center">
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>DESCRIPTION DU BESOIN</legend>
                                <div v-html="dossier.besoin"></div>
                                <div v-if="dossier.description_bien_uri">
                                    <VuePdfEmbed annotation-layer text-layer :source="dossier.description_bien" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>DESCRIPTION DES MATERIELS A FINANCER</legend>
                                <div v-html="dossier.description_bien_text"></div>
                                <div v-if="dossier.description_bien">
                                    <VuePdfEmbed annotation-layer text-layer :source="dossier.description_bien" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>Plan d'utilisation des materiels</legend>
                                <div v-html="dossier.plan_utilisation_text"></div>
                                <div v-if="dossier.plan_utilisation_excel" class="text-center m-2">
                                    <a class="btn btn-success text-white btn-sm" :href="dossier.plan_utilisation_excel"><i class="bi bi-file-earmark-excel"></i> DOCUMENT EXCEL</a>
                                </div>
                                <div v-if="dossier.plan_utilisation_pdf">
                                    <VuePdfEmbed annotation-layer text-layer :source="dossier.plan_utilisation_pdf" />
                                </div>
                                
                                <div>

                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>Informations financières de l'entreprise</legend>
                                <div v-html="dossier.informations_financieres_text"></div>
                                <div class="d-flex justify-center gap-3">
                                    <a v-if="dossier.bilan" class="btn btn-success text-white btn-sm" :href="dossier.bilan"><i class="bi bi-file-earmark-excel"></i> BILAN</a>
                                    <a v-if="dossier.compte_resultat" class="btn btn-success text-white btn-sm" :href="dossier.compte_resultat"><i class="bi bi-file-earmark-excel"></i> COMPTE DE RESULTAT</a>
                                    <a v-if="dossier.flux_treso" class="btn btn-success text-white btn-sm" :href="dossier.flux_treso"><i class="bi bi-file-earmark-excel"></i> TABLEAU DE FLUX DE TRESORERIE</a>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>COLLATERAL</legend>
                                <div v-html="dossier.collateral_text"></div>
                                <div v-if="dossier.collateral">
                                    <VuePdfEmbed annotation-layer text-layer :source="dossier.collateral" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>LETTRE DE MOTIVATION</legend>
                                <div v-if="dossier.lettre_motivation">
                                    <VuePdfEmbed annotation-layer text-layer :source="dossier.lettre_motivation" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>RESUME EXECUTIF</legend>
                                <div v-html="dossier.resume_executif_text"></div>
                                <div v-if="dossier.resume_executif">
                                    <VuePdfEmbed annotation-layer text-layer :source="dossier.resume_executif" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <hr>
                <h3>AUTRES DOCUMENTS</h3>
                <div v-for="doc in dossier.docs" :key="doc.id">
                    <fieldset>
                        <legend>{{ doc.name }}</legend>
                        <div v-if="doc.url">
                            <VuePdfEmbed annotation-layer text-layer :source="doc.url" />
                        </div>
                    </fieldset>
                </div>
            </div>
            </div>
        </div>
        <DescriptionMat @success="endSuccess()" @error="endError()" :dossier="dossier"></DescriptionMat>
        <Montant @success="endSuccess()" @error="endError()" :dossier="dossier"></Montant>
        <Collateral @success="endSuccess()" @error="endError()" :dossier="dossier"></Collateral>
        <Besoin @success="endSuccess()" @error="endError()" :dossier="dossier"></Besoin>
        <PlanUtil @success="endSuccess()" @error="endError()" :dossier="dossier"></PlanUtil>
        <Besoin @success="endSuccess()" @error="endError()" :dossier="dossier"></Besoin>
        <Lettre @success="endSuccess()" @error="endError()" :dossier="dossier"></Lettre>
        <Resume @success="endSuccess()" @error="endError()" :dossier="dossier"></Resume>
        <InfoFin @success="endSuccess()" @error="endError()" :dossier="dossier"></InfoFin>
        <Document @success="endSuccess()" @error="endError()" :dossier="dossier"></Document>
    </main>
</template>
<script>
import axios from "axios";
import VuePdfEmbed from 'vue-pdf-embed'
import { createToaster } from "@meforma/vue-toaster";
import DescriptionMat from './Includes/DescriptionMat.vue';
import Collateral from './Includes/Collateral.vue';
import Montant from './Includes/Montant.vue';
import PlanUtil from './Includes/PlanUtil.vue';
import Besoin from './Includes/Besoin.vue';
import Lettre from './Includes/Lettre.vue';
import Resume from './Includes/Resume.vue';
import InfoFin from './Includes/InfoFin.vue';
import Document from './Includes/Document.vue';
export default {
    name:"ShowDossier",
    props: ['tkn'],
    components:{
        DescriptionMat,
        Collateral,
        Montant,
        PlanUtil,
        Besoin,
        Lettre,
        InfoFin,
        Resume,
        VuePdfEmbed,
        Document,
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            dossier:{
                type:{},
            },
            toaster: createToaster({ position:'top-right'}),
            componentKey: 0,
        }
    },
    methods:{
        endSuccess(){
            this.reload();
        },
        endError(){
            this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
        },
        async load(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.get(this.path+'supplier/dossiers/'+this.tkn)
                        .then((res)=>{
                            this.dossier = res.data;
                        })
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        async reload(){
            await axios.get(this.path+'supplier/dossiers/'+this.tkn)
                        .then((res)=>{
                            this.dossier = res.data;
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.dossier))
        
    },
    computed:{ 
    }

}
</script>
<style scoped>
    .vue-pdf-embed{
        height: 360px;
        overflow: scroll;
    }
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: var(--bs-nav-pills-link-active-color);
        background-color: #3d9970;
    }
    a {
        color: #3d9970;
        
    }
    .infos p{
        margin-bottom: 2px;
        font-size: smaller;
    }
    .table thead th{
        font-size: smaller;
    }
</style>