<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Deal flow</li>
                </ol>
                <h2>MON DEAL FLOW</h2>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="mb-2">
                <input @input="search" type="text" style="max-width: 400px;" class="form-control p-1 bg-success-subtle" placeholder="Rechercher ...">
            </div>
            <fieldset>
                <legend>Preseeds</legend>
                <table class="table table-sm table-bordered">
                <thead>
                    <tr>
                        <th>DATE</th>
                        <th>DOSSIER</th>
                        <th>PORTEUR DE PROJET</th>
                        <th>INVESTISSEUR</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(deal,index) in filtered_preseeds" :key="index">
                        <td>{{ deal.created }}</td>
                        <td>{{ deal.dossier.name }}</td>
                        <td>{{ deal.owner.first_name }} {{ deal.owner.last_name }}</td>
                        <td>{{ deal.investisseur.first_name }} {{ deal.investisseur.last_name }}</td>
                    </tr>
                </tbody>
            </table>
            </fieldset>

            <fieldset>
                <legend>Seeds</legend>
                <table class="table table-sm table-bordered">
                <thead>
                    <tr>
                        <th>DATE</th>
                        <th>DOSSIER</th>
                        <th>PORTEUR DE PROJET</th>
                        <th>INVESTISSEUR</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(deal,index) in filtered_seeds" :key="index">
                        <td>{{ deal.created }}</td>
                        <td>{{ deal.dossier.name }}</td>
                        <td>{{ deal.owner.first_name }} {{ deal.owner.last_name }}</td>
                        <td>{{ deal.investisseur.first_name }} {{ deal.investisseur.last_name }}</td>
                    </tr>
                </tbody>
            </table>
            </fieldset>

            <fieldset>
                <legend>Series A</legend>
                <table class="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>DATE</th>
                            <th>DOSSIER</th>
                            <th>PORTEUR DE PROJET</th>
                            <th>INVESTISSEUR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(deal,index) in filtered_series" :key="index">
                            <td>{{ deal.created }}</td>
                            <td>{{ deal.dossier.name }}</td>
                            <td>{{ deal.owner.first_name }} {{ deal.owner.last_name }}</td>
                            <td>{{ deal.investisseur.first_name }} {{ deal.investisseur.last_name }}</td>
                        </tr>
                    </tbody>
                </table>
            </fieldset>
        </div>
    </main>

</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"IndexPorteur",
    data(){
        return {
            user:this.$store.state.auth.user,
            toaster: createToaster({ position:'top-right'}),
            seeds:[],
            preseeds:[],
            series:[],
            filtered_seeds:[],
            filtered_preseeds:[],
            filtered_series:[],

        }
    },
    methods:{
        async load(){
            await axios.get(this.path+'consultant/deals')
                        .then((res)=>{
                            this.seeds = res.data.seeds
                            this.preseeds = res.data.preseeds
                            this.series = res.data.series
                            this.filtered_seeds = res.data.seeds
                            this.filtered_preseeds = res.data.preseeds
                            this.filtered_series = res.data.series
                        })
                        .catch((err)=>console.error(err));
        },

        search(event){
            {
                setTimeout(() => {
                    if (!event.target.value.trim().length) {
                        this.filtered_seeds = [...this.seeds];
                        this.filtered_preseeds = [...this.preseeds];
                        this.filtered_series = [...this.series];
                    } else {
                        //console.log(this.porteurs)
                        this.filtered_seeds = this.seeds.filter((item) =>item.dossier.name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.owner.last_name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.owner.first_name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.investisseur.last_name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.investisseur.first_name.toLowerCase().startsWith(event.target.value.toLowerCase())
                        );

                        this.filtered_preseeds = this.preseeds.filter((item) =>item.dossier.name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.owner.last_name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.owner.first_name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.investisseur.last_name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.investisseur.first_name.toLowerCase().startsWith(event.target.value.toLowerCase())
                        );

                        this.filtered_series = this.series.filter((item) =>item.dossier.name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.owner.last_name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.owner.first_name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.investisseur.last_name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.investisseur.first_name.toLowerCase().startsWith(event.target.value.toLowerCase())
                        );
                    }
                }, 250);
            }
        },

    },
    mounted(){
        this.load().then(()=>console.log(this.porteurs))
    }

}
</script>

<style scoped>
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
</style>