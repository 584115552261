import AdminDashboard from '@/components/Revendeur/Dashboard.vue'
// Composants du revendeur
import AdminConsultantIndex from "@/components/Revendeur/Consultants/Index.vue"
import AdminPorteurIndex from "@/components/Revendeur/Porteurs/Index.vue"
import AdminPorteurShow from "@/components/Revendeur/Porteurs/Show.vue"
import AdminPreseedIndex from "@/components/Revendeur/Preseeds/Index.vue"
import AdminPreseedShow from '@/components/Revendeur/Preseeds/Show.vue'
import AdminSeedIndex from "@/components/Revendeur/Seeds/Index.vue"
import AdminSeedShow from '@/components/Revendeur/Seeds/Show.vue'
import AdminRechargesIndex from '@/components/Revendeur/Credits/Recharges.vue';
import AdminAchatsIndex from '@/components/Revendeur/Credits/Achats.vue';
import AdminProgrammesIndex from '@/components/Revendeur/Programmes/Index.vue';
import AdminProgrammesShow from '@/components/Revendeur/Programmes/Show.vue';
import AdminProgrammesCohorte from '@/components/Revendeur/Programmes/Cohorte.vue';
import AdminCandidaturesIndex from '@/components/Revendeur/Programmes/Candidatures.vue'

export default[
        //Routes de l'incubateur
        {
            name: "admin_dashboard",
            path: '/admin/dashboard',
            component: AdminDashboard,
            meta: {
                title: `Admin Dashboard`,
                middleware: "auth"
            }
        },
        {
            name: "admin_consultant_index",
            path: '/admin/consultants',
            component: AdminConsultantIndex,
            meta: {
                title: `Gestion des consultants`,
                middleware: "auth"
            }
        },
        {
            name: "admin_porteur_index",
            path: '/admin/entrepreneurs',
            component: AdminPorteurIndex,
            meta: {
                title: `Gestion des entrepreneurs`,
                middleware: "auth"
            }
        },
        {
            name: "admin_porteur",
            path: '/admin/entrepreneur/:tkn',
            component: AdminPorteurShow,
            props: true,
            meta: {
                title: `Entrepreneur`,
                middleware: "auth"
            }
        },
        {
            name: "admin_preseed_index",
            path: '/admin/preseeds',
            component: AdminPreseedIndex,
            meta: {
                title: `Gestion des dossiers preseed`,
                middleware: "auth"
            }
        },
        {
            name: "admin_preseed",
            path: '/admin/preseed/:tkn',
            component: AdminPreseedShow,
            props: true,
            meta: {
                title: `Preseed`,
                middleware: "auth"
            }
        },
        {
            name: "admin_seed_index",
            path: '/admin/seeds',
            component: AdminSeedIndex,
            meta: {
                title: `Gestion des dossiers seed`,
                middleware: "auth"
            }
        },
        {
            name: "admin_seed",
            path: '/admin/seed/:tkn',
            component: AdminSeedShow,
            props: true,
            meta: {
                title: `Seed`,
                middleware: "auth"
            }
        },
        {
            name: "admin_preseed_plan",
            path: '/admin/preseed/:tkn',
            component: AdminPreseedShow,
            props: true,
            meta: {
                title: `Preseed`,
                middleware: "auth"
            }
        },
    
        {
            name: "admin_recharges_index",
            path: '/admin/recharges',
            component: AdminRechargesIndex,
            meta: {
                title: `Historique des recharges`,
                middleware: "auth"
            }
        },
    
        {
            name: "admin_achats_index",
            path: '/admin/achats',
            component: AdminAchatsIndex,
            meta: {
                title: `Historique des achats`,
                middleware: "auth"
            }
        },
        {
            name: "admin_programme_index",
            path: '/admin/programmes',
            component: AdminProgrammesIndex,
            meta: {
                title: `Gestion des programmes`,
                middleware: "auth"
            }
        },
        {
            name: "admin_programme",
            path: '/admin/programme/:tkn',
            component: AdminProgrammesShow,
            props: true,
            meta: {
                title: `Details sur le programme`,
                middleware: "auth"
            }
        },
        {
            name: "admin_programme_candidatures",
            path: '/admin/programme/candidatures/:tkn',
            component: AdminCandidaturesIndex,
            props: true,
            meta: {
                title: `Listes des candidatures du programme`,
                middleware: "auth"
            }
        },
        {
            name: "admin_cohorte",
            path: '/admin/cohorte/:tkn',
            component: AdminProgrammesCohorte,
            props: true,
            meta: {
                title: `Details sur la cohorte`,
                middleware: "auth"
            }
        },
        /* ------------- End of Revendeur section ----------*/
    
]