<template>
        <ul>
            <li><router-link to="/owner/dashboard"> ACCUEIL</router-link></li>
            <li class="dropdown">
                <a class="">
                    <div class="text-center">
                        <p class="m-0"><small>DOSSIERS</small><i class="bi bi-chevron-down"></i></p>
                    </div>
                </a>
                <ul>
                    <li><router-link to="/owner/preseeds">PRESEED</router-link></li>
                    <li><router-link to="/owner/seeds">SEED</router-link></li>
                    <li><router-link to="/owner/serie">SERIE A</router-link></li>
                    <li><router-link to="/owner/pme">PME</router-link></li>
                    <li><router-link to="/owner/bfrs">BFR</router-link></li>
                    <li><router-link to="/owner/tresoreries">TRESORERIE</router-link></li>
                </ul>
            </li>
            <li class="dropdown">
                <a class="">
                    <div class="text-center">
                        <p class="m-0"><small>PROGRAMMES</small><i class="bi bi-chevron-down"></i></p>
                    </div>
                </a>
                <ul>
                    <li><router-link to="/owner/candidatures">MES CANDIDATURES</router-link></li>
                    <li><router-link to="/owner/programmes">APPELS A CANDIDATURE</router-link></li>
                </ul>
            </li>
            <li class="dropdown">
                <a class="">
                    <div class="text-center">
                        <p class="m-0"><small>CREDITS</small><i class="bi bi-chevron-down"></i></p>
                    </div>
                </a>
                <ul>
                    <li><router-link to="/owner/recharges">MES RECHARGES</router-link></li>
                </ul>
            </li>
            <div style="display: inherit;" v-if="authenticated">
                <li class="dropdown">
                    <a class="">
                        <div class="text-center">
                            <i style="font-size:25px;" class="bi bi-person"></i><i class="bi bi-chevron-down"></i>
                        </div>
                    </a>
                    <ul>
                        <li><router-link to="/user/profil">Profil</router-link></li>
                        <li><a class="dropdown-item" href="javascript:void(0)" @click="logout">Se deconnecter</a></li>
                    </ul>
                </li>
            </div>
    </ul>
</template>
<script>
    import {mapActions} from 'vuex';
    import axios from "axios";
    export default{
        name:'RevendeurNavbar',
        data: function(){
            return {
                authenticated:this.$store.state.auth.authenticated?this.$store.state.auth.authenticated:false,
            }
        },
        methods:{
            ...mapActions({
                signOut:"auth/signOut"
            }),
            async logout(){
                await axios.post(this.path+'auth/logout').then(()=>{
                    this.signOut()
                    this.authenticated = false;
                    window.location.href="/"
                    //this.$router.push({name:"home"})
                })
                .catch(()=>window.location.href="/")
            }
        }
    }

</script>
<style scoped>
    .navbar a{
        text-decoration: none;
    }
</style>