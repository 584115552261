<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Credit</li>
                </ol>
                <div style="display: flex; justify-content: space-between;">
                    <h2>HISTORIQUE DES RECHARGES</h2>
                    <div>
                        <span>SOLDE </span>
                        <span class="badge badge-success">{{ solde }} pts</span>
                    </div>
                    <div style="">
                        <button class="btn btn-success btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#rechargeModal">Effectuer une recharge</button>
                    </div>
                </div>
                
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <table class="table table-sm table-bordered">
                <thead>
                    <tr>
                        <th>DATE</th>
                        <th>MONTANT</th>
                        <th>STATUT</th>
                        <th>BENEFICIAIRE</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(recharge,index) in recharges" :key="index">
                        <td>{{ recharge.created }}</td>
                        <td>{{ recharge.montant }}</td>
                        <td> <span :class="recharge.statut.color"></span> {{ recharge.statut.name }}</td>
                        <td>{{ recharge.owner?recharge.owner.name:'-' }}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </main>
<div class="modal fade" id="rechargeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <form @submit.prevent="save()" method="post">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">NOUVELLE RECHARGE</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" id="close" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-group">
            <label for="">BENEFICIAIRE</label>
            <select class="form-control" name="" @change="changeClient" id="client_id">
                <option value="0">SELECTIONNER UN PORTEUR DE PROJET</option>
                <option v-for= "(client,index) in clients" :key="index" :value="client.id">{{ client.name }}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="">MONTANT</label>
            <input type="number" class="form-control" placeholder="MONTANT" v-model="recharge.montant">
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-success">ENREGISTRER</button>
      </div>
    </div>
    </form>
  </div>
</div>  
</template>
<script>
import axios from 'axios'
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"RechargesComponent",
    data(){
        return {
            user:this.$store.state.auth.user,
            clients:[],
            recharge:{
                owner_id:0,
                montant:0,
            },
            solde:0,
            toaster: createToaster({ position:'top-right'}),
            recharges:[]
        }
    },
    methods:{
        changeClient(event){
            console.log(event)
            this.recharge.owner_id = event.target.value;
        },
        async load(){
            await axios.get(this.path+'revendeur/recharges')
                        .then((res)=>{
                            this.recharges=res.data.recharges;
                            this.clients=res.data.clients;
                            this.solde = res.data.solde;

                        })
                        .catch((err)=>console.error(err));
        },
        async reload(){
            await axios.get(this.path+'revendeur/recharges')
                        .then((res)=>{
                        this.recharges=res.data.recharges;
                        this.clients=res.data.clients;
                        this.solde = res.data.solde;
                           console.log(res.data);
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        },
        async save(){
            if(this.recharge.owner_id >0 && this.recharge.montant>0){
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.post(this.path+'revendeur/recharge',this.recharge)
                        .then(()=>{
                            
                            this.reload();
                        })
                        .catch((err)=>{
                            if (err.response.status === 403) {
                                this.toaster.error(err.response.data);
                            } else {
                                this.toaster.error("Cette operation a echoue !!!");
                            }
                            
                        })
                        .finally(()=>{
                            loader.hide();
                            document.getElementById('close').click();
                        })
            }else{
                console.log(this.recharge.montant);
                console.log(this.recharge.owner_id);
                this.toaster.error("Donnees invalides !!!");
            }
        },
    },
    mounted(){
        this.load().then(()=>console.log(this.recharges))
        
    }

}
</script>