<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>RENDEZ-VOUS</li>
                </ol>
                <h2>HISTORIQUE DES RENDEZ-VOUS</h2>

            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <table class="table table-sm table-bordered">
                <thead>
                    <tr>
                        <th>INVESTISSEUR</th>
                        <th>CONTACT</th>
                        <th>DOSSIER</th>
                        <th>TYPE DE DOSSIER</th>
                        <th>PORTEUR DE PROJET</th>
                        <th>DATE DE CREATION</th>
                        <th>DATE DE PRISE DE RENDEZ-VOUS</th>
                        <th>ACTIONS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(rdv,index) in rdvs" :key="index">
                        <td>
                            {{ rdv.user?rdv.user.name:'-' }}
                        </td>
                        <th> <small> {{ rdv.user.phone!=='null'?rdv.user.phone:'' }},{{ rdv.user.email }} - {{ rdv.user.country_code }}</small></th>
                        <td>{{ rdv.dossier?rdv.dossier.name:'-' }}</td>
                        <td>{{ rdv.dossier?rdv.dossier.type:'-' }}</td>
                        <td>{{ rdv.dossier?rdv.dossier.owner.name:'-' }}</td>
                        <td>{{ rdv.created }}</td>
                        <td>{{ rdv.jour }}</td>
                        <td>
                            <div class="dropdown">
                        <a class="dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Actions</a>
                        <ul class="dropdown-menu">
                            <li v-if="!rdv.open" ><a class="dropdown-item" @click="open(rdv.token)" type="button">DONNER ACCES A LA DATAROOM</a></li>
                            <li v-if="!rdv.subscribed"><a class="dropdown-item" @click="subscribe(rdv.token)" href="#" type="button">INSCRIRE A LA NEWSLETTER</a></li>
                            <li v-if="!rdv.close"><a class="dropdown-item" @click="close(rdv.token)" href="#" type="button">CLOTURER CE RENDEZ-VOUS</a></li>
                            <li><a class="dropdown-item" :data="JSON.stringify({token:rdv.token})" @click="cancel($event)" href="#" type="button">ANNULER LE RDV</a></li>
                        </ul>
                    </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </main>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"IndexPreseed",
    data(){
        return {
            user:this.$store.state.auth.user,
            toaster: createToaster({ position:'top-right'}),
            rdvs:[]
        }
    },
    methods:{
        async open(id){
            console.log(id);
            let loader = this.$loading.show();
            await axios.get(this.path+'consultant/rdv/dataroom/'+id)
                        .then(()=>{
                            this.load();
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch(
                            (err)=>{
                                console.error(err);
                                this.toaster.error("Echec de l'operation !!!");
                            })
                        .finally(()=>{
                            loader.hide();
                        }); 
        },

        async close(id){
            console.log(id);
            let loader = this.$loading.show();
            await axios.get(this.path+'consultant/rdv/close/'+id)
                        .then(()=>{
                            this.load();
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch(
                            (err)=>{
                                console.error(err);
                                this.toaster.error("Echec de l'operation !!!");
                            })
                        .finally(()=>{
                            loader.hide();
                        }); 
        },

        async subscribe(token){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.post(this.path+'consultant/rdv/subscribe',{'token':token})
                        .then(()=>{
                            this.load();
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch(
                            (err)=>{
                                console.error(err);
                                this.toaster.error("Echec de l'operation !!!");
                            })
                        .finally(()=>{
                            loader.hide();
                        }); 
        },
        async cancel(event){
            let data = event.target.getAttribute('data');
            console.log(data);
            let rdv = JSON.parse(data);
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.post(this.path+'consultant/rdv/disable',rdv)
                        .then(()=>{
                            this.load();
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch(
                            (err)=>{
                                console.error(err);
                                this.toaster.error("Echec de l'operation !!!");
                            })
                        .finally(()=>{
                            loader.hide();
                        }); 
        },
        async load(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.get(this.path+'consultant/rdvs')
                        .then((res)=>this.rdvs=res.data)
                        .catch((err)=>console.error(err))
                        .finally(()=>{
                            loader.hide();
                        });
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.rdvs))   
    }
}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    a{
        color: #3d9970;
        text-decoration: none;
    }
</style>