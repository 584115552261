<template>
<div class="modal fade" id="strategieModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Stratégie de Refinancement</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div>
                    <ol>
                        <li>
                            <div class="form-group">
                                <label for="">Objectifs du Refinancement</label>
                                <ckeditor :editor="editor" v-model="folder.objectifs_refinancement_text" :config="editorConfig"></ckeditor>
                            </div>
                        </li>
                        <li>
                            <div class="form-group">
                                <label for="">Montant Requis</label>
                                <input type="text" v-model="folder.montant_requis" class="form-control">
                            </div>
                            <hr>
                            <div class="form-group">
                                <label for="">Utilisation des Fonds</label>
                                <ckeditor :editor="editor" v-model="folder.utilisation_fonds_text" :config="editorConfig"></ckeditor>
                                <input type="file" class="form-control" name="file" id="file" @change="utilisationChange">
                            </div>
                            
                        </li>
                        <li>
                            <div class="form-group">
                                <label for="">Structure du refinancement et plan de Remboursement (Instruments utilisé)</label>
                                <ckeditor :editor="editor" v-model="folder.structure_refinancement_plan_remboursement_text" :config="editorConfig"></ckeditor>
                                <input type="file" class="form-control" name="file" id="file" @change="refinChange">
                            </div>
                        </li>
                        <li>
                            <div class="form-group">
                                <label for="">Garanties</label>
                                <ckeditor :editor="editor" v-model="folder.garanties_text" :config="editorConfig"></ckeditor>
                                <input type="file" class="form-control" name="file" id="file" @change="garantiesChange">
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
            <div class="modal-footer">
                <div class="form-group">
                    <button class="btn btn-success" @click="submit" data-bs-dismiss="modal" type="submit"><i class="bx bi-save"></i> ENREGISTRER</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    export default{
        name:'StrategieComponent',
        props:{
            dossier:{
                'type':Object,
                'required':true,
            }
        },
        data(){
            return {
                utilisationPdf:null,
                refPdf:null,
                garantiesPdf:null,
                folder:{},
                editor: ClassicEditor,
                editorConfig: this.editorConfig,

            }
        },
        methods:{
            utilisationChange(event){
                this.utilisationPdf = event.target.files[0];
            },
            refinChange(event){
                this.refPdf = event.target.files[0];
            },
            garantiesChange(event){
                this.garantiesPdf = event.target.files[0];
            },
            submit(){
                    let loader = this.$loading.show({
                        container: this.fullPage ? null : this.$refs.formContainer,
                        canCancel: false,
                    });
                    let form = new FormData();
                    if(this.utilisationPdf!=null){
                        form.append("utilisation_fonds",this.utilisationPdf);
                    }
                    if(this.refPdf!=null){
                        form.append("structure_refinancement_plan_remboursement",this.refPdf);
                    }
                    if(this.garantiesPdf!=null){
                        form.append("garanties",this.garantiesPdf);
                    }
                    delete this.folder.institution;
                    form.append("dossier",JSON.stringify(this.folder));
                    axios.post(this.path+'cga/dimf/save',form,{
                        headers:{
                            'Accept':'multipart/form-data'
                        }
                    })
                    .then((res)=>{
                        console.log(res)
                        this.$emit('success');
                    })
                    .catch((err)=>{
                        this.$emit('error')
                        console.log(err)
                    })
                    .finally(()=>loader.hide())
                
            }
        },
        computed:{
        },
        watch:{
            dossier(newValue,oldValue){
                console.log(oldValue)
                console.log(newValue)
                this.folder = this.dossier;
            }
        },
        mounted(){
            this.folder= this.dossier;
        }
    }

</script>

<style scoped>


</style>