<template>
<div class="modal fade" id="projectionsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Projections Financières</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div>
                    <ol>
                        <li>
                            <div class="form-group">
                                <label for="">Projections de Croissance et Scénarios de Développement</label>
                                <ckeditor :editor="editor" v-model="folder.projections_croissance_text" :config="editorConfig"></ckeditor>
                                <div class="d-flex gap-2">
                                    <div class="form-group">
                                        <label for="">Fichier pdf</label>
                                        <input type="file" class="form-control"  @change="croisPdfChange">
                                    </div>
                                    <div class="form-group">
                                        <label for="">Fichier excel</label>
                                        <input type="file" class="form-control"  @change="croisExcelChange">
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="form-group">
                                <label for="">Prévisions de Bilan et Compte de Résultat (3-5 prochaines années)</label>
                                <ckeditor :editor="editor" v-model="folder.previsions_bilan_compte_resultat_text" :config="editorConfig"></ckeditor>
                                <div class="d-flex gap-2">
                                    <div class="form-group">
                                        <label for="">Fichier pdf</label>
                                        <input type="file" class="form-control"  @change="prevPdfChange">
                                    </div>
                                    <div class="form-group">
                                        <label for="">Fichier excel</label>
                                        <input type="file" class="form-control"  @change="prevExcelChange">
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="form-group">
                                <label for="">Hypothèses de Croissance et Indicateurs de Performance</label>
                                <ckeditor :editor="editor" v-model="folder.hypotheses_croissance_text" :config="editorConfig"></ckeditor>
                                <div class="d-flex gap-2">
                                    <div class="form-group">
                                        <label for="">Fichier pdf</label>
                                        <input type="file" class="form-control" name="file" id="file3" @change="hypoPdfChange">
                                    </div>
                                    <div class="form-group">
                                        <label for="">Fichier excel</label>
                                        <input type="file" class="form-control" name="file" id="file4" @change="hypoExcelChange">
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
            <div class="modal-footer">
                <div class="form-group">
                    <button class="btn btn-success" @click="submit" data-bs-dismiss="modal" type="submit"><i class="bx bi-save"></i> ENREGISTRER</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    export default{
        name:'StrategieComponent',
        props:{
            dossier:{
                'type':Object,
                'required':true,
            }
        },
        data(){
            return {
                projections_croissance_pdf:null,
                projections_croissance_excel:null,
                previsions_bilan_compte_resultat_pdf:null,
                previsions_bilan_compte_resultat_excel:null,
                hypotheses_croissance_pdf:null,
                hypotheses_croissance_excel:null,
                folder:{},
                editor: ClassicEditor,
                editorConfig: this.editorConfig,

            }
        },
        methods:{
            croisPdfChange(event){
                this.projections_croissance_pdf = event.target.files[0];
            },
            croisExcelChange(event){
                this.projections_croissance_excel = event.target.files[0];
            },
            prevPdfChange(event){
                this.previsions_bilan_compte_resultat_pdf = event.target.files[0];
            },
            prevExcelChange(event){
                this.previsions_bilan_compte_resultat_excel = event.target.files[0];
            },
            hypoPdfChange(event){
                this.hypotheses_croissance_pdf = event.target.files[0];
            },
            hypoExcelChange(event){
                this.hypotheses_croissance_excel = event.target.files[0];
            },
            submit(){
                    let loader = this.$loading.show({
                        container: this.fullPage ? null : this.$refs.formContainer,
                        canCancel: false,
                    });
                    let form = new FormData();
                    if(this.projections_croissance_pdf!=null){
                        form.append("projections_croissance_pdf",this.projections_croissance_pdf);
                    }
                    if(this.projections_croissance_excel!=null){
                        form.append("projections_croissance_excel",this.projections_croissance_excel);
                    }
                    if(this.previsions_bilan_compte_resultat_pdf!=null){
                        form.append("previsions_bilan_compte_resultat_pdf",this.previsions_bilan_compte_resultat_pdf);
                    }
                    if(this.previsions_bilan_compte_resultat_excel!=null){
                        form.append("previsions_bilan_compte_resultat_excel",this.previsions_bilan_compte_resultat_excel);
                    }
                    if(this.hypotheses_croissance_pdf!=null){
                        form.append("hypotheses_croissance_pdf",this.hypotheses_croissance_pdf);
                    }
                    if(this.hypotheses_croissance_excel!=null){
                        form.append("hypotheses_croissance_excel",this.hypotheses_croissance_excel);
                    }
                    delete this.folder.institution;
                    form.append("dossier",JSON.stringify(this.folder));
                    axios.post(this.path+'cga/dimf/save',form,{
                        headers:{
                            'Accept':'multipart/form-data'
                        }
                    })
                    .then((res)=>{
                        console.log(res)
                        this.$emit('success');
                    })
                    .catch((err)=>{
                        this.$emit('error')
                        console.log(err)
                    })
                    .finally(()=>loader.hide())
                
            }
        },
        computed:{
        },
        watch:{
            dossier(newValue,oldValue){
                console.log(oldValue)
                console.log(newValue)
                this.folder = this.dossier;
            }
        },
        mounted(){
            this.folder= this.dossier;
        }
    }

</script>

<style scoped>
    .form-control{
        margin-top: 15px;
    }

</style>