<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Dossiers de financement de la tresorerie</li>
                </ol>
                <h2>MES DOSSIERS DE TRESORERIE</h2>

            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div>
                <div class="p-1">
                    <div class=""><router-link class="btn btn-xs btn-success" to="/owner/tresorerie/create"><i class="bi bi-plus-square-fill" title="Creer un nouveau dossier"></i>Nouveau dossier</router-link></div>
                </div>
            </div>
            <table class="table table-sm table-bordered">
                <thead>
                    <tr>
                        <th>DATE</th>
                        <th>DOSSIER</th>
                        <th>CONSULTANT</th>
                        <th>NB. FICHIERS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(dossier,index) in dossiers" :key="index">
                        <td>{{ dossier.created }}</td>
                        <td>
                            <router-link :to="{name:'owner_treso', params: {tkn: dossier.token}}">{{ dossier.name }}</router-link>
                        </td>
                        <td>{{ dossier.consultant?dossier.consultant.name:'-' }}</td>
                        <td>{{ dossier.fichiers.length }}</td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
    </main>
</template>
<script>
import axios from "axios";
export default {
    name:"IndexTreso",
    data(){
        return {
            user:this.$store.state.auth.user,
            dossiers:[]
        }
    },
    methods:{
        async load(){
            await axios.get(this.path+'owner/tresoreries')
                        .then((res)=>this.dossiers=res.data)
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.dossiers))
        
    }

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    a{
        color: #3d9970;
        text-decoration: none;
    }

    a.btn{
        color:#fff;
    }
</style>