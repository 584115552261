<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <div class="d-flex justify-content-between">
                    <ol>
                        <li><a href="/">ACCUEIL</a></li>
                        <li>DOSSIERS SERIE A </li>
                        <li>DOSSIER DU {{ dossier.created }} </li>
                    </ol>
                    <div>
                        <div class="dropdown">
                            <a class="dropdown-toggle text-bold" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Actions</a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#marcheModal">Marché et opportunité</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#modeleModal">Modèle économique</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#psModal">Produit ou service</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#strategieModal">Stratégie de croissance</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#analyseModal">Analyse de la concurrence</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#equipeModal">Équipe de direction</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#milestoneModal">Milestones et réalisations </a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#utilModal">Utilisation des fonds</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#sortieModal">Stratégie de sortie</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#risqueModal">Risques et défis </a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#termeModal">Termes de l'investissement</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#docModal">Ajouter un document financier</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-center gap-4">
                    <div v-if="dossier.entreprise!=null && dossier.entreprise!=undefined">
                        <div>
                            <router-link :to="{name:'supplier_entreprise',params:{tkn:dossier.entreprise.token}}">
                                <div class="text-center">
                                    <div>
                                        <img class="img img-circle" :src="dossier.entreprise.photo" style="height: 100px; width: 100px;" alt="">
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="align-content-center">
                        <h2>{{ dossier.entreprise.name }}</h2>
                        <h6>{{ dossier.created }}</h6>
                    </div>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="d-flex justify-center">
                <div style="width:700px;" class="d-flex flex-column justify-center">
                    <div  class="card bg-light">
                        <div class="card-body">
                            <div>
                                <fieldset>
                                    <legend>Marché et opportunité</legend>
                                    <div v-html="dossier.marche_opportunites_text"></div>
                                    <div v-if="dossier.marche_opportunites">
                                        <VuePdfEmbed  :source="dossier.marche_opportunites" />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div  class="card bg-light">
                        <div class="card-body">
                            <div>
                                <fieldset>
                                    <legend>Produit ou service</legend>
                                    <div v-html="dossier.produit_service_text"></div>
                                    <div v-if="dossier.produit_service">
                                        <VuePdfEmbed  :source="dossier.produit_service" />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div  class="card bg-light">
                        <div class="card-body">
                            <fieldset>
                                <legend>MODELE ECONOMIQUE</legend>
                                    <ModeleEconomique :modele="dossier.modele"></ModeleEconomique> 
                                </fieldset>
                        </div>
                    </div>
                    <div  class="card bg-light">
                        <div class="card-body">
                            <div>
                                <fieldset>
                                    <legend>Stratégie de croissance</legend>
                                    <div v-html="dossier.strategie_croissance_text"></div>
                                    <div v-if="dossier.strategie_croissance">
                                        <VuePdfEmbed  :source="dossier.strategie_croissance" />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div  class="card bg-light">
                        <div class="card-body">
                            <div>
                                <fieldset>
                                    <legend>Analyse de la concurrence</legend>
                                    <div v-html="dossier.analyse_concurrence_text"></div>
                                    <div v-if="dossier.analyse_concurrence">
                                        <VuePdfEmbed  :source="dossier.analyse_concurrence" />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div  class="card bg-light">
                        <div class="card-body">
                            <div>
                                <fieldset>
                                    <legend>Équipe de direction</legend>
                                    <div v-html="dossier.equipe_text"></div>
                                    <div v-if="dossier.equipe">
                                        <VuePdfEmbed  :source="dossier.equipe" />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div  class="card bg-light">
                        <div class="card-body">
                            <div>
                                <fieldset>
                                    <legend>Milestones et réalisations</legend>
                                    <div v-html="dossier.milestones_realisations_text"></div>
                                    <div v-if="dossier.milestones_realisations">
                                        <VuePdfEmbed  :source="dossier.milestones_realisations" />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div  class="card bg-light">
                        <div class="card-body">
                            <div>
                                <fieldset>
                                    <legend>Utilisation des fonds</legend>
                                    <div v-html="dossier.utilisation_fonds_text"></div>
                                    <div v-if="dossier.utilisation_fonds">
                                        <VuePdfEmbed  :source="dossier.utilisation_fonds" />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div  class="card bg-light">
                        <div class="card-body">
                            <div>
                                <fieldset>
                                    <legend>Stratégie de sortie</legend>
                                    <div v-html="dossier.strategie_sortie_text"></div>
                                    <div v-if="dossier.strategie_sortie">
                                        <VuePdfEmbed :source="dossier.strategie_sortie" />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div  class="card bg-light">
                        <div class="card-body">
                            <div>
                                <fieldset>
                                    <legend>Risques et défis </legend>
                                    <div v-html="dossier.risques_defis_text"></div>
                                    <div v-if="dossier.risques_defis">
                                        <VuePdfEmbed :source="dossier.risques_defis" />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div  class="card bg-light">
                        <div class="card-body">
                            <div>
                                <fieldset>
                                    <legend>Termes de l'investissement</legend>
                                    <div v-html="dossier.termes_investissement_text"></div>
                                    <div v-if="dossier.termes_investissement">
                                        <VuePdfEmbed  :source="dossier.termes_investissement" />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <h3>DOCUMENTS FINANCIERS</h3>
                    <div v-for="doc in dossier.docs" :key="doc.id">
                        <fieldset>
                            <legend>{{ doc.name }}</legend>
                            <div v-if="doc.url">
                                <VuePdfEmbed  :source="doc.url" />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <Analyse :dossier="dossier" @success="endSuccess" @error="endError" />
        <Document :dossier="dossier" @success="endSuccess" @error="endError" />
        <marche-opportunites :dossier="dossier" @success="endSuccess" @error="endError" />
        <Modele :data="dossier.modele" @success="endSuccess" @error="endError" />
        <produit-service :dossier="dossier" @success="endSuccess" @error="endError" />
        <strategie-croissance :dossier="dossier" @success="endSuccess" @error="endError" />
        <strategie-sortie :dossier="dossier" @success="endSuccess" @error="endError" />
        <util-fonds :dossier="dossier" @success="endSuccess" @error="endError" />
        <risque-defis :dossier="dossier" @success="endSuccess" @error="endError" />
        <equipe :dossier="dossier" @success="endSuccess" @error="endError" />
        <milestone :dossier="dossier" @success="endSuccess" @error="endError" />
        <termes-invest :dossier="dossier" @success="endSuccess" @error="endError" />
    </main>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import VuePdfEmbed from 'vue-pdf-embed'
import Analyse from "./Includes/Analyse.vue";
import Document from "./Includes/Document.vue";
import MarcheOpportunites from "./Includes/MarcheOpportunites.vue";
import Modele from "./Includes/ModeleEco.vue";
import ProduitService from "./Includes/ProduitService.vue";
import StrategieCroissance from "./Includes/StrategieCroissance.vue";
import TermesInvest from './Includes/TermesInvest.vue';
import Equipe from './Includes/Equipe.vue';
import Milestone from './Includes/Milestone.vue';
import RisqueDefis from './Includes/RisqueDefis.vue';
import UtilFonds from './Includes/UtilFonds.vue'
import StrategieSortie from './Includes/StrategieSortie.vue';
import ModeleEconomique from "./Includes/ModeleEconomique.vue";
export default {
    name:"ShowDossier",
    props: ['tkn'],
    components:{
        Analyse,
        Document,
        MarcheOpportunites,
        Modele,
        ProduitService,
        StrategieCroissance,
        TermesInvest,
        RisqueDefis,
        Milestone,
        Equipe,
        UtilFonds,
        StrategieSortie,
        VuePdfEmbed,
        ModeleEconomique,
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            dossier:{},
            toaster: createToaster({ position:'top-right'}),
            componentKey: 0,
        }
    },
    methods:{
        endSuccess(){
            this.reload();
        },
        endError(){
            this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
        },
        async load(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.get(this.path+'cga/series/'+this.tkn)
                        .then((res)=>{
                            this.dossier = res.data;
                            if(this.dossier.modele==null){
                                this.dossier.modele = {
                                    serie_id : this.dossier.id,
                                }
                            }
                        })
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        async reload(){
            await axios.get(this.path+'cga/series/'+this.tkn)
                        .then((res)=>{
                            this.dossier = res.data;
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.dossier))
        
    },
    computed:{ 
    }

}
</script>
<style scoped>
    .vue-pdf-embed{
        height: 360px;
        overflow: scroll;
    }
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: var(--bs-nav-pills-link-active-color);
        background-color: #3d9970;
    }
    a {
        color: #3d9970;
        
    }
    .infos p{
        margin-bottom: 2px;
        font-size: smaller;
    }
    .table thead th{
        font-size: smaller;
    }
</style>