<template>
    <div class="container">
        <form @submit.prevent="submit">
            <input type="hidden" v-model="modele.id">
            <div class="form-group">
                <label>OFFRE</label>
                <ckeditor :editor="editor" v-model="modele.offre" :config="editorConfig"></ckeditor>
            </div>
            <div class="form-group">
                <label for="segment">SEGMENTS DE CLIENTELE</label>
                <ckeditor :editor="editor" v-model="modele.segment" :config="editorConfig"></ckeditor>
                
            </div>
            <div class="form-group">
                <label for="canaux">CANAUX DE DISTRIBUTION </label>
                <ckeditor :editor="editor" v-model="modele.canaux" :config="editorConfig"></ckeditor>
            </div>
            <div class="form-group">
                <label for="relation">RELATION CLIENTS </label>
                <ckeditor :editor="editor" v-model="modele.relation" :config="editorConfig"></ckeditor>
            </div>
            <div class="form-group">
                <label for="partenaires">PARTENAIRES CLES</label>
                <ckeditor :editor="editor" v-model="modele.partenaires" :config="editorConfig"></ckeditor>
            </div>
            <div class="form-group">
                <label for="activites">ACTIVITES CLES </label>
                <ckeditor :editor="editor" v-model="modele.activites" :config="editorConfig"></ckeditor>
            </div>
            <div class="form-group">
                <label for="ressources">RESSOURCES CLES </label>
                <ckeditor :editor="editor" v-model="modele.ressources" :config="editorConfig"></ckeditor>
            </div>
            <div class="form-group">
                <label for="couts">COUTS</label>
                <ckeditor :editor="editor" v-model="modele.couts" :config="editorConfig"></ckeditor>
            </div>
            <div class="form-group">
                <label for="revenus">REVENUS</label>
                <ckeditor :editor="editor" v-model="modele.revenus" :config="editorConfig"></ckeditor>
            </div>
            <div class="form-group">
                <button type="submit" class="btn btn-success btn-sm">ENREGISTRER</button>
            </div>
        </form>
    </div>      
</template>
<script>
import { createToaster } from "@meforma/vue-toaster";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
export default {
    name:"EditBM",
    props: {
        data:{
            type:Object,
        },
    },
    data(){
        return {
        loading: false,
        toaster: createToaster({ position:'top-right'}),
        editor: ClassicEditor,
        editorConfig: this.editorConfig,
        modele:{},
        }
    },
    methods:{
        submit(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
            });
                console.log(this.modele);
                axios.post(this.path+'bm/save',this.modele)
                .then((res)=>{
                    console.log(res.data);
                    document.getElementById('bmclose').click();
                    this.$emit('saved');
                })
                .catch((err)=>{
                    this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
                    console.log(err)
                })
                .finally(()=>loader.hide())

        },
    },
    watch:{
            data(newValue,oldValue){
                console.log(oldValue);
                console.log(newValue);
                console.log(this.data);
                this.modele = this.data;
                console.log(this.modele);
            }
        },
    mounted(){
        this.modele = this.data;
        console.log(this.data);
    }

}
</script>
  