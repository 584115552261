<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Credit</li>
                </ol>
                <div style="display: flex; justify-content: space-between;">
                    <h2>HISTORIQUE DES RECHARGES</h2>
                    <div>
                        <span>SOLDE </span>
                        <span class="badge badge-success">{{ user.solde }} pts</span>
                    </div>
                </div>
                
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <table class="table table-sm table-bordered">
                <thead>
                    <tr>
                        <th>DATE</th>
                        <th>MONTANT</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(recharge,index) in recharges" :key="index">
                        <td>{{ recharge.created }}</td>
                        <td>{{ recharge.montant }}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </main>
 
</template>
<script>
import axios from "axios";
export default {
    name:"IndexRechargesComponent",
    data(){
        return {
            user:this.$store.state.auth.user,
            recharge:{
                owner_id:0,
                montant:0,
            },
            recharges:[]
        }
    },
    methods:{
        async load(){
            await axios.get(this.path+'owner/recharges')
                        .then((res)=>{
                            this.recharges=res.data;

                        })
                        .catch((err)=>console.error(err));
        },
    },
    mounted(){
        this.load().then(()=>console.log(this.recharges))
        
    }

}
</script>