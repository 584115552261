<template>
<div class="modal fade" id="audioEnModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title" id="exampleModalLabel">CHARGEMENT DU PITCH EN ANGLAIS</h6>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="close133" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form class="form" @submit.prevent="submit" >
                    
                    <div class="form-group mb-2">
                            <label for="">FICHIER EN ANGLAIS</label>
                            <input type="file" class="form-control" name="file_en" id="file_en" @change="fileChangeEn">
                        </div>
                       <button class="btn btn-success" type="submit">ENREGISTRER</button>
                 </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    export default{
        name:'EvolutionComponent',
        props:{
            dossier:{
                'type':Object,
                'required':true,
            }
        },
        data(){
            return {
                presentation_evolution_marche:this.dossier.presentation_evolution_marche,
                editor: ClassicEditor,
                editorConfig: this.editorConfig,
                file_fr:null,
                toaster: createToaster({ position:'top-right'}),

            }
        },
        methods:{
            fileChangeEn(event){
                this.file_en = event.target.files[0];
            },
            submit(){
                document.getElementById('close133').click();
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
                let form = new FormData();
                if(this.file_en!=null){
                    form.append("file_en",this.file_en);
                }
                form.append("token",this.dossier.token);
                axios.post('/audio/ic4a/pmes/podcasts',form,{
                    headers:{
                        'Accept':'multipart/form-data',
                    }
                })
                .then((res)=>{
                    console.log(res)
                    //let rp = {token:this.dossier.token,en:res.data.en};
                    axios.post(this.path+'cga/pme/audio/path',res.data)
                    .then(()=>{
                        console.log(res)
                        this.$emit('ended');
                    })
                    .catch((err)=>{
                        this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
                        console.log(err)
                    })
                    .finally(()=>loader.hide())
                    
                })
                .catch((err)=>{
                    this.toaster.error("Une erreur est survenue lors du chargement du fichier! Bien vouloir verifier votre connexion internet");
                    console.log(err)
                })
                .finally(()=>loader.hide())
            }
        }
    }

</script>

<style scoped>


</style>