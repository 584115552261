<template>
    <div style="background-color: #efefef;">
      <v-container class="">
        
          <Vue3MultiStepper
          v-model:step="step"
          :tabs="tabs"
          primaryColor1="#3d9970"
          primaryColor2="#dfdfdf"
          backText="Precedent"
          nextText="Suivant"
          doneText="Termine"
          :loading="loading"
          :finalize="handleFormSubmission"
          :validateStep="validateStep"
      >
      <template #1>
          <v-container>
            <fieldset>
              <legend>DONNEES GENERALES</legend>
                  <div class="row">
                       <div class="col-md-5 col-sm-12">
                           <div class="form-group">
                            <div class="">
                              <label class="label">INTITULE DU PROJET</label>
                              <input name="name" id="name" class="form-control" v-model="dossier.name"  type="text" placeholder="Intitule du projet">
                          </div>
                           </div>
                       </div>
                       <div class="col-md-4 col-sm-12">
                           <div class="form-group">
                               <label class="control-label">PHOTO DU PROJET</label>
                               <input id="photo" name="imageUri" @change="handlePhoto($event)"  ref="photo" required="required" type="file"  class="form-control">
                           </div>
                       </div>

                       <div class="col-md-3 col-sm-12">
                           <div class="form-group">
                               <label class="control-label">PAYS</label>
                               <select class="form-control" @change="changePays($event)" name="pay_id" id="pay_id">
                                    <option value="">Choisir le pays ...</option>
                                     <option v-for="p in pays" :value="p.id" :key="p.id" >{{ p.name }}</option>
                                 
                               </select>
                           </div>
                       </div>
                       <div class="form-group">
                          <label for="">Petite description du projet</label>
                          <textarea name="description" v-model="dossier.description" class="form-control" id="" cols="30" rows="5"></textarea>
                       </div>
                   </div>
            </fieldset>
          </v-container>
      </template>
      <template #2>
          <Confidentialite />
      </template>
      <template #3>
        <div class="">
          <fieldset>
               <legend>DESCRIPTION DU MODELE ECONOMIQUE</legend>

                <div class="form-group">
                    <label>OFFRE</label>
                    <ckeditor :editor="editor" v-model="modele.offre" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                    <label for="segment">SEGMENTS DE CLIENTELE</label>
                    <ckeditor :editor="editor" v-model="modele.segment" :config="editorConfig"></ckeditor>
                    
                </div>
                <div class="form-group">
                    <label for="canaux">CANAUX DE DISTRIBUTION </label>
                    <ckeditor :editor="editor" v-model="modele.canaux" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                    <label for="relation">RELATION CLIENTS </label>
                    <ckeditor :editor="editor" v-model="modele.relation" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                    <label for="partenaires">PARTENAIRES CLES</label>
                    <ckeditor :editor="editor" v-model="modele.partenaires" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                    <label for="activites">ACTIVITES CLES </label>
                    <ckeditor :editor="editor" v-model="modele.activites" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                    <label for="ressources">RESSOURCES CLES </label>
                    <ckeditor :editor="editor" v-model="modele.ressources" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                    <label for="couts">COUTS</label>
                    <ckeditor :editor="editor" v-model="modele.couts" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                    <label for="revenus">REVENUS</label>
                    <ckeditor :editor="editor" v-model="modele.revenus" :config="editorConfig"></ckeditor>
                    
                </div>
          </fieldset>
      </div>
      </template>
      <template #4>
          <v-container>
              <v-card elevation="2">
                <div class="bg">
                  <fieldset>
                      <legend>ANALYSE PRELIMINAIRE DES RISQUES</legend>

                  <div class="">
                      <div class="row">
                          <div class="col-lg-4 col-md-4 col-sm-12">
                              <div>
                                  <label for="sector_id">Secteur d'activite</label>
                                  <select @change="changeSector($event)" name="sector_id" id="sector_id" class="form-control">
                                      <option value="">Selectionner un secteur</option>
                                      <option v-for="opt in secteurs" :key="opt.id" :value="opt.id">{{ opt.name }}</option>
                                  </select>

                              </div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-sm-12">
                              <div>
                                  <div>
                                    <label for="produit_id">Produit/Service</label>
                                      <select @change="changeProduit($event)" name="produit_id" id="produit_id" class="form-control">
                                        <option value="">Selectionner un produit ou un service</option>
                                        <option v-for="opt in produits" :key="opt.id" :value="opt.id">{{ opt.name }}</option>
                                      </select>
                                  </div>
                              <div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-sm-12">
                            <ul class="list inline">
                               <!-- <li class="list-inline-item" v-for="produit in selected_products" :key="produit.id"><span class="badge badge-light">{{ produit.name }}</span></li> -->
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                      <div>
                          <div class="section" id="section-4">
                              <h5 class="page-header m-2">QUESTIONNAIRE</h5>
                                      <template v-for="risque in risques" :key="risque.id">
                                        <v-card class="mb-2" v-if="risque.question!=undefined">
                                            <v-card-title>{{risque.question?risque.question.name:'-'}}</v-card-title>
                                            <v-card-text style="text-align: left;">
                                              <Question @choice-change="choiceChange" :risque="risque" />
                                            </v-card-text>
                                        </v-card>
                                      </template>
                          </div>
                      </div>
                  </div>
                  </fieldset>
              </div>
              </v-card>
          </v-container>
      </template>
      </Vue3MultiStepper>
    </v-container>
    </div>
</template>
<script>
  import { computed } from "vue";
  import { Vue3MultiStepper } from "vue3-multi-stepper";
  import axios from 'axios';
  import Confidentialite from '@/components/Includes/Confidentialite.vue';
  import Question from "@/components/Includes/Question.vue";
  import { createToaster } from "@meforma/vue-toaster";
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  export default {
    components: {
      Vue3MultiStepper,
      Question,
      Confidentialite,
    },
    name: "CreatePreseed",
    data() {
      return {
        step: 1,
        loading: false,
        toaster: createToaster({ position:'top-right'}),
        editor: ClassicEditor,
        editorConfig: this.editorConfig,
        dossier:{
          name:null,
          secteur_id:0,
          ville_id:0,
          description:null,
        },
        modele:{},
        villes:[],
        secteurs:[],
        produits:[],
        produit_ids:[],
        risques:[],
        responses:[],
        photo:null,
        pays:[],
        // NB: Use computed properties just in case you intend using translations (e.g this.$t)
        tabs: computed(() => {
          return [
            {
              title: "INFORMATIONS GENERALES",
              iconSuccess: null,
              isValid: true,
            },
            {
              title: "CONVENTION DE CONFIDENTIALITE",
              iconSuccess: null,
              isValid: true,
            },
            {
              title: "MODELE ECONOMIQUE",
              iconSuccess: null,
              isValid: true,
            },
  
            {
              title: "ANALYSE DES RISQUES",
              iconSuccess: null,
              isValid: true,
            },
          ];
        }),
      };
    },
  
    mounted(){
        axios.get(this.path+'owner/villes')
        .then((res)=>this.villes = res.data)
        .catch((err)=>console.error(err));

        axios.get(this.path+'countries')
        .then((res)=>this.pays = res.data)
        .catch((err)=>console.error(err));

       axios.get(this.staging+'secteurs')
       .then((res)=>this.secteurs=res.data)
       .catch((err)=>console.error(err));
    },
    
    methods: {
      choiceChange(res){
       let found = this.responses.find(e=>e.question==res.question);
       if(found!=undefined){
        let cp = this.responses;
        this.responses = cp.filter(response => response.question != found.question);
        
       }
       this.responses.push(res);
        console.log(this.responses);
        this.responses.forEach((rp)=>console.log(rp))
      },
      makeAChoice(event){
        console.log(event.target.value)
      },
      handlePhoto(event){
        this.photo = event.target.files[0];
      },
      changeSector(event){
        this.dossier.secteur_id = event.target.value; 
        axios.get(this.staging+'produits/sector/'+this.dossier.secteur_id)
       .then((res)=>this.produits=res.data)
       .catch((err)=>console.error(err))
      },
      changeProduit(event){
        let pid = event.target.value;
        this.produit_ids.push(pid);
        //this.selected_products.push({id:pid,name:event.target})
        axios.get(this.staging+'questions/produit/'+pid)
       .then((res)=>{
        this.risques = [...res.data];
        console.log(this.risques);
      })
       .catch((err)=>console.error(err))
      },
      changeVille(event){
        this.dossier.ville_id = event.target.value;
      },
      changePays(event){
        this.dossier.pay_id = event.target.value;
      },
  
      async handleFormSubmission() {
        this.loading = true;
       // console.log("C'est fini !!!");
        const form = new FormData();
        form.append("photo",this.photo);
        form.append("produits",JSON.stringify(this.produit_ids));
        form.append("modele",JSON.stringify(this.modele)); 
        form.append("dossier",JSON.stringify(this.dossier));
        form.append("reponses",JSON.stringify(this.responses))
        let plan = {choices:this.responses,produits:this.produit_ids};
        //console.log(this.orm+'plan');
        await axios.post(this.staging+'plan',plan,{
          headers: {
            "Content-Type": "application/json"
          },
        })
        .then((res)=>{
          form.append("plan_id",res.data);
          axios.post(this.path+"owner/seeds", form, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
              function(event){
                this.progress = Math.round((100 * event.loaded) / event.total);
              }
            })
            .then((response)=>{
                console.log(response.data)
                this.toaster.success("Enregistrement effectue avec succes !!!");
                this.$router.push({path:'/owner/seeds'});
              })
            .catch((err)=>{
              console.error(err);
              this.toaster.error("Echec lors de l'enregistrement du dossier !!!");
            });
        })
        .catch((err)=>console.log(err))
      },
  
      validateStep(step) {
        // Perform validation based on the current step
        // Return true if the step is valid, otherwise return false
        if (step === 1) {
          return this.step1Check();
        } else if (step === 2) {
          return this.step2Check();
        } else if (step === 3) {
          return this.step3Check();
        } else if (step === 4) {
          return this.step4Check();
        }
  
        return true; // Default to true if no validation is implemented
      },
  
      
  
      step1Check() {
        if(this.dossier.name==null || this.photo==null || this.dossier.pay_id == 0 || this.dossier.description == null){
          this.toaster.error("Remplissage incorrect !!!");
          return false;
        }
        return true;
      },
      step2Check() {
        return true;
      },
      step3Check() {
        return true;
      },
      step4Check() {
        return true;
      },
      step5Check() {
        return true;
      },
    },
  };
  </script>

<style scoped>
  
    .bg{
      background-color: #efefef;
    }
    .steppy-item-counter {
        height: 40px !important;
        width: 40px !important;
        border: none !important;
      }
      
      .steppy-item-counter .number {
        font-size: 1rem !important;
      }
      
      .controls {
        display: flex !important;
        flex-direction: row !important;
        gap: 2rem !important;
      }
      
      .controls .btn {
        align-self:normal !important;
        background-color: #3d7;
      }

      input[type='radio']:checked:after {
        background: #3d9970 !important;
      }
      
      .v-container div.controls .btn--default-2 {
        margin: auto !important;
        text-align: center !important;
        background: #3d9970 !important;
        border-radius: 4px !important;
        border: none !important;
        height: 40px !important;
        width: 40% !important;
        font-size: 1rem !important;
        color: #000407 !important;
        font-weight: bold !important;
      }

      .btn[data-v-bd8cdabb] {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 16px;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        line-height: 1.5;
        transition: all .15s;
        border-radius: 4px;
        width: fit-content;
        font-size: .75rem;
        color: #fff;
        background-color: #3d9970;
        border: 1px solid #f0f0f0;
      }
</style>