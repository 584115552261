<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Dossiers SEED</li>
                </ol>
                <h2>{{ dossier.name }}</h2>
                <div class="">
                    <div class="dropdown">
                        <a class="dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Actions</a>
                        <ul class="dropdown-menu">
                            <li><router-link class="dropdown-item" :to="{name:'consultant_seed_plan', params: {tkn: dossier.token}}">Afficher le plan d'actions</router-link></li>
                            <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#bmModal">Afficher le modele economique</a></li>
                            <li><a v-if="dossier.status.code==0" @click="ask()" class="dropdown-item" href="#" type="button">Demander une evaluation</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="row">
                    <div id="side1" class="col-md-12 col-sm-12" >
                        <div class="card">
                            <div class="card-body">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-12">
                                            <div style="height: auto; ">
                                                <img style="width: 100%;" :src="dossier.photo" alt="">
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-12 infos">
                                            <span :class="`badge bg-${dossier.status.color}`"> {{ dossier.status.name }}</span>
                                            <span class="badge badge-success">ETAPE {{ dossier.step }}</span>
                                            <p>PROMOTEUR : <span class="text-bold">{{ dossier.owner?dossier.owner.name:'-' }}</span></p>
                                            <p>CONSULTANT : <span class="text-bold">{{ dossier.consultant?dossier.consultant.name:'-' }}</span></p>
                                            <p>DATE DE CREATION : <span class="value"> {{ dossier.created }}</span></p>
                                            <p><span>PAYS : </span> {{ dossier.pays?dossier.pays.name:'-' }}</p>
                                            <p>SECTEUR :  <span class="text-bold">{{ dossier.secteur?dossier.secteur.name:'-' }}</span></p>
                                            <input type="hidden" id="pl_id" value="{{ dossier.plan_id }}"/>

                                            
                                        </div>
                                        <div class="col-md-4 col-sm-12">
                                            <div class="card bg-success" style="max-height:220px; overflow:scroll;">
                                                <div class="card-body">
                                                    <p>{{ dossier.description }}</p>
                                                    <p>
                                                        <strong>COHORTES : </strong>
                                                        <ul class="list-inline">
                                                            <li v-for="(cohorte,index) in dossier.cohortes" :key="index" class="list-inline-item">
                                                                <span class="badge bg-warning">{{ cohorte.name }} : <strong>{{ cohorte.programme.name }}</strong></span>
                                                            </li>
                                                        </ul>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div id="side2" style="overflow: scroll" class="col-md-12 col-sm-12">
                        <div class="card p-2">
                            <div class="card-body">
                                <Seed :dossier="dossier" :choices="choices" />
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </main>





</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import Seed from "../../Includes/Seed.vue";
export default {
    name:"IndexSseed",
    props: ['tkn'],
    components:{
        Seed,
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            dossier:{
                status:{}
            },
            choices:[],
            programmes:[],
            cohortes:[],
            programme_id:0,
            cohorte_id:0,
            description:null,
            toaster: createToaster({ position:'top-right'}),
            consultant_id:0,
            componentKey: 0,
        }
    },
    methods:{
        async ask(){
            let loader = this.$loading.show();
            await axios.get(this.path+'consultant/seeds/evaluation/'+this.tkn)
                        .then(()=>this.reload())
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        setData(data){
            this.dossier = data.dossier;
            this.choices = data.choix;
            this.programmes = data.programmes;
        },
        async load(){
            let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    //onCancel: this.onCancel,
                });
            await axios.get(this.path+'consultant/seeds/'+this.tkn)
                        .then((res)=>this.setData(res.data))
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        async reload(){
            await axios.get(this.path+'consultant/seeds/'+this.tkn)
                        .then((res)=>{
                            this.setData(res.data)
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.dossier))
        
    }

}
</script>

<style scoped>
    
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: var(--bs-nav-pills-link-active-color);
        background-color: #3d9970;
    }
    .nav-pills .nav-link:hover {
        color: #3d9970;
        
    }
    .infos p{
        margin-bottom: 2px;
        font-size: smaller;
    }
</style>