<template>
<div class="modal fade" id="audioLocaleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title" id="exampleModalLabel">CHARGEMENT DU PITCH EN LANGUE LOCALE</h6>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="closeloc" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form class="form" @submit.prevent="submit" >
                    <div class="form-group mb-2">
                        <label for="">CHOIX DE LA LANGUE</label>
                        <select name="langue_id" id="langue_id" @change="changeLangue" class="form-control">
                            <option value=0>Choisir une langue ...</option>
                            <option v-for="lang in langues" :key="lang.id" :value="lang.id">{{ lang.name }}</option>
                        </select>
                    </div>
                    <div class="form-group mb-2">
                        <label for="">FICHIER EN LANGUE LOCALE</label>
                        <input type="file" class="form-control" name="file_locale" id="file_locale" @change="fileChangeLocale">
                    </div>
                    <button class="btn btn-success" type="submit">ENREGISTRER</button>
                </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    export default{
        name:'PitchLocaleComponent',
        props:{
            dossier:{
                'type':Object,
                'required':true,
            },
            langues:{
                'type':Array,
                'required':true,
            }
        },
        data(){
            return {
                //langues:[],
                editor: ClassicEditor,
                editorConfig: this.editorConfig,
                file_locale:null,
                langue_id:0,
                toaster: createToaster({ position:'top-right'}),

            }
        },
        methods:{
            fileChangeLocale(event){
                this.file_locale = event.target.files[0];
            },
            changeLangue(event){
                this.langue_id = event.target.value;
            },  
            async submit(){
                document.getElementById('closeloc').click();
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
                let form = new FormData();
                if(this.file_locale!=null && this.langue_id!=0){
                    form.append("file_locale",this.file_locale);
                    form.append("token",this.dossier.token);
                    //form.append("seed_id",this.dossier.id);
                    axios.post('/audio/ic4a/preseeds/podcasts',form,{
                        headers:{
                            'Accept':'multipart/form-data'
                        }
                    })
                    .then((res)=>{
                        console.log(res)
                        //let rp = {token:this.dossier.token,en:res.data.en};
                        let data = res.data;
                        data.langue_id = this.langue_id;
                        axios.post(this.path+'owner/preseed/audio/path',data)
                        .then(()=>{
                            console.log(res)
                            this.$emit('ended');
                        })
                        .catch((err)=>{
                            this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
                            console.log(err)
                        })
                        .finally(()=>loader.hide())
                        
                    })
                    .catch((err)=>{
                        this.toaster.error("Une erreur est survenue lors du chargement du fichier! Bien vouloir verifier votre connexion internet");
                        console.log(err)
                    })
                    .finally(()=>loader.hide())
                }else{
                    alert("Donnees incorrectes!");
                }
            }, 
        },
    }

</script>

<style scoped>


</style>