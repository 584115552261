<template>
    <ul>
        <li><router-link to="/company/dashboard"> ACCUEIL</router-link></li>
        <li class="dropdown">
            <a class="">
                <div class="text-center">
                    <p class="m-0">Prêts à court terme<i class="bi bi-chevron-down"></i></p>
                </div>
            </a>
            <ul>
                <li><router-link to="/company/decouvert">Découvert bancaire</router-link></li>
                <li><router-link to="/company/ligne-credit">Ligne de crédit</router-link></li>
                <li><router-link to="/company/escompte">Escompte commercial</router-link></li>
            </ul>
        </li>
        <li class="dropdown">
            <a class="">
                <div class="text-center">
                    <p class="m-0">Prêts à long terme<i class="bi bi-chevron-down"></i></p>
                </div>
            </a>
            <ul>
                <li><router-link to="/company/prets-hypothecaires">Prêts hypothécaires commerciaux</router-link></li>
                <li><router-link to="/company/prets-investissement">Prêts d’investissement à long terme</router-link></li>
                <li><router-link to="/company/prets-acquisition">Prêts pour l’acquisition d’entreprises</router-link></li>
            </ul>
        </li>

        <li><router-link to="/company/creer-entreprise">Céder une entreprise</router-link></li>
        <li><router-link to="/company/redresser-entreprise">Redresser une entreprise</router-link></li>   
            
        <template v-if="authenticated">
            <li class="dropdown">
                <a class="">
                    <div class="text-center user-profil">
                        <i style="font-size:25px;" class="bi bi-person"></i><i class="bi bi-chevron-down"></i>
                       
                    </div>
                </a>
                <ul>
                    <li><router-link to="/user/profil">Profil Utilisateur</router-link></li>
                    <li><router-link to="/profil">Profil Incubateur</router-link></li>
                    <li><a class="dropdown-item" href="javascript:void(0)" @click="logout">Se deconnecter</a></li>
                </ul>
            </li>
        </template>
    </ul>
</template>
<script>
    import {mapActions} from 'vuex';
    import axios from "axios";
    export default{
        name:'RevendeurNavbar',
        data: function(){
            return {
                authenticated:this.$store.state.auth.authenticated?this.$store.state.auth.authenticated:false,
            }
        },
        methods:{
            ...mapActions({
                signOut:"auth/signOut"
            }),
            async logout(){
                await axios.post(this.path+'auth/logout').then(()=>{
                    this.signOut()
                    this.authenticated = false;
                    window.location.href="/"
                    //this.$router.push({name:"home"})
                })
            }
        }
    }

</script>
<style scoped>
    .navbar a{
        text-decoration: none;
        font-weight: bolder;
    }

</style>