<template>
<div class="modal fade" id="objectifsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">OBJECTIFS A ATTEINDRE</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="close9" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form class="form" @submit.prevent="submit" >
                        <div class="form-group">
                            <div v-html="dossier.presentation_objectifs"></div>
                            <hr/>
                            <ckeditor :editor="editor" v-model="presentation_objectifs" :config="editorConfig"></ckeditor>
                            
                        </div>
                       <button class="btn btn-success" type="submit">ENREGISTRER</button>
                 </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default{
    name:'SolutionComponent',
    props:{
            dossier:{
                'type':Object,
                'required':true,
            }
        },
        data(){
            return {
                presentation_objectifs:this.dossier.presentation_objectifs,
                toaster: createToaster({ position:'top-right'}),
                editor: ClassicEditor,
                editorConfig: this.editorConfig

                }
        },
        methods:{
            submit(){
                document.getElementById('close9').click();
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
                let form = new FormData();
                form.append("presentation_objectifs",this.presentation_objectifs);
                form.append("preseed_id",this.dossier.id);
                axios.post(this.path+'owner/preseeds/objectifs',form,{
                    headers:{
                        'Accept':'multipart/form-data'
                    }
                })
                .then((res)=>{
                    console.log(res)
                    this.$emit('ended');
                })
                .catch((err)=>{
                    this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
                    console.log(err)
                })
                .finally(()=>loader.hide())
            }
        }
    }

</script>

<style scoped>


</style>