<template>
<div class="modal fade" id="legalModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Dossier Légal et Réglementaire</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="close4" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div>
                    <ol>
                        <li>
                            <div class="form-group">
                                <label for="">Conformité Réglementaire</label>
                                <input type="file" class="form-control" name="file" id="file" @change="conformChange">
                            </div>
                        </li>
                        <li>
                            <div class="form-group">
                                <label for="">Autorisations et Licences</label>
                                <input type="file" class="form-control" name="file" id="file" @change="licenceChange">
                            </div>
                        </li>
                        <li>
                            <div class="form-group">
                                <label for="">Historique de Litiges et Contentieux</label>
                                <input type="file" class="form-control" name="file" id="file" @change="litigeChange">
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
            <div class="modal-footer">
                <div class="form-group">
                    <button class="btn btn-success" @click="submit" data-bs-dismiss="modal" type="submit"><i class="bx bi-save"></i> ENREGISTRER</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
    export default{
        name:'AnalyseComponent',
        props:{
            dossier:{
                'type':Object,
                'required':true,
            }
        },
        data(){
            return {
                conform:null,
                licence:null,
                litige:null,
            }
        },
        methods:{
            conformChange(event){
                this.conform = event.target.files[0];
            },
            licenceChange(event){
                this.licence = event.target.files[0];
            },
            litigeChange(event){
                this.litige = event.target.files[0];
            },
            submit(){
                    let loader = this.$loading.show({
                        container: this.fullPage ? null : this.$refs.formContainer,
                        canCancel: false,
                    });
                    let form = new FormData();
                    if(this.conform!=null){
                        form.append("conformite_reglementaire",this.conform);
                    }
                    if(this.licence!=null){
                        form.append("autorisations_licences",this.licence);
                    }
                    if(this.litige!=null){
                        form.append("historique_litiges_contentieux",this.litige);
                    }
                    delete this.dossier.institution;
                    form.append("dossier",JSON.stringify(this.dossier));
                    axios.post(this.path+'cga/dimf/save',form,{
                        headers:{
                            'Accept':'multipart/form-data'
                        }
                    })
                    .then((res)=>{
                        console.log(res)
                        this.$emit('success');
                    })
                    .catch((err)=>{
                        this.$emit('error')
                        console.log(err)
                    })
                    .finally(()=>loader.hide())
                
            }
        },
        computed:{
        },
        watch:{
            dossier(newValue,oldValue){
                console.log(oldValue)
                console.log(newValue)
            }
        },
        mounted(){
            
        }
    }

</script>

<style scoped>


</style>