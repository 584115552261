<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                    <li><a href="/">ACCUEIL</a></li>
                    <li>IMF</li>
                    <li>{{ dossier.name }} </li>
                </ol>
                <div class="d-flex justify-center gap-4">
                    <div v-if="dossier.institution!=null && dossier.institution!=undefined">
                        <div>
                            <router-link to="#">
                                <div class="text-center">
                                    <div>
                                        <img class="img img-circle" :src="dossier.institution.photo" style="height: 100px; width: 100px;" alt="">
                                    </div>
                                    <h6>{{ dossier.institution.name }}</h6>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="align-content-center">
                        <h4>{{ dossier.name }}</h4>
                        <h3>{{ dossier.montant_requis }}</h3>
                        <small>{{ dossier.created }}</small>
                    </div>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <fieldset>
                <legend>Analyse Financière <span><a data-bs-target="#analyseModal" data-bs-toggle="modal" href="#" class="modifier"><i class="bx bx-edit"></i></a></span></legend>
                <v-expansion-panels>  
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>Bilan comptable (3 dernières années)</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div v-if="dossier.bilan!=null">
                                <VuePdfEmbed  :source="dossier.bilan" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>Compte de Résultat (3 dernières années)</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div v-if="dossier.compte_resultat!=null">
                                <VuePdfEmbed  :source="dossier.compte_resultat" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>Flux de Trésorerie (3 dernières années)</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div v-if="dossier.flux_treso!=null">
                                <VuePdfEmbed  :source="dossier.flux_treso" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>Ratios Financiers Clés</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div v-if="dossier.ratios!=null">
                                <VuePdfEmbed  :source="dossier.ratios" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </fieldset>
            <fieldset>
                <legend>Stratégie de Refinancement <span><a data-bs-target="#strategieModal" data-bs-toggle="modal" href="#" class="modifier"><i class="bx bx-edit"></i></a></span></legend>
                <v-expansion-panels variant="accordion">
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6> 1. Objectifs du Refinancement</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div class="bg-green-lighten-5 rounded-3 p-2 m-1" v-html="dossier.objectifs_refinancement_text"></div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>2. Montant Requis et Utilisation des Fonds</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <p>MONTANT : {{ dossier.montant_requis }}</p>
                            <div class="bg-green-lighten-5 rounded-3 p-2 m-1" v-html="dossier.utilisation_fonds_text"></div>
                            <div class="m-1" v-if="dossier.utilisation_fonds!=null">
                                <VuePdfEmbed  :source="dossier.utilisation_fonds" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>3. Structure du refinancement et plan de Remboursement (Instruments utilisé)</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div class="bg-green-lighten-5 rounded-3 p-2" v-html="dossier.structure_refinancement_plan_remboursement_text"></div>
                            <div class="m-1" v-if="dossier.structure_refinancement_plan_remboursement!=null">
                                <VuePdfEmbed  :source="dossier.structure_refinancement_plan_remboursement" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>4. Garanties</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div class="bg-green-lighten-5 rounded-3 p-2" v-html="dossier.garanties_text"></div>
                            <div class="m-1" v-if="dossier.garanties!=null">
                                <VuePdfEmbed  :source="dossier.garanties" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
                
            </fieldset>
            
            <fieldset>
                <legend>Évaluation des Risques <span><a data-bs-target="#risqueModal" data-bs-toggle="modal" href="#" class="modifier"><i class="bx bx-edit"></i></a></span></legend>
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>1. Analyse SWOT (Forces, Faiblesses, Opportunités, Menaces)</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div class="m-1" v-if="dossier.swot!=null">
                                <VuePdfEmbed  :source="dossier.swot" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>2. Gestion des Risques</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div class="bg-green-lighten-5 rounded-3 p-2 m-1" v-html="dossier.gestion_risques_text"></div>
                            <div class="m-1" v-if="dossier.gestion_risques!=null">
                                <VuePdfEmbed  :source="dossier.gestion_risques" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>3. Plans de Contingence</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div class="bg-green-lighten-5 rounded-3 p-2" v-html="dossier.plan_contingence_text"></div>
                            <div class="m-1" v-if="dossier.plan_contingence!=null">
                                <VuePdfEmbed  :source="dossier.plan_contingence" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </fieldset>

            <fieldset>
                <legend>Projections Financières <span><a data-bs-target="#projectionsModal" data-bs-toggle="modal" href="#" class="modifier"><i class="bx bx-edit"></i></a></span></legend>
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>1. Projections de Croissance et Scénarios de Développement</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <a class="text-white btn btn-sm btn-success" v-if="dossier.projections_croissance_excel!=null" :href="dossier.projections_croissance_excel"><i class="bx bxs-file-export"></i> Fichier Excel</a>
                            <div class="bg-green-lighten-5 rounded-3 p-2 m-1" v-html="dossier.projections_croissance_text"></div>
                            <div class="m-1" v-if="dossier.projections_croissance_pdf!=null">
                                <VuePdfEmbed  :source="dossier.projections_croissance_pdf" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>2. Prévisions de Bilan et Compte de Résultat</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <a class="text-white btn btn-sm btn-success" v-if="dossier.previsions_bilan_compte_resultat_excel!=null" :href="dossier.previsions_bilan_compte_resultat_excel"><i class="bx bxs-file-export"></i> Fichier Excel</a>
                            <div class="bg-green-lighten-5 rounded-3 p-2 m-1" v-html="dossier.previsions_bilan_compte_resultat_text"></div>
                            <div class="m-1" v-if="dossier.previsions_bilan_compte_resultat_pdf!=null">
                                <VuePdfEmbed  :source="dossier.previsions_bilan_compte_resultat_pdf" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>3. Hypothèses de Croissance et Indicateurs de Performance</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <a v-if="dossier.hypotheses_croissance_excel!=null" class="btn btn-sm btn-success text-white" :href="dossier.hypotheses_croissance_excel"><i class="bx bxs-file-export"></i> Fichier Excel</a>
                            <div class="bg-green-lighten-5 rounded-3 p-2" v-html="dossier.hypotheses_croissance_text"></div>
                            <div class="m-1" v-if="dossier.hypotheses_croissance_pdf!=null">
                                <VuePdfEmbed  :source="dossier.hypotheses_croissance_pdf" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </fieldset>
            <fieldset>
                <legend>Dossier Légal et Réglementaire <span><a data-bs-target="#legalModal" data-bs-toggle="modal" href="#" class="modifier"><i class="bx bx-edit"></i></a></span></legend>
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>1. Conformité Réglementaire</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div v-if="dossier.conformite_reglementaire!=null">
                                <VuePdfEmbed  :source="dossier.conformite_reglementaire" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>2. Autorisations et Licences</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div class="m-1" v-if="dossier.autorisations_licences!=null">
                                <VuePdfEmbed  :source="dossier.autorisations_licences" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>3. Historique de Litiges et Contentieux</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div v-if="dossier.historique_litiges_contentieux!=null">
                                <VuePdfEmbed  :source="dossier.historique_litiges_contentieux" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </fieldset>
            <fieldset>
                <legend>Annexes <span><a data-bs-target="#annexesModal" data-bs-toggle="modal" href="#" class="modifier"><i class="bx bx-edit"></i></a></span></legend>
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>1. Documents Financiers</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div v-if="dossier.documents_financiers!=null">
                                <VuePdfEmbed  :source="dossier.documents_financiers" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>2. Rapports d’Audit</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div v-if="dossier.rapports_audit!=null">
                                <VuePdfEmbed  :source="dossier.rapports_audit" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>3. Lettres de Recommandation et Références</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div v-if="dossier.lettres_recomandation_references!=null">
                                <VuePdfEmbed  :source="dossier.lettres_recomandation_references" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <h6>4. Documents Légaux (Statuts, Certificats, etc.)</h6>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div v-if="dossier.documents_legaux!=null">
                                <VuePdfEmbed  :source="dossier.documents_legaux" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </fieldset>
            <Analyse @success="onSuccess" @error="onError" :dossier="dossier" />
            <Strategy @success="onSuccess" @error="onError" :dossier="dossier"></Strategy>
            <Evaluation @success="onSuccess" @error="onError" :dossier="dossier"></Evaluation>
            <Project @success="onSuccess" @error="onError" :dossier="dossier"></Project> 
            <Legal @success="onSuccess" @error="onError" :dossier="dossier"></Legal>  
            <Annexe @success="onSuccess" @error="onError" :dossier="dossier"></Annexe>            
        </div>
    </main>
</template>
<script>
import axios from "axios";
import Analyse from "./Includes/Analyse.vue";
import Strategy from "./Includes/Strategie.vue";
import Evaluation from "./Includes/Evaluation.vue";
import Project from "./Includes/Projections.vue";
import Legal from "./Includes/Legal.vue";
import Annexe from "./Includes/Annexe.vue";
import { createToaster } from "@meforma/vue-toaster";
import VuePdfEmbed from 'vue-pdf-embed';


export default {
    name:"ShowEntreprise",
    props: ['tkn'],
    components:{
        Analyse,
        Strategy,
        Evaluation,
        Project,
        Legal,
        VuePdfEmbed,
        Annexe,
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            dossier:{},
            toaster: createToaster({ position:'top-right'}),
        }
    },
    methods:{
        onSuccess(){
            this.load();
            this.toaster.success("Mise a jour effectuee avec success!");
        },

        onError(){
            this.toaster.error("Une erreur est survenue lors de la mise a jour du dossier!");
        },

        async load(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.get(this.path+'cga/dimfs/'+this.tkn)
                        .then((res)=>{
                            this.dossier = res.data;
                        })
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
       
    },
    mounted(){
        this.load().then(()=>console.log(this.dossier))
        
    }

}
</script>

<style scoped>
    .vue-pdf-embed{
        height: 360px;
        overflow: scroll;
    }
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
</style>