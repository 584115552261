<template>
    <div class="modal fade" id="performanceModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">PRESENTATION DES PERFORMANCES FINANCIERES (Fichier Excel)</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" id="close31" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form enctype="multipart/form-data" class="form" @submit.prevent="submit">
                        <input type="file" class="form-control" name="file" id="file" @change="fileChange">
                        <div class="form-group mt-2">
                            <button class="btn btn-success" type="submit">ENREGISTRER</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default{
    name:'PlanFinacierComponent',
    components:{
    },
    props:{
        dossier:{
                'type':Object,
                'required':true,
            }
    },
    data(){
        return {
            performance:null,
            toaster: createToaster({ position:'top-right'}),
            editor: ClassicEditor,
            editorConfig: this.editorConfig,

            }
        },
        methods:{
            fileChange(event){
                this.performance = event.target.files[0];
            },
            submit(){
                document.getElementById('close31').click();
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
                let form = new FormData();
                if(this.performance!=null){
                    form.append("file",this.performance);
                }
                form.append("seed_id",this.dossier.id);
                axios.post(this.path+'owner/seeds/performance_financiere',form,{
                    headers:{
                        'Accept':'multipart/form-data'
                    }
                })
                .then((res)=>{
                    console.log(res)
                    this.$emit('ended');
                })
                .catch((err)=>{
                    if(err.response.status == 411){
                        this.toaster.error("Echec d'enregistrement. L'extension d'un des fichiers n'est pas au format Excel !");
                    }else{
                        this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
                    }
                    console.log(err)
                })
                .finally(()=>loader.hide())
            }
        },
        mounted(){
            this.presentation_problematique = this.dossier.presentation_problematique;
            //this.segments = this.dossier.segments;
        }
    }

</script>