
<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <div class="d-flex justify-content-between">
                    <ol>
                        <li><a href="/">ACCUEIL</a></li>
                        <li>DOSSIERS PME</li>
                        <li>{{ dossier.type.name }} </li>
                    </ol>
                    <div>
                        <div class="dropdown">
                            <a class="dropdown-toggle text-bold" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Actions</a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#besoinModal">Description du besoin</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#infModal">Informations financières de l'entreprise</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#ratiosModal">Ratios financiers</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#historiqueModal">Historique de crédit</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#planModal">Plan d'utilisation des fonds</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#collateralModal">Collatéral</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#structureModal">Structure de remboursement</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#lettreModal">Lettre de motivation</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#rexModal">Résumé exécutif</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#docModal">Ajouter un document</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#audioFrModal">PITCH EN FRANCAIS</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#audioEnModal">PITCH EN ANGLAIS</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#audioLocaleModal">PITCH DANS UNE LANGUE LOCALE</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-center gap-4">
                    <div v-if="dossier.entreprise!=null && dossier.entreprise!=undefined">
                        <div>
                            <router-link to="#">
                                <div class="text-center">
                                    <div>
                                        <img class="img img-circle" :src="dossier.entreprise.photo" style="height: 100px; width: 100px;" alt="">
                                    </div>
                                    <h6>{{ dossier.entreprise.name }}</h6>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="align-content-center">
                        <h2>{{ dossier.type.name }}</h2>
                        <small>{{ dossier.created }}</small>
                    </div>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="d-flex justify-center">      
             <div v-if="dossier.type_id==2" style="width:800px;" class="d-flex flex-column justify-center">
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>DESCRIPTION DU BESOIN</legend>
                                <div v-html="dossier.besoin"></div>
                                <div v-if="dossier.description_bien_uri!=null">
                                    <VuePdfEmbed  :source="dossier.description_bien" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>Informations financières de l'entreprise</legend>
                                <div v-html="dossier.informations_financieres_text"></div>
                                <div class="d-flex justify-center gap-3">
                                    <a v-if="dossier.bilan" class="btn btn-success text-white btn-sm" :href="dossier.bilan"><i class="bi bi-file-earmark-excel"></i> BILAN</a>
                                    <a v-if="dossier.compte_resultat" class="btn btn-success text-white btn-sm" :href="dossier.compte_resultat"><i class="bi bi-file-earmark-excel"></i> COMPTE DE RESULTAT</a>
                                    <a v-if="dossier.flux_treso" class="btn btn-success text-white btn-sm" :href="dossier.flux_treso"><i class="bi bi-file-earmark-excel"></i> TABLEAU DE FLUX DE TRESORERIE</a>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>Ratios financiers</legend>
                                <div v-html="dossier.ratios_financiers_text"></div>
                                <div v-if="dossier.ratios_financiers">
                                    <VuePdfEmbed  :source="dossier.ratios_financiers" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>Historique de crédit</legend>
                                <div v-html="dossier.historique_credit_text"></div>
                                <div v-if="dossier.historique_credit">
                                    <VuePdfEmbed  :source="dossier.historique_credit" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>Plan d'utilisation des fonds</legend>
                                <div v-html="dossier.plan_utilisation_text"></div>
                                <div v-if="dossier.plan_utilisation_excel" class="text-center m-2">
                                    <a class="btn btn-success text-white btn-sm" :href="dossier.plan_utilisation_excel"><i class="bi bi-file-earmark-excel"></i> DOCUMENT EXCEL</a>
                                </div>
                                <div v-if="dossier.plan_utilisation_pdf">
                                    <VuePdfEmbed  :source="dossier.plan_utilisation_pdf" />
                                </div>
                                
                                <div>

                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>COLLATERAL</legend>
                                <div v-html="dossier.collateral_text"></div>
                                <div v-if="dossier.collateral">
                                    <VuePdfEmbed  :source="dossier.collateral" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>Structure de remboursement</legend>
                                <div v-html="dossier.structure_remboursement_text"></div>
                                <div v-if="dossier.structure_remboursement_excel" class="text-center m-2">
                                    <a class="btn btn-success text-white btn-sm" :href="dossier.structure_remboursement_excel"><i class="bi bi-file-earmark-excel"></i> DOCUMENT EXCEL</a>
                                </div>
                                <div v-if="dossier.structure_remboursement_pdf">
                                    <VuePdfEmbed  :source="dossier.structure_remboursement_pdf" />
                                </div>
                                
                                <div>

                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>LETTRE DE MOTIVATION</legend>
                                <div v-if="dossier.lettre_motivation">
                                    <VuePdfEmbed  :source="dossier.lettre_motivation" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>RESUME EXECUTIF</legend>
                                <div v-html="dossier.resume_executif_text"></div>
                                <div v-if="dossier.resume_executif">
                                    <VuePdfEmbed  :source="dossier.resume_executif" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>

                <hr>
                <h5>DOCUMENTS LEGAUX ET COMMERCIAUX</h5>
                <div v-for="doc in dossier.docs" :key="doc.id">
                    <fieldset>
                        <legend>{{ doc.name }}</legend>
                        <div v-if="doc.url">
                            <VuePdfEmbed  :source="doc.url" />
                        </div>
                    </fieldset>
                </div>
            </div>
            </div>
        </div>
        <Besoin @success="endSuccess()" @error="endError()" :dossier="dossier"></Besoin>
        <InfoFin @success="endSuccess()" @error="endError()" :dossier="dossier"></InfoFin>
        <Document @success="endSuccess()" @error="endError()" :dossier="dossier"></Document>
        <Collateral @success="endSuccess()" @error="endError()" :dossier="dossier"></Collateral>
        <PlanUtil @success="endSuccess()" @error="endError()" :dossier="dossier"></PlanUtil>
        <Resume @success="endSuccess()" @error="endError()" :dossier="dossier"></Resume>
        <Lettre @success="endSuccess()" @error="endError()" :dossier="dossier"></Lettre>
        <Historique @success="endSuccess()" @error="endError()" :dossier="dossier"></Historique>
        <Structure @success="endSuccess()" @error="endError()" :dossier="dossier"></Structure>
        <Ratios @success="endSuccess()" @error="endError()" :dossier="dossier"></Ratios>
    </main>
</template>
<script>
import VuePdfEmbed from 'vue-pdf-embed'
import { createToaster } from "@meforma/vue-toaster";
import Besoin from './Includes/Besoin.vue';
import InfoFin from '../Includes/InfoFin.vue';
import Document from '../Includes/Document.vue';

import Collateral from './Includes/Collateral.vue';
import PlanUtil from './Includes/PlanUtil.vue';
import Lettre from './Includes/Lettre.vue';
import Resume from './Includes/Resume.vue';
import Historique from './Includes/Historique.vue';
import Structure from './Includes/Structure.vue';
import Ratios from './Includes/Ratios.vue';

export default{
    name:"ShowDossier",
    props:{
        dossier:{
            type:Object,
        },
    },
    components:{
        Besoin,
        VuePdfEmbed,
        InfoFin,
        Document,
        PlanUtil,
        Collateral,
        Lettre,
        Resume,
        Structure,
        Historique,
        Ratios
        
    },
    data(){
        return {
           toaster: createToaster({ position:'top-right'}),
        }
    },
    methods:{
        endSuccess(){
            this.$emit('success');
        },
        endError(){
            this.$emit('error');
        },
    },
    watch:{
            dossier(newValue,oldValue){
                console.log(oldValue)
                console.log(newValue)
            }
        },
    mounted(){
        //this.load().then(()=>console.log(this.dossier))
        
    },
    computed:{ 
    }

}
</script>

<style scoped>
    .vue-pdf-embed {
        max-height: 300px;
        overflow: scroll;
    }
</style>