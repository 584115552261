<template>
  <div style="max-width: 700px; margin: 30px auto;" class="container">
      <div class="card">
          <div class="card-body">
              <h4>{{ dossier.name }}</h4>
              <fieldset>
                <legend>DIAGNOSTIC INTERNE</legend>
                <div v-html="dossier.diagnostic_interne "></div>
              </fieldset>
              <fieldset>
                <legend>LETTRE DE DEMANDE DE FINANCEMENT</legend>
                <div v-html="dossier.demande_financement"></div>
              </fieldset>
              <hr>
              <h5>LISTE DES DOCUMENTS</h5>
              <div class=""><a type="button" class="btn btn-xs btn-warning" data-bs-toggle="modal" data-bs-target="#addFile" style="padding: 5px; font-size: 10px;" ><i class="bi bi-file-arrow-up" title="Ajouter un fichier"></i>Nouveau fichier</a></div>
              <ul class="list-group">
                <li v-for="(fichier,index) in dossier.fichiers" :key="index" class="list-group-item">
                  <a :href="fichier.path">{{ fichier.name }}</a>
                </li>
              </ul>
              
          </div>
      </div>
      <!-- Modal -->
    <div class="modal fade" id="addFile" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">NOUVEAU FICHIER</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" id="close" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="submit">
                      <div class="form-group">
                          <label for="">INTITULE DU DOCUMENT</label>
                          <input class="form-control" type="text" v-model="fichier.name">
                      </div>
                      <div class="form-group">
                          <label for="">FICHIER (<span style="font-size: smaller;">en pdf</span>)</label>
                          <input type="file" class="form-control" name="file" id="file" @change="fileChange"/>
                      </div>
                      <div class="form-group">
                        <button type="submit" class="btn btn-success btn-block">ENREGISTRER</button>
                      </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import { createToaster } from "@meforma/vue-toaster";
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  export default {

    name: "ShowBfr",
    props: ['tkn'],
    data() {
      return {
        step: 1,
        loading: false,
        fichier:{},
        toaster: createToaster({ position:'top-right'}),
        editor: ClassicEditor,
        editorConfig: this.editorConfig,
        dossier:{},
        // NB: Use computed properties just in case you intend using translations (e.g this.$t)
      };
    },
    methods: {
      submit(){
                document.getElementById('close').click();
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
                let form = new FormData();
                form.append("file",this.fichier.file);
                form.append("name",this.fichier.name);
                
                form.append("bfr_id",this.dossier.id);
                axios.post(this.path+'owner/tresorerie/fichier',form,{
                    headers:{
                        'Accept':'multipart/form-data'
                    }
                })
                .then((res)=>{
                    console.log(res);
                    this.reload();
                })
                .catch((err)=>{
                    this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
                    console.log(err)
                })
                .finally(()=>loader.hide())
      },
      fileChange(event){
          this.fichier.file = event.target.files[0];
      },
        async load(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.get(this.path+'owner/tresoreries/'+this.tkn)
                        .then((res)=>this.dossier=res.data)
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        async reload(){
            await axios.get(this.path+'owner/tresoreries/'+this.tkn)
                        .then((res)=>{
                            this.dossier = res.data;
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
      this.load()
    }
  };
  </script>

<style scoped>
 fieldset{
    padding: 15px;
    border: 1px #b9b9b9 solid;
    border-radius: 5px;
    margin: 10px 0;
}

legend{
    font-weight: 700;
    font-size: 11px;
    font-family: "segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #000000;
    padding: 5px;
    width: auto;
    float: none;
}
</style>