
<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <div class="d-flex justify-content-between">
                    <ol>
                        <li><a href="/">ACCUEIL</a></li>
                        <li>DOSSIERS PME</li>
                        <li>{{ dossier.type.name }} </li>
                    </ol>
                    <div>
                        <div class="dropdown">
                            <a class="dropdown-toggle text-bold" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Actions</a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#besoinModal">Description du besoin</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#infModal">Informations financières de l'entreprise</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#releveModal">Releve de compte</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#justificatifModal">Justificatifs des revenus</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#prevfinModal">Previsions financieres</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#depensesModal">Liste des dépenses de l’entreprise</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#plan_rrModal">Plan de remboursement et de redressement</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#lettreModal">Lettre d'explication et de justification</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#plan_tresoModal">Plan de trésorerie</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#docModal">Ajouter un document</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#audioFrModal">PITCH EN FRANCAIS</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#audioEnModal">PITCH EN ANGLAIS</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#audioLocaleModal">PITCH DANS UNE LANGUE LOCALE</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-center gap-4">
                    <div v-if="dossier.entreprise!=null && dossier.entreprise!=undefined">
                        <div>
                            <router-link to="#">
                                <div class="text-center">
                                    <div>
                                        <img class="img img-circle" :src="dossier.entreprise.photo" style="height: 100px; width: 100px;" alt="">
                                    </div>
                                    <h6>{{ dossier.entreprise.name }}</h6>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="align-content-center">
                        <h2>{{ dossier.type.name }}</h2>
                        <small>{{ dossier.created }}</small>
                    </div>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="d-flex justify-center">      
             <div v-if="dossier.type_id==1" style="width:800px;" class="d-flex flex-column justify-center">
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>DESCRIPTION DU BESOIN</legend>
                                <div v-html="dossier.besoin"></div>
                                <div v-if="dossier.description_bien_uri!=null">
                                    <VuePdfEmbed  :source="dossier.description_bien" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>Informations financières de l'entreprise</legend>
                                <div v-html="dossier.informations_financieres_text"></div>
                                <div class="d-flex justify-center gap-3">
                                    <a v-if="dossier.bilan" class="btn btn-success text-white btn-sm" :href="dossier.bilan"><i class="bi bi-file-earmark-excel"></i> BILAN</a>
                                    <a v-if="dossier.compte_resultat" class="btn btn-success text-white btn-sm" :href="dossier.compte_resultat"><i class="bi bi-file-earmark-excel"></i> COMPTE DE RESULTAT</a>
                                    <a v-if="dossier.flux_treso" class="btn btn-success text-white btn-sm" :href="dossier.flux_treso"><i class="bi bi-file-earmark-excel"></i> TABLEAU DE FLUX DE TRESORERIE</a>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>RELEVE DE COMPTE</legend>
                                <div v-if="dossier.releve_compte!=null">
                                    <VuePdfEmbed  :source="dossier.releve_compte" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>JUSTIFICATIF DE REVENUS</legend>
                                <div v-if="dossier.justificatif_revenus!=null">
                                    <VuePdfEmbed  :source="dossier.justificatif_revenus" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>PREVISIONS FINANCIERES</legend>
                                <a v-if="dossier.previsions_financieres_excel!=null" class="btn btn-success btn-sm text-center" :href="dossier.previsions_financieres_excel">Fichier Excel</a>
                                <div v-if="dossier.previsions_financieres_pdf!=null">
                                    <VuePdfEmbed  :source="dossier.previsions_financieres_pdf" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>Liste des dépenses de l’entreprise</legend>
                                <div v-if="dossier.depenses!=null">
                                    <VuePdfEmbed  :source="dossier.depenses" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>Plan de remboursement et de redressement</legend>
                                <a v-if="dossier.plan_remboursement_redressement_excel!=null" class="btn btn-success btn-sm text-center" :href="dossier.previsions_financieres_excel">Fichier Excel</a>
                                <div v-if="dossier.plan_remboursement_redressement_pdf!=null">
                                    <VuePdfEmbed  :source="dossier.plan_remboursement_redressement_pdf" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>Lettre d'explication et de justification</legend>
                                <div v-if="dossier.lettre_explication_justification!=null">
                                    <VuePdfEmbed  :source="dossier.lettre_explication_justification" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>PLAN DE TRESORERIE</legend>
                                <a v-if="dossier.projections_flux_treso_excel!=null" class="btn btn-success btn-sm text-center" :href="dossier.projections_flux_treso_excel">Fichier Excel</a>
                                <div v-if="dossier.projections_flux_treso_pdf!=null">
                                    <VuePdfEmbed  :source="dossier.projections_flux_treso_pdf" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <hr>
                <h3>DOCUMENTS LEGAUX ET COMMERCIAUX</h3>
                <div v-for="doc in dossier.docs" :key="doc.id">
                    <fieldset>
                        <legend>{{ doc.name }}</legend>
                        <div v-if="doc.url">
                            <VuePdfEmbed  :source="doc.url" />
                        </div>
                    </fieldset>
                </div>
            </div>
            </div>
        </div>
        <Besoin @success="endSuccess()" @error="endError()" :dossier="dossier"></Besoin>
        <PrevFin @success="endSuccess()" @error="endError()" :dossier="dossier"></PrevFin>
        <ReleveCompte @success="endSuccess()" @error="endError()" :dossier="dossier"></ReleveCompte>
        <InfoFin @success="endSuccess()" @error="endError()" :dossier="dossier"></InfoFin>
        <PlanRedressement @success="endSuccess()" @error="endError()" :dossier="dossier"></PlanRedressement>
        <Document @success="endSuccess()" @error="endError()" :dossier="dossier"></Document>
        <PlanTreso @success="endSuccess()" @error="endError()" :dossier="dossier"></PlanTreso>
        <LettreExplication @success="endSuccess()" @error="endError()" :dossier="dossier"></LettreExplication>
        <Depense @success="endSuccess()" @error="endError()" :dossier="dossier"></Depense>
        <Justificatif @success="endSuccess()" @error="endError()" :dossier="dossier"></Justificatif>
    </main>
</template>
<script>
import VuePdfEmbed from 'vue-pdf-embed'
import { createToaster } from "@meforma/vue-toaster";
import Besoin from './Includes/Besoin.vue';
import ReleveCompte from './Includes/ReleveCompte.vue';
import PrevFin from './Includes/PrevFin.vue';
import InfoFin from '../Includes/InfoFin.vue';
import Document from '../Includes/Document.vue';
import PlanRedressement from './Includes/PlanRedressement.vue';
import LettreExplication from './Includes/LettreExplication.vue';
import PlanTreso from './Includes/PlanTreso.vue';
import Depense from './Includes/Depenses.vue';
import Justificatif from './Includes/Justificatif.vue';
export default{
    name:"ShowDossier",
    props:{
        dossier:{
            type:Object,
        },
    },
    components:{
        Besoin,
        VuePdfEmbed,
        PrevFin,
        ReleveCompte,
        InfoFin,
        Document,
        PlanRedressement,
        LettreExplication,
        PlanTreso,
        Depense,
        Justificatif,
    },
    data(){
        return {
           toaster: createToaster({ position:'top-right'}),
        }
    },
    methods:{
        endSuccess(){
            this.$emit('success');
        },
        endError(){
            this.$emit('error');
        },
    },
    watch:{
            dossier(newValue,oldValue){
                console.log(oldValue)
                console.log(newValue)
            }
        },
    mounted(){
       // this.load().then(()=>console.log(this.dossier))
        
    },
    computed:{ 
    }

}
</script>

<style scoped>
    .vue-pdf-embed {
        max-height: 300px;
        overflow: scroll;
    }
</style>