<template>
       <main class="main"> 
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>LES IMF</li>
                <li>NOUVELLE IMF</li>
                </ol>
                <h2 class="title">NOUVELLE INSTITUTION FINANCIERE ET MONETAIRE</h2>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container d-flex justify-center">
            <div class="card" style="width: 700px;">
                <div class="card-body d-flex gap-2 flex-wrap justify-content-center">
                  <div class="form-group">
                    <label for="">NOM </label>
                    <input type="text" placeholder="nom" v-model="dossier.name" class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="">TYPE D'IMF</label>
                    <select name="" id="" v-model="dossier.type_id" class="form-control">
                      <option value=0>CHOISIR ...</option>
                      <option v-for="t in types" :key="t.id" :value="t.id">{{ t.name }}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="">ADRESSE</label>
                    <input type="text" placeholder="Adresse" v-model="dossier.address" class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="">TELEPHONE</label>
                    <input type="text" placeholder="TELEPHONE" v-model="dossier.phone" class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="">EMAIL</label>
                    <input type="text" placeholder="Adresse Email" v-model="dossier.email" class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="">LOGO</label>
                    <input type="file"  @change="handlePhoto" class="form-control">
                  </div>
                  <fieldset>
                      <legend>Présentation de l’institution financière monétaire</legend>
                      <div class="">
                      <div class="form-group">
                        <ckeditor class="form-control" placeholder="Saisir le texte de presentation ..." :editor="editor" v-model="dossier.presentation_text" :config="editorConfig"></ckeditor>
                      </div>
                      <div class="form-group">
                          <label for="">Fichier PDF</label>
                          <input type="file" @change="handlePresentation" class="form-control">
                      </div>
                  </div>
                  </fieldset>
                  <fieldset>
                      <legend>Structure de l’Entreprise</legend>
                      <div class="">
                      <div class="form-group">
                        <ckeditor class="form-control" placeholder="Saisir ici ..." :editor="editor" v-model="dossier.structure_text" :config="editorConfig"></ckeditor>
                      </div>
                      <div class="form-group">
                          <label for="">Fichier PDF</label>
                          <input type="file" @change="handleStructure" class="form-control">
                      </div>
                  </div>
                  </fieldset>
                  <fieldset>
                      <legend>Principales Activités et Services </legend>
                      <div class="">
                      <div class="form-group">
                        <ckeditor class="form-control" placeholder="Saisir ..." :editor="editor" v-model="dossier.activites_services_text" :config="editorConfig"></ckeditor>
                      </div>
                      <div class="form-group">
                          <label for="">Fichier PDF</label>
                          <input type="file" @change="handleActivites" class="form-control">
                      </div>
                  </div>
                  </fieldset>
                  <fieldset>
                      <legend>Gouvernance et Équipe de Direction</legend>
                      <div class="">
                      <div class="form-group">
                        <ckeditor class="form-control" placeholder="Saisir ..." :editor="editor" v-model="dossier.equipe_text" :config="editorConfig"></ckeditor>
                      </div>
                      <div class="form-group">
                          <label for="">Fichier PDF</label>
                          <input type="file" @change="handleEquipe" class="form-control">
                      </div>
                  </div>
                  </fieldset>
                  <fieldset>
                      <legend>Filiales et Partenariats Stratégiques</legend>
                      <div class="">
                      <div class="form-group">
                        <ckeditor class="form-control" placeholder="Saisir ..." :editor="editor" v-model="dossier.filiales_partenariats_text" :config="editorConfig"></ckeditor>
                      </div>
                      <div class="form-group">
                          <label for="">Fichier PDF</label>
                          <input type="file" @change="handleFiliales" class="form-control">
                      </div>
                  </div>
                  </fieldset>
                </div>
                <div class="card-footer text-center">
                    <button type="button" @click="submit()" class="btn btn-success"><i class="bx bx-save"></i> ENREGISTRER</button>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
  import axios from 'axios';
  import { createToaster } from "@meforma/vue-toaster";
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  export default {
    components: {
    },
    name: "CreateDossier",
    data() {
      return {
        toaster: createToaster({ position:'top-right'}),
        editor: ClassicEditor,
        editorConfig: this.editorConfig,
        dossier:{
          type_id:0,
        },
        presentation:null,
        photo:null,
        structure:null,
        activites_services:null,
        equipe:null,
        filiales_partenariats:null,
        types:[],
      };
    },
  
    mounted(){
      this.load();
    },
    methods: {
      async load(){
        let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
        await axios.get(this.path+'cga/institutions/create')
              .then((res)=>{
                this.types = res.data;
              })
              .catch((err)=>console.log(err))
              .finally(()=>loader.hide())
      },
      handlePhoto(event){
        this.photo = event.target.files[0];
      },
      handlePresentation(event){
        this.presentation = event.target.files[0];
      },
      handleStructure(event){
        this.structure = event.target.files[0];
      },
      handleActivites(event){
        this.activites_services = event.target.files[0];
      },
      handleEquipe(event){
        this.equipe = event.target.files[0];
      },
      handleFiliales(event){
        this.filiales_partenariats = event.target.files[0];
      },
      async submit(){
        let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            let form = new FormData();
            if(this.photo!=null){
              form.append("photo",this.photo);
            }
            if(this.presentation!=null){
              form.append("presentation",this.presentation);
            }
            if(this.structure!=null){
              form.append("structure",this.structure);
            }
            if(this.equipe!=null){
              form.append("equipe",this.equipe);
            }
            if(this.activites_services!=null){
              form.append("activites_services",this.activites_services);
            }
            if(this.filiales_partenariats!=null){
              form.append("filiales_partenariats",this.filiales_partenariats);
            }
            form.append('institution',JSON.stringify(this.dossier));    
            await axios.post(this.path+'cga/institutions',form)
                        .then((res)=>{
                            this.dossier = res.data;
                            this.toaster.success("Creation de dossier effectuee avec succes !!!");
                            this.$router.push({name:'cga_institutions_index'})
                        })
                        .catch((err)=>{
                          console.error(err);
                          this.toaster.error("Echec de creation de l'institution!");
                        })
                        .finally(()=>loader.hide());
      },
    },
  };
  </script>

<style scoped>
  
    .bg{
      background-color: #efefef;
    }
</style>