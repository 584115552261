import CompanyDashboard from '@/components/Company/Dashboard.vue'
//Composant de l'admin Pme (Company)
import CompanyDossierIndex from "@/components/Company/Dossiers/Index.vue"
import CompanyDossierShow from "@/components/Company/Dossiers/Show.vue"
import CompanyDossierCreate from "@/components/Company/Dossiers/Create.vue"

export default[

        {
            name: "company_dashboard",
            path: '/company/dashboard',
            component: CompanyDashboard,
            meta: {
                title: `Tableau de bord`,
                middleware: "auth"
            }
        },
        {
            name: "company_dossiers_index",
            path: '/company/dossiers',
            component: CompanyDossierIndex,
            meta: {
                title: `Listes des dossiers`,
                middleware: "auth"
            }
        },
        {
            name: "company_dossier",
            path: '/company/dossier/:tkn',
            component: CompanyDossierShow,
            props: true,
            meta: {
                title: `Details du Dossier`,
                middleware: "auth"
            }
        },
        {
            name: "company_dossiers_create",
            path: '/company/dossiers/create',
            component: CompanyDossierCreate,
            meta: {
                title: `Creation du dossier`,
                middleware: "auth"
            }
        },
]