<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>SUIVI DES INCUBATEURS</li>
                </ol>
                <h2>MA LISTE D'INCUBATEURS</h2>

            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="mb-3">
                <button data-bs-toggle="modal" data-bs-target="#addModal" class="btn-sm btn-success"><i class="bx bx-plus-circle"></i> CREER</button>
            </div>
            <table class="table table-sm table-bordered">
                <thead>
                    <tr>
                        <th>DATE</th>
                        <th>INCUBATEURS</th>
                        <th>ADRESSE</th>
                        <th>TELEPHONE</th>
                        <th>EMAIL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(incubateur,index) in incubateurs" :key="index">
                        <td>{{ incubateur.created }}</td>
                        <td>
                            <router-link :to="{name:'advisor_incubateur', params: {tkn: incubateur.token}}">{{ incubateur.name }}</router-link>
                        </td>
                        <td>{{ incubateur.address }}</td>
                        <td>{{ incubateur.phone }}</td>
                        <td>{{ incubateur.email }}</td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Modal -->
    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">NOUVEL INCUBATEUR</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" id="close" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <form @submit.prevent="submit" action="">
                            <div class="form-group">
                                <label for="">NOM</label>
                                <input type="text" v-model="incubateur.name" class="form-control">
                            </div>
                            <div class="form-group">
                                <label for="">ADRESSE</label>
                              <input type="text" v-model="incubateur.address" class="form-control" placeholder="Adresse"  name="address" >
                            </div>
                            <div class="form-group">
                              <label for="longitude">TELEPHONE</label>
                              <input type="text" v-model="incubateur.phone" class="form-control" placeholder="Telephone" id="longitude" name="phone" >
                            </div>
                            <div class="form-group">
                              <input type="email" v-model="incubateur.email" class="form-control" placeholder="Email" id="longitude" name="email" >
                            </div>
                            <div class="form-group">
                              <label for="longitude">LOGO</label>
                              <input type="file" class="form-control" ref="logo" @change="handleLogo($event)"  id="longitude" name="imageUri" >
                            </div>
                            <div class="form-group">
                                <label for="type_id">TYPE DE LICENSE</label>
                                <select class="form-control" name="" @change="setType($event)" id="type_id">
                                    <option value="">SELECTIONNER ...</option>
                                    <option v-for="(opt,index) in types" :key="index" :value="opt.id"> {{ opt.name }}</option>
                                </select>
                            </div>
                            <fieldset>
                                <legend>Compte de l'admininistrateur</legend>
                                <div class="form-group">
                                    <input type="text" v-model="incubateur.last_name" class="form-control" placeholder="Nom"  name="last_name" >
                                  </div>
                                  <div class="form-group">
                                    <input type="text" class="form-control" v-model="incubateur.first_name" placeholder="Prenom"  name="first_name" >
                                  </div>
                                  <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Telephone" v-model="incubateur.user_phone" id="user_phone" name="user_phone" >
                                  </div>
                                  <div class="form-group">
                                    <input type="email" placeholder="Email" v-model="incubateur.user_email" class="form-control" id="user_email" name="user_email" >
                                  </div>
                                  <div class="form-group">
                                    <input type="password" placeholder="Mot de passe" class="form-control" v-model="incubateur.password" id="password" name="password" >
                                  </div>
                            </fieldset>
                            <button type="submit" class="btn btn-success btn-sm">ENREGISTRER</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"IndexIncubateurs",
    data(){
        return {
            user:this.$store.state.auth.user,
            incubateurs:[],
            incubateur:{},
            types: [],
            logo:null,
            loading: false,
            toaster: createToaster({ position:'top-right'}),
        }
    },
    methods:{
        async load(){
            await axios.get(this.path+'advisor/licenses')
                        .then((res)=>{
                            this.incubateurs=res.data.revendeurs;
                            this.types = res.data.types;
                        })
                        .catch((err)=>console.error(err));
        },
        async submit(){
            this.loading = true;
            const form = new FormData();
            form.append("logo",this.logo);
            form.append("incubateur",JSON.stringify(this.incubateur));
            axios.post(this.path+"advisor/licenses", form, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                function(event){
                    this.progress = Math.round((100 * event.loaded) / event.total);
                }
                })
                .then((response)=>{
                    console.log(response.data)
                    this.toaster.success("Enregistrement effectue avec succes !!!");
                    this.$router.push({path:'/advisor/incubateurs'});
                })
                .catch((err)=>{
                    console.error(err);
                    this.toaster.error("Echec lors de l'enregistrement du dossier !!!");
                });
        },

        setType(event){
            console.log(event)
            this.incubateur.type_id = event.target.value;
        },
        handleLogo(event){
            this.logo = event.target.files[0];
        },
    },
    mounted(){
        this.load().then(()=>{
            console.log(this.incubateurs);
            console.log(this.types);
        })
        
    }

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    a{
        color: #3d9970;
        text-decoration: none;
    }
</style>