<template>
<div class="modal fade" id="marketingModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">PLAN MARKETING</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="close10" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form enctype="multipart/form-data" class="form"  @submit.prevent="submit" method="post">
         
                    
                    <div class="form-group">
                        <label for="">Presentation du plan marketing</label>
                         <div v-html="dossier.presentation_marketing"></div>
                         <hr/>
                        <ckeditor :editor="editor" v-model="presentation_marketing" :config="editorConfig"></ckeditor>
                        
                    </div>
                    <div class="form-group">
                        <label for="">Fichier du plan marketing</label>
                        <input type="file" class="form-control" name="file" id="file" @change="fileChange">
                    </div>
                    <button class="btn btn-success" type="submit">ENREGISTRER</button>
                </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default{
    name:'ProblematiqueComponent',
    components:{
    },
    props:{
        dossier:{
                'type':Object,
                'required':true,
            }
    },
    data(){
        return {
            presentation_marketing:"",
            file:null,
            segments:this.dossier.segments,
            toaster: createToaster({ position:'top-right'}),

            editor: ClassicEditor,
                //editorData: '<p>Content of the editor.</p>',
            editorConfig: this.editorConfig


            }
        },
        methods:{
            fileChange(event){
                //console.log(this.dossier)
                this.file = event.target.files[0];
            },
            submit(){
                document.getElementById('close10').click();
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
                let form = new FormData();
                if(this.file!=null){
                    form.append("file",this.file);
                }
                form.append("presentation_marketing",this.presentation_marketing);
                form.append("preseed_id",this.dossier.id);
                axios.post(this.path+'owner/preseeds/marketing',form,{
                    headers:{
                        'Accept':'multipart/form-data'
                    }
                })
                .then((res)=>{
                    console.log(res)
                    this.$emit('ended');
                })
                .catch((err)=>{
                    this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
                    console.log(err)
                })
                .finally(()=>loader.hide())
            }
        },
        mounted(){
            this.presentation_marketing = this.dossier.presentation_marketing;
            //this.segments = this.dossier.segments;
        }
    }

</script>