<template>
       <main class="main"> 
          <section id="breadcrumbs" class="breadcrumbs">
              <div class="container">
                  <ol>
                  <li><a href="/">ACCUEIL</a></li>
                  <li>DOSSIERS</li>
                  <li>NOUVEAU DOSSIER PME</li>
                  </ol>
                  <h2 class="title">NOUVEAU DOSSIER PME</h2>
              </div>
          </section><!-- End Breadcrumbs -->

        <div class="container d-flex justify-center gap-2 flex-wrap">
            <div class="card" style="width: 400px;">
                <div class="card-body">
                  <div class="">
                    <div class="form-group">
                      <div class="">
                          <div class="form-group">
                            <label for="">TYPE DE DOSSIER</label>
                            <select @change="selectType" name="" id="" class="form-control">
                                <option value="0">Veuillez selectionner le type de dossier ...</option>
                                <option v-for="t in types" :key="t.id" :value="t.id">{{ t.name }}</option>
                            </select>
                          </div>
                          <div class="form-group">
                            <label for="">BESOIN EN FINANCEMENT</label>
                            <input type="number" class="form-control" v-model="dossier.besoin_financement" >
                          </div>
                          <div class="d-flex">
                            <div style="width: 250px;">
                                <label for="">ENTREPRISE</label>
                                <autocomplete  :debounce-time="500" :get-result-value="getResultValue" :search="search" @submit="selectEntreprise" placeholder="Rechercher une entreprise ..."></autocomplete>
                            </div>
                            <div style="width:100px" class="mt-4">
                              <router-link to="/cga/entreprises/create" class="btn btn-success btn-sm mt-2" title="Creer un nouvelle entreprise ..."><i class="bi bi-plus-circle"></i> Ajouter</router-link>
                            </div>
                          </div>
                          
                      </div>
                    </div>
                </div>
                </div>
                <div class="card-footer text-center">
                    <button type="button" @click="submit()" class="btn btn-success">SUIVANT <i class="bx bx-right-arrow"></i></button>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
  import axios from 'axios';
  import { createToaster } from "@meforma/vue-toaster";
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  import Autocomplete from '@trevoreyre/autocomplete-vue'

  export default {
    components: {
      Autocomplete,
    },
    name: "CreateDossier",
    data() {
      return {
        toaster: createToaster({ position:'top-right'}),
        editor: ClassicEditor,
        editorConfig: this.editorConfig,
        dossier:{
          type_id:0,
          besoin_financement:0,
        },
        entreprises:[],
        filteredEntreprises:[],
        types:[],
        selectedEntreprise:null,
      };
    },
  
    mounted(){
      this.load();
    },
    methods: {
      selectEnt(event){
        this.dossier.entreprise_id = event.target.value;
      },
      search(event){
        console.log(event);
        if (event.length < 1) { return [] }
        return this.entreprises.filter(entreprise => {
          return entreprise.name.toLowerCase()
            .startsWith(event.toLowerCase())
        })
      },
      getResultValue(result) {
        return result.name
      },
      selectEntreprise(result){
        console.log(result);
        this.selectedEntreprise = result; 
      },
      selectType(event){
        console.log(event.target.value);
        this.dossier.type_id = event.target.value; 
        console.log(this.dossier.type_id);
      },
      async submit(){
        if(this.selectedEntreprise!=null && this.dossier.type_id!=0){
          let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
                this.dossier.entreprise_id = this.selectedEntreprise.id;
                console.log(this.selectedEntreprise);    
            await axios.post(this.path+'cga/pmes',this.dossier)
                        .then((res)=>{
                            this.dossier = res.data;
                            this.toaster.success("Creation de dossier effectuee avec succes !!!");
                            this.$router.push({name:'cga_pmes_show',params:{tkn:this.dossier.token}})
                        })
                        .catch((err)=>{
                          console.error(err);
                          if(err.response.status==407){
                              this.toaster.error("Votre solde est insuffisant pour créer ce dossier. Veuillez effectuer une recharge.");
                          }else{
                            this.toaster.error("Echec de creation du dossier!");
                          }
                          
                        })
                        .finally(()=>loader.hide());
        }
        else{
          this.toaster.error("Veuillez selectionner une entreprise ou le type de dossier avant de poursuivre!");
          console.log(this.selectedEntreprise);
        }
      },
      async load(){
            await axios.get(this.path+'cga/pmes/create')
                .then((res)=>{
                  this.entreprises=res.data.entreprises;
                  this.types=res.data.types;
                })
                .catch((err)=>console.error(err));
        }
    },
  };
  </script>

<style scoped>  
    .bg{
      background-color: #efefef;
    }


</style>










