<template>
    <ul class="list-unstyled">
        <li v-for="choice in risque.question.choices" :key="choice.id">
            <input @change="change($event)" :taux='choice.taux' :value='choice.id' type="radio" :name='risque.question.id'>{{  choice.name}}
        </li>
    </ul>
</template>
<script>
    export default{
        name:'QuestionComponent',
        props:{
            risque:{
                type:Object,
                default:()=>({
                    id:0
                }),
            },
        },
        methods:{
            change(event){
                const elt = {
                    question:event.target.name,
                    choice:event.target.value,
                    taux:event.target.attributes['taux'].nodeValue,
                }
                this.$emit('choice-change',elt);
                //console.log(event);
            }
        }

    }

</script>
<style scoped>
    input[type='radio']{
        accent-color: #3d9970;
      }
</style>