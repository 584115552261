<template>
<div class="modal fade" id="annexesModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Annexes</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="close4" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div>
                    <ol>
                        <li>
                            <div class="form-group">
                                <label for="">Documents Financiers</label>
                                <input type="file" class="form-control" name="file" id="file" @change="finChange">
                            </div>
                        </li>
                        <li>
                            <div class="form-group">
                                <label for="">Rapports d’Audit</label>
                                <input type="file" class="form-control" name="file" id="file" @change="rapportChange">
                            </div>
                        </li>
                        <li>
                            <div class="form-group">
                                <label for="">Lettres de Recommandation et Références </label>
                                <input type="file" class="form-control" name="file" id="file" @change="lettreChange">
                            </div>
                        </li>
                        <li>
                            <div class="form-group">
                                <label for="">Documents Légaux (Statuts, Certificats, etc.)</label>
                                <input type="file" class="form-control" name="file" id="file" @change="legauxChange">
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
            <div class="modal-footer">
                <div class="form-group">
                    <button class="btn btn-success" @click="submit" data-bs-dismiss="modal" type="submit"><i class="bx bi-save"></i> ENREGISTRER</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
    export default{
        name:'AnalyseComponent',
        props:{
            dossier:{
                'type':Object,
                'required':true,
            }
        },
        data(){
            return {
                fin:null,
                rapport:null,
                lettre:null,
                legaux:null,
            }
        },
        methods:{
            finChange(event){
                this.fin = event.target.files[0];
            },
            lettreChange(event){
                this.lettre = event.target.files[0];
            },
            rapportChange(event){
                this.rapport = event.target.files[0];
            },
            legauxChange(event){
                this.legaux = event.target.files[0];
            },
            submit(){
                    let loader = this.$loading.show({
                        container: this.fullPage ? null : this.$refs.formContainer,
                        canCancel: false,
                    });
                    let form = new FormData();
                    if(this.fin!=null){
                        form.append("documents_financiers",this.fin);
                    }
                    if(this.rapport!=null){
                        form.append("rapports_audit",this.rapport);
                    }
                    if(this.lettre!=null){
                        form.append("lettres_recomandation_references",this.lettre);
                    }
                    if(this.legaux!=null){
                        form.append("documents_legaux",this.legaux);
                    }
                    delete this.dossier.institution;
                    form.append("dossier",JSON.stringify(this.dossier));
                    axios.post(this.path+'cga/dimf/save',form,{
                        headers:{
                            'Accept':'multipart/form-data'
                        }
                    })
                    .then((res)=>{
                        console.log(res)
                        this.$emit('success');
                    })
                    .catch((err)=>{
                        this.$emit('error')
                        console.log(err)
                    })
                    .finally(()=>loader.hide())
                
            }
        },
        computed:{
        },
        watch:{
            dossier(newValue,oldValue){
                console.log(oldValue)
                console.log(newValue)
            }
        },
        mounted(){
            
        }
    }

</script>

<style scoped>


</style>