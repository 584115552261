
<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <div class="d-flex justify-content-between">
                    <ol>
                        <li><a href="/">ACCUEIL</a></li>
                        <li>DOSSIERS PME</li>
                        <li>{{ dossier.type.name }} </li>
                    </ol>
                    <div>
                        <div class="dropdown">
                            <a class="dropdown-toggle text-bold" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Actions</a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#besoinModal">Description du besoin</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#analyseModal">Analyse de la situation actuelle</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#planredrModal">plan de redressement</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#prevfinModal">Prévisions financières et budgets</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#comModal">Communication et engagement des parties prenantes</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#risqueModal">Gestion des risques et suivi</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#docModal">Ajouter un document</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#audioFrModal">PITCH EN FRANCAIS</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#audioEnModal">PITCH EN ANGLAIS</a></li>
                                <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#audioLocaleModal">PITCH DANS UNE LANGUE LOCALE</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-center gap-4">
                    <div v-if="dossier.entreprise!=null && dossier.entreprise!=undefined">
                        <div>
                            <router-link to="#">
                                <div class="text-center">
                                    <div>
                                        <img class="img img-circle" :src="dossier.entreprise.photo" style="height: 100px; width: 100px;" alt="">
                                    </div>
                                    <h6>{{ dossier.entreprise.name }}</h6>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="align-content-center">
                        <h2>{{ dossier.type.name }}</h2>
                        <small>{{ dossier.created }}</small>
                    </div>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="d-flex justify-center">      
             <div v-if="dossier.type_id==11" style="width:800px;" class="d-flex flex-column justify-center">
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>DESCRIPTION DU BESOIN</legend>
                                <div v-html="dossier.besoin"></div>
                                <div v-if="dossier.description_bien_uri!=null">
                                    <VuePdfEmbed  :source="dossier.description_bien" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>Analyse de la situation actuelle</legend>
                                <div v-html="dossier.analyse_situation_actuelle_text"></div>
                                <div v-if="dossier.analyse_situation_actuelle_excel" class="text-center m-2">
                                    <a class="btn btn-success text-white btn-sm" :href="dossier.analyse_situation_actuelle_excel"><i class="bi bi-file-earmark-excel"></i> DOCUMENT EXCEL</a>
                                </div>
                                <div v-if="dossier.analyse_situation_actuelle_pdf">
                                    <VuePdfEmbed  :source="dossier.analyse_situation_actuelle_pdf" />
                                </div>
                                
                                <div>

                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>Plan de redressement</legend>
                                <div v-html="dossier.plan_redressement_text"></div>
                                <div v-if="dossier.plan_redressement_excel" class="text-center m-2">
                                    <a class="btn btn-success text-white btn-sm" :href="dossier.plan_redressement_excel"><i class="bi bi-file-earmark-excel"></i> DOCUMENT EXCEL</a>
                                </div>
                                <div v-if="dossier.plan_redressement_pdf">
                                    <VuePdfEmbed  :source="dossier.plan_redressement_pdf" />
                                </div>
                                
                                <div>

                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>Prévisions financières et budgets</legend>
                                <div v-html="dossier.previsions_financieres_text"></div>
                                <div v-if="dossier.previsions_financieres_excel" class="text-center m-2">
                                    <a class="btn btn-success text-white btn-sm" :href="dossier.previsions_financieres_excel"><i class="bi bi-file-earmark-excel"></i> DOCUMENT EXCEL</a>
                                </div>
                                <div v-if="dossier.previsions_financieres_pdf">
                                    <VuePdfEmbed  :source="dossier.previsions_financieres_pdf" />
                                </div>
                                <div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>Communication et engagement des parties prenantes</legend>
                                <div v-html="dossier.communication_engagement_text"></div>
                                <div v-if="dossier.communication_engagement">
                                    <VuePdfEmbed  :source="dossier.communication_engagement" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div  class="card bg-light">
                    <div class="card-body">
                        <div>
                            <fieldset>
                                <legend>Gestion des risques et suivi</legend>
                                <div v-html="dossier.gestion_risques_text"></div>
                                <div v-if="dossier.gestion_risques">
                                    <VuePdfEmbed  :source="dossier.gestion_risques" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>

                <hr>
                <h5>AUTRES DOCUMENTS</h5>
                <div v-for="doc in dossier.docs" :key="doc.id">
                    <fieldset>
                        <legend>{{ doc.name }}</legend>
                        <div v-if="doc.url">
                            <VuePdfEmbed  :source="doc.url" />
                        </div>
                    </fieldset>
                </div>
            </div>
            </div>
        </div>
        <Besoin @success="endSuccess()" @error="endError()" :dossier="dossier"></Besoin>
        <Communication @success="endSuccess()" @error="endError()" :dossier="dossier"></Communication>
        <Analyse @success="endSuccess()" @error="endError()" :dossier="dossier"></Analyse>
        <PlanRedressement @success="endSuccess()" @error="endError()" :dossier="dossier"></PlanRedressement>
        <PrevFin @success="endSuccess()" @error="endError()" :dossier="dossier"></PrevFin>
        <Document @success="endSuccess()" @error="endError()" :dossier="dossier"></Document>
        <Risque @success="endSuccess()" @error="endError()" :dossier="dossier"></Risque>
    </main>
</template>
<script>
import VuePdfEmbed from 'vue-pdf-embed'
import { createToaster } from "@meforma/vue-toaster";
import Besoin from '../Includes/Besoin.vue';
import Document from '../Includes/Document.vue';
import Analyse from './Includes/Analyse.vue';
import PlanRedressement from './Includes/PlanRedressement.vue'
import PrevFin from './Includes/PrevFin.vue';
import Communication from './Includes/Communication.vue';
import Risque from './Includes/Risque.vue';
export default{
    name:"ShowDossier",
    props:{
        dossier:{
            type:Object,
        },
    },
    components:{
        Besoin,
        VuePdfEmbed,
        Document,
        Analyse,
        PlanRedressement,
        PrevFin,
        Communication,
        Risque,
    },
    data(){
        return {
           toaster: createToaster({ position:'top-right'}),
        }
    },
    methods:{
        endSuccess(){
            this.$emit('success');
        },
        endError(){
            this.$emit('error');
        },
    },
    watch:{
            dossier(newValue,oldValue){
                console.log(oldValue)
                console.log(newValue)
            }
        },
    mounted(){
        
    },
    computed:{ 
    }

}
</script>

<style scoped>
    .vue-pdf-embed {
        max-height: 300px;
        overflow: scroll;
    }
</style>