import OwnerDashboard from '@/components/Owner/Dashboard.vue';
// Composants du owner
import OwnerPreseedIndex from "@/components/Owner/Preseeds/Index.vue"
import OwnerPreseedShow from '@/components/Owner/Preseeds/Show.vue'
import OwnerPreseedCreate from '@/components/Owner/Preseeds/Create.vue'
import OwnerSeedIndex from "@/components/Owner/Seeds/Index.vue"
import OwnerSeedShow from '@/components/Owner/Seeds/Show.vue'
import OwnerSeedUpdateInfos from '@/components/Owner/Seeds/UpdateInfos.vue'
import OwnerPreseedUpdateInfos from '@/components/Owner/Preseeds/UpdateInfos.vue'
import OwnerSeedCreate from '@/components/Owner/Seeds/Create.vue'
import OwnerPlan from "@/components/Owner/Plans/Show.vue"
import OwnerPligne from "@/components/Owner/Plans/Pligne.vue"
import OwnerRechargesIndex from '@/components/Owner/Recharges/Index.vue';
import OwnerBfrIndex from '@/components/Owner/Bfrs/Index.vue';
import OwnerBfrCreate from '@/components/Owner/Bfrs/Create.vue';
import OwnerBfrShow from '@/components/Owner/Bfrs/Show.vue';
import OwnerTresoIndex from '@/components/Owner/Tresoreries/Index.vue';
import OwnerTresoCreate from '@/components/Owner/Tresoreries/Create.vue';
import OwnerTresoShow from '@/components/Owner/Tresoreries/Show.vue';

import OwnerStartupIndex from '@/components/Owner/Startups/Index.vue';
import OwnerStartupCreate from '@/components/Owner/Startups/Create.vue';
import OwnerStartupShow from '@/components/Owner/Startups/Show.vue';

import OwnerProgrammesIndex from '@/components/Owner/Programmes/Index.vue';
import OwnerProgrammesShow from '@/components/Owner/Programmes/Show.vue';
import OwnerCandidaturesIndex from '@/components/Owner/Programmes/Candidatures.vue';


export default[
         /* ------------ Start of Owner section -----------*/
         {
            name: "owner_dashboard",
            path: '/owner/dashboard',
            component: OwnerDashboard,
            meta: {
                title: `Owner Dashboard`,
                middleware: "auth"
            }
        },
        {
            name: "owner_recharges_index",
            path: '/owner/recharges',
            component: OwnerRechargesIndex,
            meta: {
                title: `Historique des recharges`,
                middleware: "auth"
            }
        },
        {
            name: "owner_preseed_index",
            path: '/owner/preseeds',
            component: OwnerPreseedIndex,
            meta: {
                title: `Gestion des dossiers preseed`,
                middleware: "auth"
            }
        },
        {
            name: "owner_preseed",
            path: '/owner/preseed/:tkn',
            component: OwnerPreseedShow,
            props: true,
            meta: {
                title: `Preseed`,
                middleware: "auth"
            }
        },
    
        {
            name: "owner_preseed_update_infos",
            path: '/owner/preseed/update/infos/:tkn',
            component: OwnerPreseedUpdateInfos,
            props: true,
            meta: {
                title: `Modification des infos`,
                middleware: "auth"
            }
        },
    
        {
            name: "owner_preseed_create",
            path: '/owner/preseed/create',
            component: OwnerPreseedCreate,
            meta: {
                title: `Preseed`,
                middleware: "auth"
            }
        },
        {
            name: "owner_bfr_index",
            path: '/owner/bfrs',
            component: OwnerBfrIndex,
            meta: {
                title: `Gestion des dossiers bfr`,
                middleware: "auth"
            }
        },
        {
            name: "owner_bfr",
            path: '/owner/bfr/:tkn',
            component: OwnerBfrShow,
            props: true,
            meta: {
                title: `Bfr`,
                middleware: "auth"
            }
        },
        {
            name: "owner_bfr_create",
            path: '/owner/bfr/create',
            component: OwnerBfrCreate,
            meta: {
                title: `Creation du dossier Bfr`,
                middleware: "auth"
            }
        },
        {
            name: "owner_treso_index",
            path: '/owner/tresoreries',
            component: OwnerTresoIndex,
            meta: {
                title: `Gestion des dossiers de treso`,
                middleware: "auth"
            }
        },
        {
            name: "owner_treso",
            path: '/owner/tresorerie/:tkn',
            component: OwnerTresoShow,
            props: true,
            meta: {
                title: `Treso`,
                middleware: "auth"
            }
        },
        {
            name: "owner_treso_create",
            path: '/owner/tresorerie/create',
            component: OwnerTresoCreate,
            meta: {
                title: `Creation du dossier de treso`,
                middleware: "auth"
            }
        },
        {
            name: "owner_seed_index",
            path: '/owner/seeds',
            component: OwnerSeedIndex,
            meta: {
                title: `Gestion des dossiers seed`,
                middleware: "auth"
            }
        },
        {
            name: "owner_seed",
            path: '/owner/seed/:tkn',
            component: OwnerSeedShow,
            props: true,
            meta: {
                title: `Seed`,
                middleware: "auth"
            }
        },
        {
            name: "owner_seed_update_infos",
            path: '/owner/seed/update/infos/:tkn',
            component: OwnerSeedUpdateInfos,
            props: true,
            meta: {
                title: `Modification des infos`,
                middleware: "auth"
            }
        },
        {
            name: "owner_seed_create",
            path: '/owner/seed/create',
            component: OwnerSeedCreate,
            meta: {
                title: `Dossiers seed`,
                middleware: "auth"
            }
        },
    
        //startups
        {
            name: "owner_startup_index",
            path: '/owner/series',
            component: OwnerStartupIndex,
            meta: {
                title: `Gestion des dossiers serie A`,
                middleware: "auth"
            }
        },
        {
            name: "owner_startup",
            path: '/owner/startup/:tkn',
            component: OwnerStartupShow,
            props: true,
            meta: {
                title: `Serie A`,
                middleware: "auth"
            }
        },
        {
            name: "owner_startup_create",
            path: '/owner/serie/create',
            component: OwnerStartupCreate,
            meta: {
                title: `Creation de dossier`,
                middleware: "auth"
            }
        },
    
        {
            name: "owner_plan",
            path: '/owner/plan/:tkn',
            component: OwnerPlan,
            props: true,
            meta: {
                title: `Plan d'action`,
                middleware: "auth"
            }
        },
        {
            name: "owner_pligne",
            path: '/owner/pligne/:tkn',
            component: OwnerPligne,
            props: true,
            meta: {
                title: `Action de maitrise`,
                middleware: "auth"
            }
        },
        {
            name: "owner_programme_index",
            path: '/owner/programmes',
            component: OwnerProgrammesIndex,
            meta: {
                title: `Gestion des programmes`,
                middleware: "auth"
            }
        },
        {
            name: "owner_programme",
            path: '/owner/programme/:tkn',
            component: OwnerProgrammesShow,
            props: true,
            meta: {
                title: `Details sur le programme`,
                middleware: "auth"
            }
        },
        {
            name: "owner_candidatures",
            path: '/owner/candidatures',
            component: OwnerCandidaturesIndex,
            props: true,
            meta: {
                title: `Mes candidatures`,
                middleware: "auth"
            }
        },
    
        /* ----------------------- End of Owner section ---------------*/
]