<template>
<div class="modal fade" id="concurrenceModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">LA CONCURRENCE</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="close4" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form  @submit.prevent="submit" method="post">

                        <div class="form-group">
                            <div v-html="dossier.presentation_concurrence"></div>
                            <hr/>
                            <ckeditor :editor="editor" v-model="presentation_concurrence" :config="editorConfig"></ckeditor>
                            
                        </div>

                       <div class="form-group">
                        <label for="">Fichier des concurrents</label>
                        <input type="file" class="form-control" name="file" id="file" @change="fileChange">
                       </div>
                       <div class="form-group">
                         <button class="btn btn-success" type="submit">ENREGISTRER</button>
                       </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    export default{
        name:'ConcurrenceComponent',
        props:{
            dossier:{
                'type':Object,
                'required':true,
            }
        },
        data(){
            return {
                presentation_concurrence:this.dossier.presentation_concurrence,
                file:null,
                toaster: createToaster({ position:'top-right'}),
                editor: ClassicEditor,
                editorConfig: this.editorConfig,

            }
        },
        methods:{
            fileChange(event){
                //console.log(this.dossier)
                this.file = event.target.files[0];
            },
            submit(){
                document.getElementById('close4').click();
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
                let form = new FormData();
                if(this.file!=null){
                    form.append("fichier",this.file);
                }
                form.append("presentation_concurrence",this.presentation_concurrence);
                form.append("preseed_id",this.dossier.id);
                axios.post(this.path+'owner/preseeds/concurrence',form,{
                    headers:{
                        'Accept':'multipart/form-data'
                    }
                })
                .then((res)=>{
                    console.log(res)
                    this.$emit('ended');
                })
                .catch((err)=>{
                    this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
                    console.log(err)
                })
                .finally(()=>loader.hide())
            }
        }
    }

</script>

<style scoped>


</style>