import CgaDashboard from '@/components/Cga/Dashboard.vue';
import CgaCreateEntreprise from '@/components/Cga/Entreprises/Create.vue'
import CgaCreateSerie from '@/components/Cga/Series/Create.vue';
import CgaShowSerie from '@/components/Cga/Series/Show.vue';
import CgaIndexSerie from '@/components/Cga/Series/Index.vue';
import CgaIndexPme from '@/components/Cga/Pmes/Index.vue';
import CgaCreatePme from '@/components/Cga/Pmes/Create.vue';
import CgaShowPme from '@/components/Cga/Pmes/Show.vue';
import CgaCreateInstitution from '@/components/Cga/Institutions/Create.vue';
import CgaIndexInstitution from '@/components/Cga/Institutions/Index.vue';
import CgaShowInstitution from '@/components/Cga/Institutions/Show.vue';
import CgaCreateDimf from '@/components/Cga/Dimfs/Create.vue';
import CgaIndexDimf from '@/components/Cga/Dimfs/Index.vue';
import CgaShowDimf from '@/components/Cga/Dimfs/Show.vue';
import CgaAchatsIndex from '@/components/Cga/Credits/Achats.vue';
export default[
    
        {
            name: "cga_dashboard",
            path: '/cga/dashboard',
            component: CgaDashboard,
            meta: {
                title: `Tableau de bord`,
                middleware: "auth"
            }
        },
        {
            name: "cga_entreprise_create",
            path: '/cga/entreprises/create',
            component: CgaCreateEntreprise,
            meta: {
                title: `Creation Entreprise`,
                middleware: "auth"
            }
        },
        {
            name: "cga_serie_index",
            path: '/cga/series',
            component: CgaIndexSerie,
            meta: {
                title: `Liste des dossiers`,
                middleware: "auth"
            }
        },
        {
            name: "cga_serie_create",
            path: '/cga/series/create',
            component: CgaCreateSerie,
            meta: {
                title: `Creation de dossier`,
                middleware: "auth"
            }
        },
        {
            name: "cga_serie_show",
            path: '/cga/series/:tkn',
            component: CgaShowSerie,
            props: true,
            meta: {
                title: `Dossier Serie A`,
                middleware: "auth"
            }
        },

        {
            name: "cga_pmes_index",
            path: '/cga/pmes',
            component: CgaIndexPme,
            meta: {
                title: `Liste des dossiers`,
                middleware: "auth"
            }
        },
        {
            name: "cga_pmes_create",
            path: '/cga/pmes/create',
            component: CgaCreatePme,
            meta: {
                title: `Creation de dossier`,
                middleware: "auth"
            }
        },
        {
            name: "cga_pmes_show",
            path: '/cga/pmes/:tkn',
            component: CgaShowPme,
            props: true,
            meta: {
                title: `Dossier Pme`,
                middleware: "auth"
            }
        },

        {
            name: "cga_institutions_index",
            path: '/cga/institutions',
            component: CgaIndexInstitution,
            meta: {
                title: `Liste des IMF`,
                middleware: "auth"
            }
        },
        {
            name: "cga_institutions_create",
            path: '/cga/institutions/create',
            component: CgaCreateInstitution,
            meta: {
                title: `Creation d'une IMF`,
                middleware: "auth"
            }
        },
        {
            name: "cga_institutions_show",
            path: '/cga/institutions/:tkn',
            component: CgaShowInstitution,
            props: true,
            meta: {
                title: `IMF`,
                middleware: "auth"
            }
        },


        {
            name: "cga_dimfs_index",
            path: '/cga/dimfs',
            component: CgaIndexDimf,
            meta: {
                title: `Liste des dossiers IMF`,
                middleware: "auth"
            }
        },
        {
            name: "cga_dimfs_create",
            path: '/cga/dimfs/create',
            component: CgaCreateDimf,
            meta: {
                title: `Creation d'un dossier IMF`,
                middleware: "auth"
            }
        },
        {
            name: "cga_dimfs_show",
            path: '/cga/dimfs/:tkn',
            component: CgaShowDimf,
            props: true,
            meta: {
                title: `Dossier IMF`,
                middleware: "auth"
            }
        },
        {
            name: "cga_achats_index",
            path: '/cga/achats',
            component: CgaAchatsIndex,
            meta: {
                title: `Historique des achats`,
                middleware: "auth"
            }
        },
]