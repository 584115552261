<template>
       <main class="main"> 
        
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>MES DOSSIERS</li>
                <li>NOUVEAU DOSSIER</li>
                </ol>
                <h2 class="title">NOUVEAU DOSSIER</h2>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container d-flex justify-center">
            <div class="card" style="max-width: 600px;">
                <div class="card-body">
                  <div class="">
                    <div class="form-group">
                        <label for="">TYPE DE DOSSIER</label>
                        <select name="" class="form-control" @change="selectType($event)" id="">
                            <option value="">SELECTIONNER UN TYPE DE DOSSIER</option>
                            <option v-for="opt in types" :key="opt.id" :value="opt.id">{{ opt.name }}</option>
                        </select>
                    </div>
                    <div class="form-group">
                      <label for="">DESCRIPTION DU BESOIN</label>
                      <ckeditor class="form-control" :editor="editor" v-model="dossier.besoin" :config="editorConfig"></ckeditor>
                    </div>
                    <div v-if="dossier.type_id==3 || dossier.type_id == 6" class="form-group">
                      <label for="">MONTANT DE LA TRANSACTION</label>
                      <input type="number" v-model="dossier.montant_transaction" class="form-control">
                    </div>
                    <div v-if="dossier.type_id==3" class="form-group">
                      <label for="">HISTORIQUE DE CREDIT</label>
                      <ckeditor :editor="editor" v-model="dossier.historique_credit_text" :config="editorConfig"></ckeditor>
                    </div>
                    <div v-if="dossier.type_id==3" class="form-group">
                      <label for="">Détails sur l'escompte</label>
                      <ckeditor :editor="editor" v-model="dossier.details_escompte" :config="editorConfig"></ckeditor>
                    </div>
                    <div v-if="dossier.type_id==5" class="form-group">
                      <label for="">DESCRIPTION DU BIEN</label>
                      <ckeditor :editor="editor" v-model="dossier.description_bien_text" :config="editorConfig"></ckeditor>
                    </div>
                </div>
                </div>
                <div class="card-footer text-center">
                    <button type="button" @click="submit()" class="btn btn-success"><i class="bx bx-save"></i> ENREGISTRER</button>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
  import axios from 'axios';
  import { createToaster } from "@meforma/vue-toaster";
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  export default {
    components: {
    },
    name: "CreateDossier",
    data() {
      return {
        toaster: createToaster({ position:'top-right'}),
        editor: ClassicEditor,
        editorConfig: this.editorConfig,
        dossier:{},
        types:[],
      };
    },
  
    mounted(){
      this.load();
    },
    methods: {
      selectType(event){
        this.dossier.type_id = event.target.value;
      },
      async submit(){
        let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.post(this.path+'company/dossiers',this.dossier)
                        .then((res)=>{
                            this.dossier = res.data;
                            this.toaster.success("Creation de dossier effectuee avec succes !!!");
                            this.$router.push({name:'company_dossier',params:{tkn:this.dossier.token}})
                        })
                        .catch((err)=>{
                          console.error(err);
                          this.toaster.error("Echec de creation du dossier!");
                        })
                        .finally(()=>loader.hide());
      },
      async load(){
            await axios.get(this.path+'company/dossiers/create')
                        .then((res)=>this.types=res.data.types)
                        .catch((err)=>console.error(err));
        }
    },
  };
  </script>

<style scoped>
  
    .bg{
      background-color: #efefef;
    }
    .steppy-item-counter {
        height: 40px !important;
        width: 40px !important;
        border: none !important;
      }
      
      .steppy-item-counter .number {
        font-size: 1rem !important;
      }
      
      .controls {
        display: flex !important;
        flex-direction: row !important;
        gap: 2rem !important;
      }
      
      .controls .btn {
        align-self:normal !important;
        background-color: #3d7;
      }

      input[type='radio']:checked:after {
        background: #3d9970 !important;
      }
      
      .v-container div.controls .btn--default-2 {
        margin: auto !important;
        text-align: center !important;
        background: #3d9970 !important;
        border-radius: 4px !important;
        border: none !important;
        height: 40px !important;
        width: 40% !important;
        font-size: 1rem !important;
        color: #000407 !important;
        font-weight: bold !important;
      }

      .btn[data-v-bd8cdabb] {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 16px;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        line-height: 1.5;
        transition: all .15s;
        border-radius: 4px;
        width: fit-content;
        font-size: .75rem;
        color: #fff;
        background-color: #3d9970;
        border: 1px solid #f0f0f0;
      }
</style>