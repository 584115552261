<template>
       <main class="main"> 
          <section id="breadcrumbs" class="breadcrumbs">
              <div class="container">
                  <ol>
                  <li><a href="/">ACCUEIL</a></li>
                  <li>DOSSIERS</li>
                  <li>NOUVEAU DOSSIER SERIE A</li>
                  </ol>
                  <h2 class="title">NOUVEAU DOSSIER SERIE A</h2>
              </div>
          </section><!-- End Breadcrumbs -->

        <div class="container d-flex justify-center">
            <div class="card" style="width: 600px;">
                <div class="card-body">
                  <div class="">
                    <div class="form-group">
                      <div class="d-flex justify-content-between">
                          <div>
                              <label for="">ENTREPRISE</label>
                              <autocomplete :debounce-time="500" :get-result-value="getResultValue" :search="search" @submit="selectEntreprise" placeholder="Rechercher une entreprise ..."></autocomplete>
                          </div>
                          <div class="mt-4">
                            <router-link to="/cga/entreprises/create" class="btn btn-success btn-sm mt-2" title="Creer un nouvelle entreprise ..."><i class="bi bi-plus-circle"></i> Ajouter</router-link>
                          </div>
                      </div>
                      <div class="form-group">
                          <div class="form-group">
                            <label for="">BESOIN EN FINANCEMENT</label>
                            <input type="number" class="form-control" v-model="dossier.besoin_financement" >
                          </div>
                      </div>
                    </div>
                </div>
                </div>
                <div class="card-footer text-center">
                    <button type="button" @click="submit()" class="btn btn-success">SUIVANT <i class="bx bx-right-arrow"></i></button>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
  import axios from 'axios';
  import { createToaster } from "@meforma/vue-toaster";
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  import Autocomplete from '@trevoreyre/autocomplete-vue'

  export default {
    components: {
      Autocomplete,
    },
    name: "CreateDossier",
    data() {
      return {
        toaster: createToaster({ position:'top-right'}),
        editor: ClassicEditor,
        editorConfig: this.editorConfig,
        dossier:{
          besoin_financement:0,
        },
        entreprises:[],
        filteredEntreprises:[],
        selectedEntreprise:null,
      };
    },
  
    mounted(){
      this.load();
    },
    methods: {
      selectEnt(event){
        this.dossier.entreprise_id = event.target.value;
      },
      search(event){
        console.log(event);
        if (event.length < 1) { return [] }
        return this.entreprises.filter(entreprise => {
          return entreprise.name.toLowerCase()
            .startsWith(event.toLowerCase())
        })
      },
      getResultValue(result) {
        return result.name
      },
      selectEntreprise(result){
        console.log(result);
        this.selectedEntreprise = result; 
      },
      async submit(){
        if(this.selectedEntreprise!=null){
          let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
                this.dossier.entreprise_id = this.selectedEntreprise.id;
                console.log(this.selectedEntreprise);    
            await axios.post(this.path+'cga/series',this.dossier)
                        .then((res)=>{
                            this.dossier = res.data;
                            this.toaster.success("Creation de dossier effectuee avec succes !!!");
                            this.$router.push({name:'cga_serie_show',params:{tkn:this.dossier.token}})
                        })
                        .catch((err)=>{
                          if(err.response.status==407){
                              this.toaster.error("Votre solde est insuffisant pour créer ce dossier. Veuillez effectuer une recharge.");
                          }else{
                            this.toaster.error("Echec de creation du dossier!");
                          }
                        })
                        .finally(()=>loader.hide());
        }
        else{
          this.toaster.error("Veuillez selection une entreprise avant de poursuivre!");
          console.log(this.selectedEntreprise);
        }
      },
      async load(){
            await axios.get(this.path+'cga/series/create')
                .then((res)=>this.entreprises=res.data.entreprises)
                .catch((err)=>console.error(err));
        }
    },
  };
  </script>

<style scoped>  
    .form-group{
      margin-top:1rem;
    }
    .bg{
      background-color: #efefef;
    }

.autocomplete-input, input.control-input{
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    padding-left: 45px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    box-shadow: inset 0 0 0 transparent;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

</style>










