<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Porteurs de projets</li>
                </ol>
                <h2>REPERTOIRE DES PORTEURS DE PROJETS</h2>
                <div style="">
                    <button class="btn btn-success btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#ownerModal">Creer un compte</button>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="mb-2">
                <input @input="search" type="text" style="max-width: 400px;" class="form-control p-1 bg-success-subtle" placeholder="Rechercher ...">
            </div>
            <table class="table table-sm table-bordered">
                <thead>
                    <tr>
                        <th>NOM</th>
                        <th>TELEPHONE</th>
                        <th>EMAIL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(consultant,index) in filtered" :key="index">
                        <td>
                            <router-link :to="{name:'admin_porteur', params: {tkn: consultant.token}}"> {{ consultant.first_name }} {{ consultant.last_name }}</router-link>
                           
                        </td>
                        <td>{{ consultant.phone }}</td>
                        <td>{{ consultant.email }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </main>
    <!-- Modal -->
<div class="modal fade" id="ownerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <form @submit.prevent="saveOwner()" method="post">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">NOUVEAU PORTEUR DE PROJET</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" id="close" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-group">
            
            <input type="text" class="form-control" placeholder="Nom" v-model="owner.last_name">
        </div>
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Prenom" v-model="owner.first_name">
        </div>
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Adresse" v-model="owner.address">
        </div>
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Telephone" v-model="owner.phone">
        </div>
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Email" v-model="owner.email">
        </div>
        <div class="form-group">
            <input type="password" class="form-control" placeholder="Mot de passe" v-model="owner.password">
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-success">Valider</button>
      </div>
    </div>
    </form>
  </div>
</div>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"IndexPorteur",
    data(){
        return {
            user:this.$store.state.auth.user,
            owner:{},
            toaster: createToaster({ position:'top-right'}),
            porteurs:[],
            filtered:[],
        }
    },
    methods:{
        async load(){
            await axios.get(this.path+'revendeur/porteurs')
                        .then((res)=>{
                            this.porteurs = res.data
                            this.filtered = res.data
                        })
                        .catch((err)=>console.error(err));
        },
        async reload(){
            await axios.get(this.path+'revendeur/porteurs')
                        .then((res)=>{
                        // this.setData(res.data)
                        this.porteurs=res.data
                        this.filtered = res.data
                           console.log(res.data);
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        },
        search(event){
            {
                console.log(this.porteurs)
                setTimeout(() => {
                    if (!event.target.value.trim().length) {
                        this.filtered = [...this.porteurs];
                    } else {
                        //console.log(this.porteurs)
                        this.filtered = this.porteurs.filter((item) =>item.first_name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.last_name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.phone.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.email.toLowerCase().startsWith(event.target.value.toLowerCase())
                        );
                    }
                }, 250);
            }
        },
        async saveOwner(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.post(this.path+'revendeur/porteurs',this.owner)
                        .then(()=>{
                            this.reload();
                        })
                        .catch(()=>{
                            this.toaster.error("Cette operation a echoue !!!");
                        })
                        .finally(()=>{
                            loader.hide();
                            document.getElementById('close').click();
                        })
        },

    },
    mounted(){
        this.load().then(()=>console.log(this.porteurs))
    }

}
</script>

<style scoped>
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
</style>