<template>
<div class="modal fade" id="risqueModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Évaluation des Risques</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div>
                    <ol>
                        <li>
                            <div class="form-group">
                                <label for="">Analyse SWOT (Forces, Faiblesses, Opportunités, Menaces)</label>
                                <input type="file" class="form-control" name="file" id="file" @change="swotChange">
                            </div>
                        </li>
                        <li>
                            <div class="form-group">
                                <label for="">Gestion des Risques</label>
                                <ckeditor :editor="editor" v-model="folder.gestion_risques_text" :config="editorConfig"></ckeditor>
                                <input type="file" class="form-control" name="file" id="file" @change="risqueChange">
                            </div>
                        </li>
                        <li>
                            <div class="form-group">
                                <label for="">Plans de Contingence</label>
                                <ckeditor :editor="editor" v-model="folder.plan_contingence_text" :config="editorConfig"></ckeditor>
                                <input type="file" class="form-control" name="file" id="file" @change="planChange">
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
            <div class="modal-footer">
                <div class="form-group">
                    <button class="btn btn-success" @click="submit" data-bs-dismiss="modal" type="submit"><i class="bx bi-save"></i> ENREGISTRER</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    export default{
        name:'StrategieComponent',
        props:{
            dossier:{
                'type':Object,
                'required':true,
            }
        },
        data(){
            return {
                gestion_risques:null,
                plan_contingence:null,
                swot:null,
                folder:{},
                editor: ClassicEditor,
                editorConfig: this.editorConfig,

            }
        },
        methods:{
            risqueChange(event){
                this.gestion_risques = event.target.files[0];
            },
            planChange(event){
                this.plan_contingence = event.target.files[0];
            },
            swotChange(event){
                this.swot = event.target.files[0];
            },
            submit(){
                    let loader = this.$loading.show({
                        container: this.fullPage ? null : this.$refs.formContainer,
                        canCancel: false,
                    });
                    let form = new FormData();
                    if(this.swot!=null){
                        form.append("swot",this.swot);
                    }
                    if(this.gestion_risques!=null){
                        form.append("gestion_risques",this.gestion_risques);
                    }
                    if(this.plan_contingence!=null){
                        form.append("plan_contingence",this.plan_contingence);
                    }
                    delete this.folder.institution;
                    form.append("dossier",JSON.stringify(this.folder));
                    axios.post(this.path+'cga/dimf/save',form,{
                        headers:{
                            'Accept':'multipart/form-data'
                        }
                    })
                    .then((res)=>{
                        console.log(res)
                        this.$emit('success');
                    })
                    .catch((err)=>{
                        this.$emit('error')
                        console.log(err)
                    })
                    .finally(()=>loader.hide())
                
            }
        },
        computed:{
        },
        watch:{
            dossier(newValue,oldValue){
                console.log(oldValue)
                console.log(newValue)
                this.folder = this.dossier;
            }
        },
        mounted(){
            this.folder= this.dossier;
        }
    }

</script>

<style scoped>


</style>