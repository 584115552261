<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Dossiers PRESEED</li>
                </ol>
                <h2>{{ dossier.name }}</h2>
                <div class="">
                    <div class="dropdown">
                        <a class="dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Actions</a>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#" @click="validateStep">Valider cette etape</a></li>
                            <li><router-link class="dropdown-item" :to="{name:'admin_preseed_plan', params: {tkn: dossier.token}}">Afficher le plan d'actions</router-link></li>
                            <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#bmModal">Afficher le modele economique</a></li>
                            <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#consultantModal">Affecter a un consultant</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="row">
                    <div id="side1" class="col-md-12 col-sm-12" >
                        <div class="card">
                            <div class="card-body">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-12">
                                            <div style="height: auto; ">
                                                <img style="width: 100%;" :src="dossier.photo" alt="">
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-12 infos">
                                            <span class="badge badge-success">ETAPE {{ dossier.step }}</span>
                                            <p>PROMOTEUR : <span class="text-bold">{{ dossier.owner?dossier.owner.name:'-' }}</span></p>
                                            <p>CONSULTANT : <span class="text-bold">{{ dossier.consultant?dossier.consultant.name:'-' }}</span></p>
                                            <p>DATE DE CREATION : <span class="value"> {{ dossier.created }}</span></p>
                                            <p><span>VILLE : </span> {{ dossier.ville?dossier.ville.name:'-' }}</p>
                                            <p>SECTEUR :  <span class="text-bold">{{ dossier.secteur?dossier.secteur.name:'-' }}</span></p>
                                            <input type="hidden" id="pl_id" value="{{ dossier.plan_id }}"/>

                                            
                                        </div>
                                        <div class="col-md-4 col-sm-12">
                                            <div class="card bg-light">
                                                <div class="card-body">
                                                    <p>{{ dossier.description }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div id="side2" style="overflow: scroll" class="col-md-12 col-sm-12">
                        <div class="card p-2">
                            <div style="overflow: scroll" class="card-header p-0 border-bottom-0">
                                <ul class="nav nav-pills" id="custom-tabs-four-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="custom-tabs-four-home-tab" data-bs-toggle="tab" data-bs-target="#custom-tabs-four-home" type="button" role="tab" aria-selected="true" data-toggle="pill" href="" aria-controls="custom-tabs-four-home">ANALYSE DES RISQUES</a>
                                    </li>
                                    <li class="nav-item">
                                    <a class="nav-link" id="custom-tabs-four-profile-tab"  data-bs-toggle="tab" data-bs-target="#custom-tabs-four-profile" type="button" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">PROBLEMATIQUE A RESOUDRE</a>
                                    </li>
                                    <li class="nav-item">
                                    <a class="nav-link" id="custom-tabs-four-messages-tab"  data-bs-toggle="tab" data-bs-target="#solution-proposee" type="button" role="tab" aria-controls="solution-proposee" aria-selected="false">SOLUTION PROPOSEE</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="custom-tabs-four-settings-tab" data-bs-toggle="tab" data-bs-target="#solution-proposee" type="button" role="tab" aria-controls="taille-marche" aria-selected="false">TAILLE DU MARCHE</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="custom-tabs-four-settings-tab" data-bs-toggle="tab" data-bs-target="#taille-marche" type="button" role="tab" aria-controls="concurrence" aria-selected="false">CONCURRENCE</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="custom-tabs-four-profile-tab" data-bs-toggle="tab" data-bs-target="#va" type="button" role="tab" aria-controls="va" aria-selected="false">VALEUR AJOUTEE</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="custom-tabs-four-messages-tab" data-bs-toggle="tab" data-bs-target="#equipe" type="button" role="tab" aria-controls="equipe" aria-selected="false">L’EQUIPE</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="custom-tabs-four-settings-tab" data-bs-toggle="tab" data-bs-target="#etapes" type="button" role="tab" aria-controls="etapes" aria-selected="false">ETAPES IMPORTANTES</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="custom-tabs-four-settings-tab" data-bs-toggle="tab" data-bs-target="#objectifs" type="button" role="tab" aria-controls="objectifs" aria-selected="false">LES OBJECTIFS A ATTEINDRE</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="custom-tabs-four-messages-tab" data-bs-toggle="tab" data-bs-target="#marketing" type="button" role="tab" aria-controls="marketing" aria-selected="false"> PLAN MARKETING</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="custom-tabs-four-settings-tab" data-bs-toggle="tab" data-bs-target="#planfinancier" type="button" role="tab" aria-controls="planfinancier" aria-selected="false">PLAN FINANCIER PREVISIONNEL</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="custom-tabs-four-settings-tab" data-bs-toggle="tab" data-bs-target="#besoins-financiers" type="button" role="tab" aria-controls="besoins-financiers" aria-selected="false">LES BESOINS FINANCIERS</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="card-body">
                            <div class="tab-content" id="custom-tabs-four-tabContent">
                                <div class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                                   <Diagnostic />
                                </div>
                                <div class="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel" aria-labelledby="custom-tabs-four-profile-tab">
                                    <fieldset>
                                        <legend>PRESENTATION DE LA PROBLEMATIQUE</legend>
                                        <p>{{dossier.presentation_problematique}}</p>
                                    </fieldset>
                                    <div class="table-responsive">
                                        <table class="table table-sm table-striped table-bordered">
                                            <thead>
                                                <th>#</th>
                                                <th>Qui sont les clients cibles ?</th>
                                                <th>Quelle est la problématique à laquelle ils sont confrontés?</th>
                                                <th>Où achètent-ils des produits-services pour résoudre cette problématique ?</th>
                                                <th>A quelle fréquence achètent-ils ces produits-services ?</th>
                                                <th>En moyenne, à combien achètent ils ces produits-services ?</th>
                                                <th>Pourquoi achètent-ils ces produits-services en particulier et pas un autre ?</th>
                                            </thead>
                                            <tbody>
                                                    <tr v-for="(segment, index) in dossier.segments" :key="index">
                                                        <td>Segment {{ index+1 }}</td>
                                                        <td>{{ segment.clients }}</td>
                                                        <td>{{ segment.problematique }}</td>
                                                        <td>{{ segment.approvisionnement }}</td>
                                                        <td>{{ segment.frequence }}</td>
                                                        <td>{{ segment.moyenne }}</td>
                                                        <td>{{ segment.motif }}</td>
                                                    </tr>
                                            </tbody>
                                       </table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="solution-proposee" role="tabpanel" aria-labelledby="custom-tabs-four-messages-tab">
                                    <p>{{dossier.presentation_solution}}</p>
                                </div>
                                <div class="tab-pane fade" id="taille-marche" role="tabpanel" aria-labelledby="custom-tabs-four-settings-tab">
                                    {{dossier.presentation_taille_marche}}
                                </div>
                                <div class="tab-pane fade" id="concurrence" role="tabpanel" aria-labelledby="custom-tabs-four-settings-tab">
                                    {{dossier.presentation_concurrence}}
                                </div>
                                <div class="tab-pane fade" id="va" role="tabpanel" aria-labelledby="custom-tabs-four-settings-tab">
                                    {{dossier.presentation_valeur_ajoutee}}
                                </div>
                                <div class="tab-pane fade" id="equipe" role="tabpanel" aria-labelledby="custom-tabs-four-settings-tab">
                                   {{ dossier.presentation_equipe}}
                                    <div class="pt-2">
                                        <ul class="list-group">
                                                <li v-for="(membre, index) in dossier.membres" :key="index" class="list-group-item">
                                                    <div class="row">
                                                        <div class="col-md-2 col-sm-12">
                                                            <img :src="membre.photo" alt="">
                                                        </div>
                                                        <div class="col-md-6 col-sm-12">
                                                           <h5>{{ membre.name }}</h5>
                                                           <p>{{ membre.fonction }}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="etapes" role="tabpanel" aria-labelledby="custom-tabs-four-settings-tab">
                                    {{ dossier.presentation_etapes_realisation }}
                                </div>
                                <div class="tab-pane fade" id="objectifs" role="tabpanel" aria-labelledby="custom-tabs-four-settings-tab">
                                    {{ dossier.presentation_objectifs }}
                                </div>
                                <div class="tab-pane fade" id="marketing" role="tabpanel" aria-labelledby="custom-tabs-four-settings-tab">
                                     {{ dossier.presentation_marketing  }}
                                    <table class="table table-sm table-striped table-bordered mt-2">
                                        <thead>
                                            <th>#</th>
                                            <th>GAMME DE PRODUIT</th>
                                            <th>Prix proposé</th>
                                            <th>Stratégie de distribution</th>
                                            <th>Stratégie de communication</th>
                                        </thead>
                                        <tbody>
                                                <tr v-for="(segment, index) in dossier.marketings" :key="index">
                                                    <td>Segment {{ index }}</td>
                                                    <td>{{ segment.gamme }}</td>
                                                    <td>{{ segment.prix }}</td>
                                                    <td>{{ segment.distribution }}</td>
                                                    <td>{{ segment.communication }}</td>
                                                </tr>
                                        </tbody>
                                   </table>
                                </div>
                                <div class="tab-pane fade" id="planfinancier" role="tabpanel" aria-labelledby="custom-tabs-four-settings-tab">

                                </div>
                                <div class="tab-pane fade" id="besoins-financiers" role="tabpanel" aria-labelledby="custom-tabs-four-settings-tab">
                                    {{  dossier.presentation_besoins_financiers }}

                                </div>
                            </div>
                            </div>
                            <!-- /.card -->
                        </div>
                    </div>
                </div>
        </div>


<!-- Modal -->
<div class="modal fade" id="consultantModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <form @submit.prevent="saveConsultant()" method="post">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">AFFECTER A UN CONSULTANT</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" id="close" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-group">
            <select class="form-control" name="" @change="setConsultant($event)" required id="">
                <option value="">SELECTIONNER ...</option>
                <option v-for="(opt,index) in consultants" :key="index" :value="opt.id">{{ opt.first_name }} {{ opt.last_name }}</option>
            </select>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-success">Valider</button>
      </div>
    </div>
    </form>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="bmModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">LE MODELE ECONOMIQUE</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="bmclose" aria-label="Close"></button>
            </div>
            <div class="modal-body">
               <modele-economique :modele="dossier.modele"></modele-economique> 
            </div>
        </div>
    </div>
</div>
</main>
</template>
<script>
import axios from "axios";
import Diagnostic from "../../Includes/Diagnostic.vue";
import { createToaster } from "@meforma/vue-toaster";
import ModeleEconomique from "../../Includes/ModeleEconomique.vue";
export default {
    name:"IndexPreseed",
    props: ['tkn'],
    components:{
        Diagnostic,
        ModeleEconomique,
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            dossier:{},
            consultants:[],
            toaster: createToaster({ position:'top-right'}),
            consultant_id:0,
            componentKey: 0,
        }
    },
    methods:{
        setConsultant(event){
            console.log(event)
            this.consultant_id = event.target.value;
        },
        async saveConsultant(){
            let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    //onCancel: this.onCancel,
                });
            await axios.post(this.path+'revendeur/preseed/consultant',{token:this.dossier.token,consultant_id:this.consultant_id})
                        .then(()=>{
                            this.reload();
                        })
                        .catch(()=>{
                            this.toaster.error("Cette operation a echoue !!!");
                        })
                        .finally(()=>{
                            loader.hide();
                            //var elt = document.getElementById('consultantModal');
                            //var myModal = Modal.;
                            //myModal.hide();
                            document.getElementById('close').click();

                            //document.getElementsByTagName('body')[0].classList.remove("modal-open");
                            //document.getElementById('consultantModal').classList.remove("show");
                        })
        },
        forceRender() {
            this.componentKey += 1;
        },
        
        async validateStep(){
            await axios.get(this.path+'revendeur/preseeds/validate/step/'+this.tkn)
                        .then(()=>this.reload())
                        .catch((err)=>console.error(err));
        },
        setData(data){
            this.consultants = data.consultants;
            this.dossier = data.dossier;
        },
        async load(){
            let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    //onCancel: this.onCancel,
                });
            await axios.get(this.path+'revendeur/preseeds/'+this.tkn)
                        .then((res)=>this.setData(res.data))
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        async reload(){
            await axios.get(this.path+'revendeur/preseeds/'+this.tkn)
                        .then((res)=>{
                            this.setData(res.data)
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.dossier))
        
    }

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: var(--bs-nav-pills-link-active-color);
        background-color: #3d9970;
    }
    .nav-pills .nav-link:hover {
        color: #3d9970;
        
    }
    .infos p{
        margin-bottom: 2px;
        font-size: smaller;
    }
</style>