<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Programmes</li>
                </ol>
                <h2>MES CANDIDATURES</h2>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="row">
                <div v-for="(candidature,index) in candidature" :key="index" class="col-md-4 h-100">
                    <a @click="show(candidature)" data-bs-toggle="modal" data-bs-target="#candidatureModal">
                        <div class="card">
                            <div class="card-header">
                                <h5>{{ candidature.dossier.content.name }}</h5>
                                <h6>Programme : <strong>{{ candidature.programme.name }}</strong></h6>
                            </div>
                            <div class="card-body">
                                <img :src="candidature.dossier.content.photo" style="height: 160px; width: 100%;" alt="">
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"IndexCandidatures",
    props: ['tkn'],
    data(){
        return {
            user:this.$store.state.auth.user,
            candidatures:[],
            candidature:{},
            toaster: createToaster({ position:'top-right'}),
        }
    },
    methods:{
        show(event){
            console.log(event)
            //this.consultant_id = event.target.value;
        },
        async reload(){
            await axios.get(this.path+'owner/candidatures')
                        .then((res)=>{
                            this.candidatures = res.data;
                           console.log(res.data);
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        },
        async load(){
            await axios.get(this.path+'owner/candidatures')
                        .then((res)=>this.candidatures=res.data)
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.candidatures))
        
    }

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    a{
        color: #3d9970;
        text-decoration: none;
    }
</style>