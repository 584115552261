<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>MES DOSSIERS PRESEED</li>
                </ol>
                <h2>{{ dossier.name }}</h2>
                <div class="">
                    <div class="dropdown">
                        <a class="dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Actions</a>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#bmModal">1- EDITER LE MODELE ECONOMIQUE</a></li>
                            <li v-if="dossier.validated_step>0"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#problematiqueModal">2- PROBLEMATIQUE</a></li>
                            <li v-if="dossier.validated_step>1"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#solutionModal">3- SOLUTION PROPOSEE</a></li>
                            <li v-if="dossier.validated_step>2"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#tailleModal">4- TAILLE DU MARCHE</a></li>
                            <li v-if="dossier.validated_step>3"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#concurrenceModal">5- PRESENTATION DE LA CONCURRENCE</a></li>
                            <li v-if="dossier.validated_step>4"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#vaModal">6- PRESENTATION DE LA VALEUR AJOUTEE</a></li>
                            <li v-if="dossier.validated_step>5"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#equipeModal">7- PRESENTATION DE L'EQUIPE</a></li>

                            <li v-if="dossier.validated_step>6"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#etapeModal">8- LES ETAPES DE LA REALISATION</a></li>
                            <li v-if="dossier.validated_step>7"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#objectifsModal">9- OBJECTIFS</a></li>
                            <li v-if="dossier.validated_step>8"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#marketingModal">10- PLAN MARKETING</a></li>
                            <li v-if="dossier.validated_step>9"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#planFinancierModal">11- PLAN FINANCIER PREVISIONNEL</a></li>
                            <li v-if="dossier.validated_step>10"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#besoinsModal">12- BESOINS FINANCIERS</a></li>
                            <li v-if="dossier.validated_step>11"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#teaserModal">13- AFFICHER LE TEASER</a></li>
                            <li v-if="dossier.validated_step>11"><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#planModal">14- AFFICHER LE PLAN D'ACTIONS</a></li>
                            <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#audioModal">FICHIERS AUDIO</a></li>
                            <li><router-link class="dropdown-item" :to="{name:'consultant_plan', params: {tkn: dossier.token}}">PLAN D'ACTION</router-link></li>
                            <li><a v-if="dossier.status.code==0" @click="ask()" class="dropdown-item" href="#" type="button">Demander une evaluation</a></li>

                        </ul>
                    </div>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="row">
                    <div id="side1" class="col-md-12 col-sm-12" >
                        <div class="card" style="background:#f7f7f7;">
                            <div class="card-body">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-12">
                                            <div style="height: auto; ">
                                                <img style="max-width: 100%; width: fit-content; max-height: 220px; height: fit-content;"  :src="dossier.photo" alt="">
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-12 infos">
                                            <span :class="`badge bg-${dossier.status.color}`"> {{ dossier.status.name }}</span>
                                            <span class="badge badge-success">ETAPE {{ dossier.step }}</span>
                                            <p>PROMOTEUR : <span class="text-bold">{{ dossier.owner?dossier.owner.name:'-' }}</span></p>
                                            <p>CONSULTANT : <span class="text-bold">{{ dossier.consultant?dossier.consultant.name:'-' }}</span></p>
                                            <p>DATE DE CREATION : <span class="value"> {{ dossier.created }}</span></p>
                                            <p><span>VILLE : </span> {{ dossier.ville?dossier.ville.name:'-' }}</p>
                                            <p>SECTEUR :  <span class="text-bold">{{ dossier.secteur?dossier.secteur.name:'-' }}</span></p>
                                            <input type="hidden" id="pl_id" value="{{ dossier.plan_id }}"/>

                                            
                                        </div>
                                        <div class="col-md-4 col-sm-12">
                                            <div class="card bg-light">
                                                <div class="card-body">
                                                    <p>{{ dossier.description }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div id="side2" style="overflow: scroll" class="col-md-12 col-sm-12">
                        <Preseed :dossier="dossier" :choices="choices" />
                    </div>
                </div>
        </div>
    </main>




<!-- Modal -->
<div class="modal fade" id="bmModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">LE MODELE ECONOMIQUE</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="bmclose" aria-label="Close"></button>
            </div>
            <div class="modal-body">
               <modele-economique :modele="dossier.modele"></modele-economique> 
            </div>
        </div>
    </div>
</div>
<Problematique :dossier="dossier" @ended="reload" />
<Solution :dossier="dossier" @ended="reload" />
<Taille :dossier="dossier" @ended="reload" />
<Concurrence :dossier="dossier" @ended="reload" />
<Va :dossier="dossier" @ended="reload" />
<Equipe :dossier="dossier" @ended="reload" />
<Membre :dossier="dossier" @ended="reload" />
<Etapes :dossier="dossier" @ended="reload" />
<Objectifs :dossier="dossier" @ended="reload" />
<Marketing :dossier="dossier" @ended="reload" />
<Besoins :dossier="dossier" @ended="reload" />
<PlanFinancier :dossier="dossier" @ended="reload" />
</template>
<script>
import axios from "axios";
import Problematique from "./Problematique.vue";
import Solution from "./Solution.vue";
import Taille from "./Taille.vue";
import Concurrence from "./Concurrence.vue";
import Va from "./Va.vue";
import Equipe from "./Equipe.vue";
import Membre from "./Membre.vue";
import Etapes from "./Etapes.vue";
import Objectifs from "./Objectifs.vue";
import Marketing from "./Marketing.vue";
import Besoins from "./BesoinsFinanciers.vue";
import PlanFinancier from "./PlanFinancier.vue";

import { createToaster } from "@meforma/vue-toaster";
import ModeleEconomique from "../../Includes/ModeleEconomique.vue";
import Preseed from "../../Includes/Preseed.vue";
export default {
    name:"IndexPreseed",
    props: ['tkn'],
    components:{
        Preseed,
        ModeleEconomique,
        Problematique,
        Solution,
        Taille,
        Concurrence,
        Va,
        Equipe,
        Membre,
        Etapes,
        Objectifs,
        Marketing,
        Besoins,
        PlanFinancier,
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            dossier:{
                status:{}
            },
            choices:[],
            toaster: createToaster({ position:'top-right'}),
            componentKey: 0,
        }
    },
    methods:{
        async ask(){
            let loader = this.$loading.show();
            await axios.get(this.path+'consultant/preseeds/evaluation/'+this.tkn)
                        .then(()=>this.reload())
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        endpb(){
            this.reload();
            document.getElementById('closepb').click();
        },
        
        setData(data){
            this.dossier = data.dossier;
            this.choices = data.choices;
        },
        async load(){
            let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    //onCancel: this.onCancel,
                });
            await axios.get(this.path+'consultant/preseeds/'+this.tkn)
                        .then((res)=>this.setData(res.data))
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        async reload(){
            await axios.get(this.path+'consultant/preseeds/'+this.tkn)
                        .then((res)=>{
                            this.setData(res.data)
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.dossier))
        
    }

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: var(--bs-nav-pills-link-active-color);
        background-color: #3d9970;
    }
    a {
        color: #3d9970;
        
    }
    .infos p{
        margin-bottom: 2px;
        font-size: smaller;
    }
    .table thead th{
        font-size: smaller;
    }
</style>