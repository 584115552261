<template>
<div class="modal fade" id="vaModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">VALEUR AJOUTEE</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="close5" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form class="form" @submit.prevent="submit" >
                        <div class="form-group">
                            <div v-html="dossier.presentation_valeur_ajoutee"></div>
                            <hr/>
                            <ckeditor :editor="editor" v-model="presentation_valeur_ajoutee" :config="editorConfig"></ckeditor>
                            
                        </div>
                       <button class="btn btn-success" type="submit">ENREGISTRER</button>
                 </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default{
    name:'VaComponent',
    props:{
            dossier:{
                'type':Object,
                'required':true,
            }
        },
        data(){
            return {
                presentation_valeur_ajoutee:this.dossier.presentation_valeur_ajoutee,
                toaster: createToaster({ position:'top-right'}),
                editor: ClassicEditor,
                editorConfig: this.editorConfig

                }
        },
        methods:{
            submit(){
                if(this.solvable){
                    document.getElementById('close5').click();
                    let loader = this.$loading.show({
                        container: this.fullPage ? null : this.$refs.formContainer,
                        canCancel: false,
                    });
                    let form = new FormData();
                    form.append("presentation_valeur_ajoutee",this.presentation_valeur_ajoutee);
                    form.append("preseed_id",this.dossier.id);
                    axios.post(this.path+'owner/preseeds/valeur_ajoutee',form,{
                        headers:{
                            'Accept':'multipart/form-data'
                        }
                    })
                    .then((res)=>{
                        console.log(res)
                        this.$emit('ended');
                    })
                    .catch((err)=>{
                        this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
                        console.log(err)
                    })
                    .finally(()=>loader.hide())
                }else{
                    this.toaster.error("Votre solde est insuffisant pour achever cette etape ! Veuillez vous rapprocher de votre gestionaire pour crediter votre compte!");
                }
            }
        },
        computed:{
            solvable: function(){
                return this.$store.state.auth.entreprise.step_cost_preseed<this.$store.state.auth.user.solde;
            }
        },
        mounted(){
            if(!this.solvable){
               // this.toaster.error("Votre solde est insuffisant pour achever cette etape ! Veuillez vous rapprocher de votre gestionaire pour crediter votre compte!");
            }
        }
    }

</script>

<style scoped>


</style>