<template>
<div class="modal fade" id="plan_rrModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Plan de remboursement et de redressement</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="close4" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form  @submit.prevent="submit" method="post">
                       <div class="form-group">
                            <label for="">Fichier PDF</label>
                            <input type="file" class="form-control" name="file" id="file" @change="filePdfChange">
                       </div>
                       <div class="form-group mt-2">
                            <label for="">Fichier EXCEL</label>
                            <input type="file" class="form-control" name="file" id="file" @change="fileExcelChange">
                       </div>
                       <div class="form-group">
                         <button class="btn btn-success" data-bs-dismiss="modal" type="submit"><i class="bx bi-save"></i> ENREGISTRER</button>
                       </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    export default{
        name:'PlanRedressementComponent',
        props:{
            dossier:{
                'type':Object,
                'required':true,
            }
        },
        data(){
            return {
                pdf:null,
                excel:null,
                editor: ClassicEditor,
                editorConfig: this.editorConfig,

            }
        },
        methods:{
            filePdfChange(event){
                this.pdf = event.target.files[0];
            },
            fileExcelChange(event){
                this.excel = event.target.files[0];
            },
            submit(){
                   // document.getElementById('closecol').click();
                    let loader = this.$loading.show({
                        container: this.fullPage ? null : this.$refs.formContainer,
                        canCancel: false,
                    });
                    let form = new FormData();
                    if(this.pdf!=null){
                        form.append("pdf",this.pdf);
                    }
                    if(this.excel!=null){
                        form.append("excel",this.excel);
                    }
                    form.append("id",this.dossier.id);
                    axios.post(this.path+'cga/pme/plan_reboursement_redressement',form,{
                        headers:{
                            'Accept':'multipart/form-data'
                        }
                    })
                    .then((res)=>{
                        console.log(res)
                        this.$emit('success');
                    })
                    .catch((err)=>{
                        this.$emit('error')
                        //this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
                        console.log(err)
                    })
                    .finally(()=>loader.hide())
                
            }
        },
        computed:{
        },
        watch:{
            dossier(newValue,oldValue){
                console.log(oldValue)
                console.log(newValue)
            }
        },
        mounted(){
        }
    }

</script>

<style scoped>


</style>