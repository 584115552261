<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>LISTE DES DOSSIERS</li>
                </ol>
                <div class="d-flex justify-space-between">
                    <h2>LISTE DES DOSSIERS</h2>
                    <div class="mr-3"><router-link class="btn bg-green-darken-4" to="/company/dossiers/create"><i class="bi bi-plus-square-fill" title="Creer un nouveau dossier"></i> NOUVEAU DOSSIER</router-link></div>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="d-flex justify-center gap-3">
                <div v-for="(dossier,index) in dossiers" :key="index" :class="dossier.bg" class="card">
                    <div class="card-body">
                        <router-link :to="{name:'company_dossier',params:{tkn:dossier.token}}">
                            <div>
                                <h5>{{ dossier.type.name }}</h5>
                                <small>{{ dossier.created }}</small>
                                <div v-if="dossier.type.id==3">{{ dossier.montant_transaction }}</div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import axios from "axios";
export default {
    name:"IndexPreseed",
    data(){
        return {
            user:this.$store.state.auth.user,
            dossiers:[]
        }
    },
    methods:{
        async load(){
            await axios.get(this.path+'company/dossiers')
                        .then((res)=>this.dossiers=res.data)
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>{
            this.dossiers.map((d,i)=>{
                console.log(i)
                console.log(d);
                let n = d.type.id;
                d.bg = 'bg-gradient-teal';
                if(n==1){
                    d.bg = 'bg-danger';
                }
                if(n==2){
                    d.bg = 'bg-yellow-darken-4';
                }
                if(n==3){
                    d.bg = 'bg-blue-darken-4';
                }
                if(n==4){
                    d.bg ='bg-dark';
                }
                if(n==5){
                    d.bg = 'bg-gradient-cyan';
                }
                if(n==6){
                    d.bg = 'bg-green-darken-4';
                }
                console.log(d);
                return d;
            })
        })
        
    },
    computed:{
    }

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    a{
        color: #3d9970;
        text-decoration: none;
    }

    div.card-body a {
        color: inherit;
    }

    a.btn{
        color:#fff;
    }
</style>