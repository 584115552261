import AdvisorDashboard from '@/components/Advisor/Dashboard.vue'
// Composants de l'advisor
import AdvsorIncubateurIndex from "@/components/Advisor/Incubateurs/Index.vue"
import AdvisorIncubateurShow from '@/components/Advisor/Incubateurs/Show.vue'

export default[
     
      {
        name: "advisor_dashboard",
        path: '/advisor/dashboard',
        component: AdvisorDashboard,
        meta: {
            title: `Advisor Dashboard`,
            middleware: "auth"
        }
    },
    {
        name: "advisor_incubateurs_index",
        path: '/advisor/incubateurs',
        component: AdvsorIncubateurIndex,
        meta: {
            title: `Gestion des incubateurs`,
            middleware: "auth"
        }
    },
    {
        name: "advisor_incubateur",
        path: '/advisor/incubateur/:tkn',
        component: AdvisorIncubateurShow,
        props: true,
        meta: {
            title: `Incubateur`,
            middleware: "auth"
        }
    },

     /* ----------------------- End of Advisor section ---------------*/


]