<template>
<div class="modal fade" id="docModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">NOUVEAU DOCUMENT</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="closedoc" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form enctype="multipart/form-data" class="form"  @submit.prevent="submit" method="post">
                    <div class="form-group">
                        <label for="">NOM DU DOCUMENT</label>
                        <input type="text" v-model="name" class="form-control" name="file" id="file">
                    </div>
                    <div class="form-group">
                        <label for="">Fichier au format PDF</label>
                        <input type="file" class="form-control" name="file" id="file" @change="fileChange">
                    </div>
                    <button class="btn btn-success" type="submit">ENREGISTRER</button>
                </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default{
    name:'DocComponent',
    components:{
    },
    props:{
        dossier:{
                'type':Object,
                'required':true,
            }
    },
    data(){
        return {
            file:null,
            name:'',
            toaster: createToaster({ position:'top-right'}),
            editor: ClassicEditor,
            editorConfig: this.editorConfig,


            }
        },
        methods:{
            fileChange(event){
                this.file = event.target.files[0];
            },
            submit(){
                document.getElementById('closedoc').click();
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
                let form = new FormData();
                if(this.file!=null){
                    form.append("file",this.file);
                }
                form.append("token",this.dossier.token);
                form.append("type",'SEED');
                form.append("name",this.name);
                axios.post(this.path+'owner/dossier/doc',form,{
                    headers:{
                        'Accept':'multipart/form-data'
                    }
                })
                .then((res)=>{
                    console.log(res)
                    this.$emit('ended');
                })
                .catch((err)=>{
                    if(err.response.status == 411){
                        this.toaster.error("Echec d'enregistrement. L'extension d'un des fichiers n'est pas au format PDF !");
                    }else{
                        this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
                    }
                    console.log(err)
                })
                .finally(()=>loader.hide())
            }
        },
        mounted(){
           
        }
    }

</script>