<template>
    <div class="modal fade" id="newsLetterModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Edtion de la News Letter</h5>
                <button type="button" class="btn-close btn-xs btn-danger" data-bs-dismiss="modal" id="nlclose" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form @submit.prevent="submit">
                    <div class="form-group">
                        <label>Récapitulatif du Projet</label>
                        <ckeditor  :editor="editor" v-model="letter.recapitulatif" :config="editorConfig"></ckeditor>
                    </div>
                    <div class="form-group">
                        <label for="canaux">Actualités et Mises à Jour</label>
                        <ckeditor :editor="editor" v-model="letter.actu" :config="editorConfig"></ckeditor>
                    </div>
                    <div class="form-group">
                        <label for="canaux">Progression Financière</label>
                        <ckeditor :editor="editor" v-model="letter.progression" :config="editorConfig"></ckeditor>
                    </div>
                    <div class="form-group">
                        <label for="canaux">Analyse du Marché</label>
                        <ckeditor :editor="editor" v-model="letter.analyse" :config="editorConfig"></ckeditor>
                    </div>
                    <div class="form-group">
                        <label for="canaux">Témoignage ou Étude de Cas</label>
                        <ckeditor :editor="editor" v-model="letter.temoignage" :config="editorConfig"></ckeditor>
                    </div>
                    <div class="form-group">
                        <label for="canaux">Prochains Objectifs</label>
                        <ckeditor :editor="editor" v-model="letter.objectifs" :config="editorConfig"></ckeditor>
                    </div>
                    <div class="form-group">
                        <label for="canaux">Appel à l'Action</label>
                        <ckeditor :editor="editor" v-model="letter.appel" :config="editorConfig"></ckeditor>
                    </div>
                    <div class="form-group">
                        <label for="canaux">Conclusion</label>
                        <ckeditor :editor="editor" v-model="letter.conclusion" :config="editorConfig"></ckeditor>
                    </div>
                    <div class="form-group">
                        <label for="">Document (PDF ou EXCEL)</label>
                        <input type="file" class="form-control" name="file" id="file" @change="fileChange">
                    </div>
                    <div class="text-center mt-4">
                        <div class="form-group">
                            <button type="submit" class="btn btn-success btn-sm"><i class="bi bi-send"></i> Envoyer</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>     
</template>
<script>
import { createToaster } from "@meforma/vue-toaster";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
export default {
    name:"EditNewsLetter",
    props: {
        dossier:{
            type:Object,
        },
    },
    data(){
        return {
        loading: false,
        toaster: createToaster({ position:'top-right'}),
        editor: ClassicEditor,
        editorConfig: this.editorConfig,
        letter:{},
        file:null
        
        }
    },
    methods:{
        fileChange(event){
                this.file = event.target.files[0];
        },
        submit(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
            });
                this.letter.dossier = this.dossier;
                console.log(this.letter);
                let form = new FormData();
                if(this.file!=null){
                    form.append("file",this.file);
                }
                form.append('letter',JSON.stringify(this.letter))
                axios.post(this.path+'news-letter',form)
                .then((res)=>{
                    console.log(res.data);
                    document.getElementById('nlclose').click();
                    this.$emit('ended');
                })
                .catch((err)=>{
                    this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
                    console.log(err)
                })
                .finally(()=>loader.hide())

        },
    },
    watch:{
            data(newValue,oldValue){
                console.log(oldValue);
                console.log(newValue);
               // console.log(this.data);
                //this.dossier = this.data;
                console.log(this.dossier);
            }
        },
    mounted(){
        //this.dossier = this.data;
       // console.log(this.data);
    }

}
</script>
  