<template>
<div class="modal fade" id="evolutionModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title" id="exampleModalLabel">EVOLUTION DU MARCHE</h6>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="close3" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form class="form" @submit.prevent="submit" >
                    
                        <div class="form-group">
                            <div v-html="dossier.presentation_evolution_marche"></div>
                            <hr/>
                            <ckeditor :editor="editor" v-model="presentation_evolution_marche" :config="editorConfig"></ckeditor>
                            
                        </div>
                        <div class="form-group mb-2">
                            <input type="file" class="form-control" name="file" id="file" @change="fileChange">
                        </div>
                       <button class="btn btn-success" type="submit">ENREGISTRER</button>
                 </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    export default{
        name:'EvolutionComponent',
        props:{
            dossier:{
                'type':Object,
                'required':true,
            }
        },
        data(){
            return {
                presentation_evolution_marche:this.dossier.presentation_evolution_marche,
                editor: ClassicEditor,
                editorConfig: this.editorConfig,
                file:null,
                toaster: createToaster({ position:'top-right'}),

            }
        },
        methods:{
            fileChange(event){
                this.file = event.target.files[0];
            },
            submit(){
                document.getElementById('close3').click();
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
                let form = new FormData();
                if(this.file!=null){
                    form.append("file",this.file);
                }
                form.append("presentation_evolution_marche",this.presentation_evolution_marche);
                form.append("seed_id",this.dossier.id);
                axios.post(this.path+'owner/seeds/evolution_marche',form,{
                    headers:{
                        'Accept':'multipart/form-data'
                    }
                })
                .then((res)=>{
                    console.log(res)
                    this.$emit('ended');
                })
                .catch((err)=>{
                    this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
                    console.log(err)
                })
                .finally(()=>loader.hide())
            }
        }
    }

</script>

<style scoped>


</style>