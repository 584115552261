<template>
<div class="modal fade" id="problematiqueModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">LA PROBLEMATIQUE</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="close1" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form enctype="multipart/form-data" class="form"  @submit.prevent="submit" method="post">
         
                    
                    <div class="form-group">
                        <label for="">Presentation de la Problematique</label>
                         <div v-html="dossier.presentation_problematique"></div>
                         <hr/>
                        <ckeditor :editor="editor" v-model="presentation_problematique" :config="editorConfig"></ckeditor>
                        
                    </div>
                    <div class="form-group">
                        <label for="">Fichier de segments clients</label>
                        <input type="file" class="form-control" name="file" id="file" @change="fileChange">
                    </div>
                    <button class="btn btn-success" type="submit">ENREGISTRER</button>
                </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
export default{
    name:'ProblematiqueComponent',
    components:{
    },
    props:{
        dossier:{
                'type':Object,
                'required':true,
            }
    },
    data(){
        return {
            presentation_problematique:"",
            file:null,
            segments:this.dossier.segments,
            toaster: createToaster({ position:'top-right'}),

            editor: ClassicEditor,
            editorConfig: this.editorConfig,
            }
        },
        methods:{
            fileChange(event){
                //console.log(this.dossier)
                this.file = event.target.files[0];
            },
            checkStep() {
                //let toaster = createToaster({ position:'top-right'});
                if(!this.solvable){
                    this.toaster.error("Votre solde est insuffisant pour achever cette etape ! Veuillez vous rapprocher de votre gestionaire pour crediter votre compte!");
                }
            },
            submit(){
                if(this.solvable){
                    document.getElementById('close1').click();
                    let loader = this.$loading.show({
                        container: this.fullPage ? null : this.$refs.formContainer,
                        canCancel: false,
                    });
                    let form = new FormData();
                    if(this.file!=null){
                        form.append("fichier",this.file);
                    }
                    form.append("presentation_problematique",this.presentation_problematique);
                    form.append("preseed_id",this.dossier.id);
                    axios.post(this.path+'owner/preseeds/problematique',form,{
                        headers:{
                            'Accept':'multipart/form-data'
                        }
                    })
                    .then((res)=>{
                        console.log(res)
                        this.$emit('ended');
                    })
                    .catch((err)=>{
                        this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
                        console.log(err)
                    })
                    .finally(()=>loader.hide())
                }else{
                    this.toaster.error("Votre solde est insuffisant pour achever cette etape ! Veuillez vous rapprocher de votre gestionaire pour crediter votre compte!");
                }
            }
        },
        mounted(){
            this.presentation_problematique = this.dossier.presentation_problematique;
            //console.log(this.solvable);
        },
        computed:{
            solvable: function(){
                return this.$store.state.auth.entreprise.step_cost_preseed<this.$store.state.auth.user.solde;
            }
        }
    }

</script>