<template>
    <main id="main">

        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <div class="d-flex justify-content-between">
                    <div>
                        <ol>
                        <li><a href="/">{{ entreprise?entreprise.name:'-'  }}</a></li>
                        <li>Accueil</li>
                        </ol>
                        <h2 class="text-success">Hello <span class="text-gray text-lg">{{ user.name }}</span></h2>
                    </div>
                    <div>
                        <span>Votre solde est de : <strong>{{ entreprise.solde }}</strong></span>
                    </div>
                </div>
                

            </div>
        </section><!-- End Breadcrumbs -->

        <!-- ======= Contact Section ======= -->
        <section id="contact" class="contact">
            <div class="container">

            <div class="d-flex gap-3 justify-content-center">
                <div class="bg-light-green-darken-4">
                    <router-link to="/cga/pmes">
                        <div class="info-box mb-4">
                            <div>
                                <i class="bx bxs-report"></i>
                            </div>
                            <p>DOSSIERS PME</p>
                        </div>
                    </router-link>
                </div>

                <div class="bg-light-blue-darken-4">
                    <router-link to="/cga/series">
                        <div class="info-box mb-4">
                            <div>
                                <i class="bx bxs-report"></i>
                            </div>
                            <p>DOSSIERS SERIE A</p>
                        </div>
                    </router-link>
                </div>


                <div class="bg-info">
                    <router-link to="/profil">
                        <div class="info-box mb-4">
                            <div>
                                <i class="bx bx-pencil"></i>
                            </div>
                            <p>Editer le profil</p>
                        </div>
                    </router-link>
                </div>
                <div class="bg-dark-subtle text-white">
                    <a href="javascript:void(0)" @click="logout">
                        <div class="info-box mb-4">
                       <div>
                        <i class="bx bx-power-off"></i>
                       </div>
                        <p>Se deconnecter</p>
                    </div>
                    </a>
                </div>
            </div>
        </div>
    </section><!-- End Contact Section -->

</main><!-- End #main -->
</template>

<script>
import {mapActions} from 'vuex'
import axios from "axios";
export default {
    name:"DashboardMember",
    components: {
        
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            entreprise:this.$store.state.auth.entreprise,
        }
    },
    methods:{
        load(){
            console.log(this.$store.state.auth.entreprise);
        },
        ...mapActions({
                signOut:"auth/signOut"
            }),
            async logout(){
                await axios.post(this.path+'auth/logout').then(()=>{
                    this.signOut()
                    this.authenticated = false;
                    window.location.href="/"
                })
            },
        async afterLogout(){
            await this.signOut()
                    .then(()=>window.location.href='/')
        }
    },
    mounted(){
        this.load();
    }

}
</script>

<style scoped>
   
    div.info-box a{
        color : white;
    }

    div.info-box {
        background-color: inherit;
        width:240px;
        padding:20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    div.info-box:hover{
        width: 242px;
    }

    

    div.info-box i{
        width: 55px;
        color:#fff;
        font-weight: 800;
    }

    div.info-box i:hover{
        width: 62px;
        border: 3px #d45 solid;
    }

    div.info-box p{
        color:#fff;
        font-weight: 800;
    }

    div.d-flex div{
        border-radius: 10px;
    }

</style>
