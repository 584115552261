<template>
<div class="modal fade" id="planFinancierModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">PRESENTATION DU PLAN FINANCIER</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="close12" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form enctype="multipart/form-data" class="form"  @submit.prevent="submit" method="post">
                    <div class="form-group">
                        <label for="">compte d'exploitation previsionnel</label>
                        <input type="file" class="form-control" name="file" id="file" @change="fileResultatChange">
                    </div>
                    <div class="form-group">
                        <label for="">Bilan previsionnel</label>
                        <input type="file" class="form-control" name="file" id="file" @change="fileBilanChange">
                    </div>
                    <div class="form-group">
                        <label for="">Flux de treso previsionnel</label>
                        <input type="file" class="form-control" name="file" id="file" @change="fileTresoChange">
                    </div>
                    <button class="btn btn-success" type="submit">ENREGISTRER</button>
                </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default{
    name:'PlanFinacierComponent',
    components:{
    },
    props:{
        dossier:{
                'type':Object,
                'required':true,
            }
    },
    data(){
        return {
            resultat:null,
            bilan:null,
            treso:null,
            toaster: createToaster({ position:'top-right'}),
            editor: ClassicEditor,
            editorConfig: this.editorConfig,


            }
        },
        methods:{
            fileResultatChange(event){
                this.resultat = event.target.files[0];
            },
            fileBilanChange(event){
                this.bilan = event.target.files[0];
            },
            fileTresoChange(event){
                this.treso = event.target.files[0];
            },
            submit(){
                document.getElementById('close12').click();
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
                let form = new FormData();
                if(this.resultat!=null){
                    form.append("resultat",this.resultat);
                }
                if(this.bilan!=null){
                    form.append("bilan",this.bilan);
                }
                if(this.treso!=null){
                    form.append("treso",this.treso);
                }
                form.append("seed_id",this.dossier.id);
                axios.post(this.path+'owner/seeds/plan-financier-previsionnel',form,{
                    headers:{
                        'Accept':'multipart/form-data'
                    }
                })
                .then((res)=>{
                    console.log(res)
                    this.$emit('ended');
                })
                .catch((err)=>{
                    if(err.response.status == 411){
                        this.toaster.error("Echec d'enregistrement. L'extension d'un des fichiers n'est pas au format Excel !");
                    }else{
                        this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
                    }
                    console.log(err)
                })
                .finally(()=>loader.hide())
            }
        },
        mounted(){
            this.presentation_problematique = this.dossier.presentation_problematique;
            //this.segments = this.dossier.segments;
        }
    }

</script>