<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>GESTION DU RISQUE</li>
                </ol>
                <h2>{{ pligne.risque.name }}</h2>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-sm-12">
                    <div class="card">
                    <form @submit.prevent="save">
                        <div class="card-body table-responsive">
                            <div class="form-group">
                                <label for="budget">Budget</label>
                                <input type="number" class="form-control" v-model="pligne.budget" id="budget" >
                            </div>
                            <div class="form-group">
                                    <label for="amelioration">Amelioration</label>
                                    <textarea v-model="pligne.amelioration" id="amelioration" cols="10" rows="2" class="form-control"></textarea>
                            </div>
                            <div class="form-group">
                                    <label for="objectif">Objectif</label>
                                    <ckeditor :editor="editor" v-model="pligne.objectif" :config="editorConfig"></ckeditor>
                            </div>
                            <div class="row">
                                    <div class="col-md-5 col-sm-12">
                                        <label for="demarrage">DATE DE DEMARRAGE :
                                            <input type="date" class="form-control" v-model="pligne.demarrage">
                                        </label>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <label for="demarrage">ECHEANCE :
                                            <input type="date" class="form-control" v-model="pligne.echeance">
                                        </label>
                                    </div>
                                    <div class="col-md-2 col-sm-12">
                                        <label for="demarrage">RECURRENT
                                        <input id="reccurent" type="checkbox"   v-model="pligne.recurrent">
                                        </label>
                                    </div>
                            </div>
                                <div class="form-group">
                                    <label for="pv">PROCESS VERBAL</label>
                                    <ckeditor :editor="editor" v-model="pligne.pv" :config="editorConfig"></ckeditor>
                                </div>

                                <div class="card-footer">
                                    <button class="btn btn-success" id="btn-save" type="submit">ENREGISTER <i class="fa fa-save"></i></button>
                                </div>

                        </div>
                    </form>
                        <!-- /.card-body -->
                    </div>
                </div>
            </div>
        </div>
    </main>


</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name:"PligneComponent",
    props: ['tkn'],
    components:{
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            dossier:{},
            pligne:{
                risque:{
                    name:''
                }
            },
            toaster: createToaster({ position:'top-right'}),
            editor: ClassicEditor,
            editorConfig: this.editorConfig,
            componentKey: 0,
        }
    },
    methods:{
        setData(data){
            this.pligne = data;

        },
        async save(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.post(this.staging+'pligne',this.pligne)
                        .then((res)=>{
                            console.log(res.data);
                          //  this.setData(res.data)
                            this.toaster.success("Enregistrement effectue avec succes !!!");
                        })
                        .catch((err)=>{
                                console.log(err);
                                this.toaster.error("Le chargement de l'action a echoue !!!");
                            })
                        .finally(()=>loader.hide());
        },
        async load(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.get(this.staging+'pligne/'+this.tkn)
                        .then((res)=>{
                            console.log(res.data);
                            this.setData(res.data)
                            
                        })
                        .catch((err)=>{
                                console.log(err);
                                this.toaster.error("Le chargement de l'action a echoue !!!");
                            })
                        .finally(()=>loader.hide());
        },
    },
    mounted(){
        this.load().then(()=>console.log(this.dossier))
        
    }

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    a {
        color: #3d9970;
        
    }
    .infos p{
        margin-bottom: 2px;
        font-size: smaller;
    }
    .table thead th{
        font-size: smaller;
    }
</style>