<template>
       <main class="main"> 
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>MES ENTREPRISES</li>
                <li>NOUVELLE ENTREPRISE</li>
                </ol>
                <h2 class="title">NOUVELLE ENTREPRISE</h2>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container d-flex justify-center">
            <div class="card" style="max-width: 600px;">
                <div class="card-body">
                  <div class="form-group">
                    <label for="">NOM DE L'ENTREPRISE</label>
                    <input type="text" placeholder="nom" v-model="dossier.name" class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="">ADRESSE</label>
                    <input type="text" placeholder="Adresse" v-model="dossier.address" class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="">TELEPHONE</label>
                    <input type="text" placeholder="TELEPHONE" v-model="dossier.phone" class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="">EMAIL</label>
                    <input type="text" placeholder="Adresse Email" v-model="dossier.infoemail" class="form-control">
                  </div>
                  <div class="">
                    <div class="form-group">
                      <label for="">DESCRIPTION</label>
                      <ckeditor class="form-control" :editor="editor" v-model="dossier.description" :config="editorConfig"></ckeditor>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="">LOGO</label>
                    <input type="file"  @change="handlePhoto" class="form-control">
                  </div>
                  <fieldset>
                    <legend>COMPTE ADMINISTRATEUR</legend>
                    <div class="form-group">
                      <label for="">NOM</label>
                      <input type="text" placeholder="Nom de l'administrateur du compte" v-model="dossier.lastname" class="form-control">
                    </div>
                    <div class="form-group">
                      <label for="">PRENOM</label>
                      <input type="text" placeholder="Prenom de l'administrateur du compte" v-model="dossier.firstname" class="form-control">
                    </div>
                    <div class="form-group">
                      <label for="">TELEPHONE</label>
                      <input type="text" placeholder="TEL" v-model="dossier.userphone" class="form-control">
                    </div>
                    <div class="form-group">
                      <label for="">EMAIL</label>
                      <input type="email" placeholder="Adresse email" v-model="dossier.email" class="form-control">
                    </div>
                    <div class="form-group">
                      <label for="">MOT DE PASSE</label>
                      <input type="password" placeholder="Mot de passe" v-model="dossier.password" class="form-control">
                    </div>
                  </fieldset>
                </div>
                <div class="card-footer text-center">
                    <button type="button" @click="submit()" class="btn btn-success"><i class="bx bx-save"></i> ENREGISTRER</button>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
  import axios from 'axios';
  import { createToaster } from "@meforma/vue-toaster";
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  export default {
    components: {
    },
    name: "CreateDossier",
    data() {
      return {
        toaster: createToaster({ position:'top-right'}),
        editor: ClassicEditor,
        editorConfig: this.editorConfig,
        dossier:{},
        photo:null,
      };
    },
  
    mounted(){
      
    },
    methods: {
      handlePhoto(event){
        this.photo = event.target.files[0];
      },
      async submit(){
        let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            let form = new FormData();
            if(this.photo!=null){
              form.append("photo",this.photo);
            }
            form.append('entreprise',JSON.stringify(this.dossier));    
            await axios.post(this.path+'supplier/entreprises',form)
                        .then((res)=>{
                            this.dossier = res.data;
                            this.toaster.success("Creation de dossier effectuee avec succes !!!");
                            this.$router.push({name:'supplier_entreprises_index'})
                        })
                        .catch((err)=>{
                          console.error(err);
                          this.toaster.error("Echec de creation de l'entreprises!");
                        })
                        .finally(()=>loader.hide());
      },
    },
  };
  </script>

<style scoped>
  
    .bg{
      background-color: #efefef;
    }
    .steppy-item-counter {
        height: 40px !important;
        width: 40px !important;
        border: none !important;
      }
      
      .steppy-item-counter .number {
        font-size: 1rem !important;
      }
      
      .controls {
        display: flex !important;
        flex-direction: row !important;
        gap: 2rem !important;
      }
      
      .controls .btn {
        align-self:normal !important;
        background-color: #3d7;
      }

      input[type='radio']:checked:after {
        background: #3d9970 !important;
      }
      
      .v-container div.controls .btn--default-2 {
        margin: auto !important;
        text-align: center !important;
        background: #3d9970 !important;
        border-radius: 4px !important;
        border: none !important;
        height: 40px !important;
        width: 40% !important;
        font-size: 1rem !important;
        color: #000407 !important;
        font-weight: bold !important;
      }

      .btn[data-v-bd8cdabb] {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 16px;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        line-height: 1.5;
        transition: all .15s;
        border-radius: 4px;
        width: fit-content;
        font-size: .75rem;
        color: #fff;
        background-color: #3d9970;
        border: 1px solid #f0f0f0;
      }
</style>