<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Dossiers PRESEED</li>
                </ol>
                <h2>GESTION DES DOSSIERS PRESEED</h2>

            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="mb-2">
                <input @input="search" type="text" style="max-width: 400px;" class="form-control p-1 bg-success-subtle" placeholder="Rechercher ...">
            </div>
            <table class="table table-sm table-bordered">
                <thead>
                    <tr>
                        <th>NOM</th>
                        <th>CONSULTANT</th>
                        <th>PROMOTEUR</th>
                        <th>PROGRESSION</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(dossier,index) in filtered" :key="index">
                        <td>
                            <router-link :to="{name:'admin_preseed', params: {tkn: dossier.token}}">{{ dossier.name }}</router-link>
                        </td>
                        <td>{{ dossier.consultant?dossier.consultant.name:'-' }}</td>
                        <td>{{ dossier.owner?dossier.owner.name:'-' }}</td>
                        <td> <span :class="'badge badge-'+dossier.progression.color"  style="padding:5px; ">  {{ dossier.progression.per }} %</span></td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
    </main>
</template>
<script>
import axios from "axios";
export default {
    name:"IndexPreseed",
    data(){
        return {
            user:this.$store.state.auth.user,
            dossiers:[],
            filtered:[],
        }
    },
    methods:{
        async load(){
            await axios.get(this.path+'revendeur/preseeds')
                        .then((res)=>{
                            this.dossiers=res.data
                            this.filtered = res.data
                        })
                        .catch((err)=>console.error(err));
        },

        search(event){
            {
                //console.log(event.target.value.trim())
                setTimeout(() => {
                    if (!event.target.value.trim().length) {
                        this.filtered = [...this.dossiers];
                    } else {
                        this.filtered = this.dossiers.filter((item) => {
                            return item.name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.owner.name.toLowerCase().startsWith(event.target.value.toLowerCase())

                        });
                        //console.log(this.filtered)
                    }
                }, 250);
            }
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.dossiers))
        
    },

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    a{
        color: #3d9970;
        text-decoration: none;
    }
</style>