<template>
<div class="modal fade" id="modeleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">DESCRIPTION DU BESOIN</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
                <div class="modal-body">
                    <form @submit.prevent="submit">
                <input type="hidden" v-model="modele.id">
                <div class="form-group">
                    <label>OFFRE</label>
                    <ckeditor :editor="editor" v-model="modele.offre" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                    <label for="segment">SEGMENTS DE CLIENTELE</label>
                    <ckeditor :editor="editor" v-model="modele.segment" :config="editorConfig"></ckeditor>
                    
                </div>
                <div class="form-group">
                    <label for="canaux">CANAUX DE DISTRIBUTION </label>
                    <ckeditor :editor="editor" v-model="modele.canaux" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                    <label for="relation">RELATION CLIENTS </label>
                    <ckeditor :editor="editor" v-model="modele.relation" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                    <label for="partenaires">PARTENAIRES CLES</label>
                    <ckeditor :editor="editor" v-model="modele.partenaires" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                    <label for="activites">ACTIVITES CLES </label>
                    <ckeditor :editor="editor" v-model="modele.activites" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                    <label for="ressources">RESSOURCES CLES </label>
                    <ckeditor :editor="editor" v-model="modele.ressources" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                    <label for="couts">COUTS</label>
                    <ckeditor :editor="editor" v-model="modele.couts" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                    <label for="revenus">REVENUS</label>
                    <ckeditor :editor="editor" v-model="modele.revenus" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                    <button type="submit" data-bs-dismiss="modal" class="btn btn-success btn-sm">ENREGISTRER</button>
                </div>
            </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    export default{
        name:'ModeleEcoComponent',
        props:{
            data:{
                type:Object,
            },
        },
        data(){
            return {
                editor: ClassicEditor,
                editorConfig: this.editorConfig,
                modele:{},

            }
        },
        methods:{
            submit(){
                   // document.getElementById('closecol').click();
                    let loader = this.$loading.show({
                        container: this.fullPage ? null : this.$refs.formContainer,
                        canCancel: false,
                    });
                    axios.post(this.path+'cga/serie/modele',this.modele,{
                        headers:{
                            'Accept':'multipart/form-data'
                        }
                    })
                    .then((res)=>{
                        console.log(res)
                        this.$emit('success');
                    })
                    .catch((err)=>{
                        this.$emit('error')
                        //this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
                        console.log(err)
                    })
                    .finally(()=>loader.hide())
                
            }
        },
        computed:{
        },
        watch:{
            data(newValue,oldValue){
                console.log(oldValue)
                console.log(newValue)
                this.modele = this.data;
            }
        },
        mounted(){
            this.modele = this.data;
        }
    }

</script>

<style scoped>


</style>