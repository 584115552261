<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>ENTREPRISES </li>
                <li>{{ entreprise.name }} </li>
                </ol>
                <div class="d-flex">
                    <h2>{{ entreprise.name }}</h2>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            
        </div>
    </main>
</template>
<script>
import axios from "axios";

import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"ShowEntreprise",
    props: ['tkn'],
    components:{
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            entreprise:{},
            toaster: createToaster({ position:'top-right'}),
            componentKey: 0,
        }
    },
    methods:{
        
        async load(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.get(this.path+'supplier/entreprises/'+this.tkn)
                        .then((res)=>{
                            this.entreprise = res.data;
                        })
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        async reload(){
            await axios.get(this.path+'supplier/entreprises/'+this.tkn)
                        .then((res)=>{
                            this.entreprise = res.data;
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.entreprise))
        
    }

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: var(--bs-nav-pills-link-active-color);
        background-color: #3d9970;
    }
    a {
        color: #3d9970;
        
    }
    .infos p{
        margin-bottom: 2px;
        font-size: smaller;
    }
    .table thead th{
        font-size: smaller;
    }
</style>