<template>
<div class="modal fade" id="membreModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">NOUVEAU MEMBRE</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="close7" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form class="form" @submit.prevent="submit" >
                    <div class="form-group">
                            <input type="text" required class="form-control" v-model="first_name" placeholder="Prenom">
                        </div>
                        <div class="form-group">
                            <input type="text" required class="form-control" v-model="last_name" placeholder="Nom">
                        </div>
                        <div class="form-group">
                            <input type="text" required class="form-control" v-model="fonction" placeholder="Fonction">
                        </div>
                        <div class="form-group">
                            <label for="">PHOTO</label>
                            <input type="file" required class="form-control" name="photo" @change="photoChange">
                        </div>
                        <div class="form-group">
                            <label for="">CV</label>
                            <input type="file" required class="form-control" name="cv" @change="cvChange">
                        </div>
                       <button class="btn btn-success" type="submit">AJOUTER</button>
                 </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default{
    name:'MembreComponent',
    props:{
            dossier:{
                'type':Object,
                'required':true,
            }
        },
        data(){
            return {
                first_name:'',
                last_name:'',
                fonction:'',
                cv:null,
                photo:null,
                toaster: createToaster({ position:'top-right'}),
                editor: ClassicEditor,
                editorConfig: this.editorConfig

                }
        },
        methods:{
            cvChange(event){
                this.cv = event.target.files[0];
            },
            photoChange(event){
                this.photo = event.target.files[0];
            },
            submit(){
                document.getElementById('close7').click();
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
                let form = new FormData();
                if(this.photo!=null){
                    form.append("photo",this.photo);
                }
                if(this.cv!=null){
                    form.append("cv",this.cv);
                }

                form.append("first_name",this.first_name);
                form.append("last_name",this.last_name);
                form.append("fonction",this.fonction);
                form.append("seed_id",this.dossier.id);
                axios.post(this.path+'owner/seeds/add/member',form,{
                    headers:{
                        'Accept':'multipart/form-data'
                    }
                })
                .then((res)=>{
                    console.log(res)
                    this.$emit('ended');
                })
                .catch((err)=>{
                    this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
                    console.log(err)
                })
                .finally(()=>loader.hide())
            }
        }
    }

</script>

<style scoped>


</style>