<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>GESTION DES ENTREPRENEURS</li>
                </ol>
                <h2>{{ porteur.name }}</h2>
                <div class="">
                    <div class="dropdown">
                        <a class="dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Actions</a>
                        <ul class="dropdown-menu">
                            
                        </ul>
                    </div>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="row">
                    <div id="side1" class="col-md-12 col-sm-12" >
                        <div class="card" style="background:#f7f7f7;">
                            <div class="card-body">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-12" style="display: flex;">
                                            <div style="height: auto; display: flex; ">
                                                <img class="img-circle img-rounded img-bordered" style="width: 100px; height: 100px; align-self: center;"  :src="porteur.photo" alt="">
                                            </div>
                                        </div>
                                        <div class="col-md-8 col-sm-12 infos">
                                            <p>NOM : <span class="text-bold">{{ porteur.last_name }}</span></p>
                                            <p>PRENOM : <span class="text-bold">{{ porteur.first_name }}</span></p>
                                            <p>AGE : <span class="text-bold">{{ porteur.age }}</span></p>
                                            <p>DOSSIERS PRESEED : <span class="text-bold">{{ preseeds.length }}</span></p>
                                            <p>DOSSIERS SEED : <span class="text-bold">{{ seeds.length }}</span></p>
                                            <p>DERNIERE CONNECTION : <span class="value"> {{ porteur.last_connection }}</span></p>

                                        </div>
                                        <div style="display: flex;" class="col-md-2 col-sm-12">
                                            <div style="display: flex; align-self: center;">
                                                <v-chip style="align-self: center; background-color: green; color:#f7f7f7;font-weight: bold;">
                                                    {{ porteur.solde }} POINT(S)
                                                </v-chip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
            </div>
            <div>
                <div>
                    <ul class="nav nav-pills" id="custom-tabs-four-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="preseed-tab" data-bs-toggle="tab" data-bs-target="#preseed-pane" type="button" role="tab" aria-selected="true" data-toggle="pill" href="" aria-controls="custom-tabs-four-home">PRESEED</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="seed-tab"  data-bs-toggle="tab" data-bs-target="#seed-pane" type="button" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">SEED</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="serie-tab"  data-bs-toggle="tab" data-bs-target="#serie-pane" type="button" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">SERIE <A:blank></A:blank></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pme-tab"  data-bs-toggle="tab" data-bs-target="#pme-pane" type="button" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">PME</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="recharge-tab"  data-bs-toggle="tab" data-bs-target="#recharge-pane" type="button" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">RECHARGES</a>
                        </li>
                    </ul>
                </div>
                <div class="tab-content">
                    <div class="tab-pane fade show active" id="preseed-pane" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                        <div class="container">
                            <div class="row">
                                <div v-for="(dossier,index) in preseeds" :key="index" class="col-md-3 col-sm-12">
                                    <div>
                                         <v-card
                                                        :loading="loading"
                                                        class="mx-auto my-12"
                                                        max-width="374"
                                                    >

                                                        <v-img
                                                        height="250"
                                                        :src="dossier.photo"
                                                        cover
                                                        ></v-img>

                                                        <v-card-title>{{ dossier.name }}</v-card-title>

                                                        <v-card-text>
                                                        <div class="my-4 text-subtitle-1">
                                                           <p> <i class="bx bx-user"></i> <span class="text-bold">{{ dossier.owner.name }}</span> </p>
                                                           <p> <i class="bx bx-user-check"></i> <span class="text-bold">{{ dossier.consultant?dossier.consultant.name:'-' }}</span> </p>
                                                           <p> <i class="bx bx-category text-success"></i> <span class="text-success">{{ dossier.secteur?dossier.secteur.name:'-' }}</span> </p>
                                                            <p> 
                                                                <v-progress-linear
                                                                v-model="dossier.progression.per"
                                                                :color="dossier.progression.color"
                                                                height="20"
                                                                >
                                                                <strong>{{ dossier.progression.per }}%</strong>
                                                                </v-progress-linear> 
                                                            </p>
                                                        </div>
                                                        </v-card-text>

                                                        <v-card-actions>
                                                            <v-chip>
                                                                <router-link :to="{name:'consultant_preseed', params: {tkn: dossier.token}}">Afficher <i class="bx bx-show"></i></router-link> 
                                                            </v-chip>
                                                        </v-card-actions>
                                                    </v-card>
                                            </div>
                                </div>
                            </div>
                        </div>         
                    </div>
                    <div class="tab-pane fade" id="seed-pane" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                        <div class="container">
                            <div class="row">
                                <div v-for="(dossier,index) in seeds" :key="index" class="col-md-3 col-sm-12">
                                    <div>
                                         <v-card
                                                        :loading="loading"
                                                        class="mx-auto my-12"
                                                        max-width="374"
                                                    >

                                                        <v-img
                                                        height="250"
                                                        :src="dossier.photo"
                                                        cover
                                                        ></v-img>

                                                        <v-card-title>{{ dossier.name }}</v-card-title>

                                                        <v-card-text>
                                                        <div class="my-4 text-subtitle-1">
                                                           <p> <i class="bx bx-user"></i> <span class="text-bold">{{ dossier.owner.name }}</span> </p>
                                                           <p> <i class="bx bx-user-check"></i> <span class="text-bold">{{ dossier.consultant?dossier.consultant.name:'-' }}</span> </p>
                                                           <p> <i class="bx bx-category text-success"></i> <span class="text-success">{{ dossier.secteur?dossier.secteur.name:'-' }}</span> </p>
                                                            <p> 
                                                                <v-progress-linear
                                                                v-model="dossier.progression.per"
                                                                :color="dossier.progression.color"
                                                                height="20"
                                                                >
                                                                <strong>{{ dossier.progression.per }}%</strong>
                                                                </v-progress-linear> 
                                                            </p>
                                                        </div>
                                                        </v-card-text>

                                                        <v-card-actions>
                                                            <v-chip>
                                                                <router-link :to="{name:'consultant_preseed', params: {tkn: dossier.token}}">Afficher <i class="bx bx-show"></i></router-link> 
                                                            </v-chip>
                                                        </v-card-actions>
                                                    </v-card>
                                            </div>
                                </div>
                            </div>
                        </div>         
                    </div>
                    <div class="tab-pane fade" id="serie-pane" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                                   
                    </div>
                    <div class="tab-pane fade" id="pme-pane" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                                   
                    </div>
                    <div class="tab-pane fade" id="recharge-pane" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                                   
                    </div>
                </div>
            </div>
        </div>
    </main>






</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"ShowPorteur",
    props: ['tkn'],
    components:{
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            porteur:{},
            preseeds:[],
            seeds:[],
            series:[],
            pmes:[],
            recharges:[],
            toaster: createToaster({ position:'top-right'}),

        }
    },
    methods:{
        
        setData(data){
            this.porteur = data.porteur;
            this.preseeds = data.preseeds;
            this.seeds = data.seeds,
            this.series = data.series,
            this.pmes = data.pmes,
            this.recharges = data.recharges
        },
        async load(){
            let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    //onCancel: this.onCancel,
                });
            await axios.get(this.path+'revendeur/porteurs/'+this.tkn)
                        .then((res)=>this.setData(res.data))
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        async reload(){
            await axios.get(this.path+'revendeur/porteurs/'+this.tkn)
                        .then((res)=>{
                            this.setData(res.data)
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.dossier))
        
    }

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: var(--bs-nav-pills-link-active-color);
        background-color: #3d9970;
    }
    a {
        color: #3d9970;
        text-decoration: none;
        
    }
    .infos p{
        margin-bottom: 2px;
        font-size: smaller;
    }
    .table thead th{
        font-size: smaller;
    }
</style>