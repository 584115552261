<template>
    <ul>
        <li><router-link to="/cga/dashboard"> ACCUEIL</router-link></li>
            <li class="dropdown">
                <a class="">
                    <div class="text-center">
                        <p class="m-0">DOSSIERS<i class="bi bi-chevron-down"></i></p>
                    </div>
                </a>
                <ul>
                    <li><router-link to="/cga/series">SERIE A</router-link></li>
                    <li><router-link to="/cga/pmes">PME</router-link></li>
                    <li><router-link to="/cga/dimfs">IMF</router-link></li>
                </ul>
            </li>
            <li class="dropdown">
                <a class="">
                    <div class="text-center">
                        <p class="m-0">INVESTISSEURS<i class="bi bi-chevron-down"></i></p>
                    </div>
                </a>
                <ul>
                    <li><router-link to="/cga/rdvs">RENDEZ-VOUS</router-link></li>
                    <li><router-link to="/cga/deals">DEAL FLOW</router-link></li>
                </ul>
            </li>
            <li class="dropdown">
                <a class="">
                    <div class="text-center">
                        <p class="m-0">ENTITES<i class="bi bi-chevron-down"></i></p>
                    </div>
                </a>
                <ul>
                    <li><router-link to="/cga/entreprises">ENTREPRISES</router-link></li>
                    <li><router-link to="/cga/institutions">IMF</router-link></li>
                </ul>
            </li>
            <li class="dropdown">
                <a class="">
                    <div class="text-center">
                        <p class="m-0">CREDITS<i class="bi bi-chevron-down"></i></p>
                    </div>
                </a>
                <ul>
                    <li><router-link to="/cga/achats">HISTORIQUE DES ACHATS</router-link></li>
                </ul>
            </li>
            <li><router-link to="/cga/consultants"> CONSULTANTS</router-link></li>
            <div style="display: inherit;" v-if="authenticated">
            <li class="dropdown">
                <a class="">
                    <div class="text-center user-profil">
                        <i style="font-size:25px;" class="bi bi-person"></i><i class="bi bi-chevron-down"></i>
                       
                    </div>
                </a>
                <ul>
                    <li><router-link to="/user/profil">Profil Utilisateur</router-link></li>
                    <li><router-link to="/profil">Profil Incubateur</router-link></li>
                    <li><a class="dropdown-item" href="javascript:void(0)" @click="logout">Se deconnecter</a></li>
                </ul>
            </li>
            </div>
    </ul>
</template>
<script>
    import {mapActions} from 'vuex';
    import axios from "axios";
    export default{
        name:'CgaNavbar',
        data: function(){
            return {
                authenticated:this.$store.state.auth.authenticated?this.$store.state.auth.authenticated:false,
            }
        },
        methods:{
            ...mapActions({
                signOut:"auth/signOut"
            }),
            async logout(){
                await axios.post(this.path+'auth/logout').then(()=>{
                    this.signOut()
                    this.authenticated = false;
                    window.location.href="/"
                    //this.$router.push({name:"home"})
                })
            }
        }
    }

</script>
<style scoped>
    .navbar a{
        text-decoration: none;
        font-weight: bolder;
    }

</style>