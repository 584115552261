<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>DOSSIERS IMF</li>
                </ol>
                <div class="d-flex justify-space-between">
                    <div>
                        <h2>LISTE DES DOSSIERS D'IMF</h2>
                        <input placeholder="Saisir ici pour rechercher ..." type="text" @input="search" class="form-control input-search bg-success-subtle">
                    </div>
                    <div class="mr-3"><router-link class="btn bg-green-darken-4" to="/cga/dimfs/create"><i class="bi bi-plus-square-fill" title="Creer un nouveau dossier"></i> NOUVEAU DOSSIER IMF</router-link></div>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="d-flex justify-center gap-3 flex-wrap">
                <div v-for="(dossier,index) in filtered" :key="index" class="card bg-light-green-lighten-5">
                    <div class="card-body">
                        <router-link :to="{name:'cga_dimfs_show',params:{tkn:dossier.token}}">
                            <div class="text-center">
                                <div>
                                    <img class="img img-circle" :src="dossier.institution.photo" style="height: 100px; width: 100px;" alt="">
                                </div>
                                <p>{{ dossier.name }}</p>
                                <h5>{{ dossier.montant_requis }}</h5>
                                <h6 class="text-center">{{ dossier.institution.name }}</h6>
                            </div>
                        </router-link>
                    </div>
                    <div class="card-footer">
                        <span><i class="bx bx-building"></i> {{ dossier.institution.type.name }}</span>
                    </div>
                    <div class="card-footer">
                        <button v-if="dossier.status.code==0" @click="ask(dossier.token)"  href="#" class="text-dark btn-sm btn-link">Demander l'évaluation</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import axios from "axios";
export default {
    name:"IndexPreseed",
    data(){
        return {
            user:this.$store.state.auth.user,
            entreprises:[],
            filtered:[],
        }
    },
    methods:{
        async ask(token){
            let loader = this.$loading.show();
            await axios.get(this.path+'cga/dimf/evaluation/'+token)
                        .then(()=>this.load())
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        async load(){
            await axios.get(this.path+'cga/dimfs')
                        .then((res)=>{
                            this.entreprises=res.data;
                            this.filtered = this.entreprises;
                        })
                        .catch((err)=>console.error(err));
        },
        search(event){
            let val = event.target.value;
            console.log(event.target);
            console.log(val);
            if (val.length < 1) { 
                this.filtered = this.entreprises;
             }
            if(val.length==0){
                this.filtered = this.entreprises;
            }
            this.filtered =  this.entreprises.filter(entreprise => {
                return entreprise.name.toLowerCase()
                    .startsWith(val.toLowerCase()) || entreprise.type.name.toLowerCase()
                    .startsWith(val.toLowerCase())
                })
        }
    },
    mounted(){
        this.load();
    },
    computed:{
    }

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    a{
        color: #3d9970;
        text-decoration: none;
    }

    div.card-body a {
        color: inherit;
    }

    a.btn{
        color:#fff;
    }

    .input-search{
        font-size: 0.8rem;
        padding: .2rem .7rem;
        max-width: 360px;
    }
</style>