<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Programmes</li>
                </ol>
                <h2>LISTE DES PROGRAMMES</h2>
                <div style="display: flex; justify-content: flex-start;">
                    <a class="btn btn-danger text-white btn-sm" data-bs-toggle="modal" data-bs-target="#programmeModal" href=""><i class="bi bi-plus-circle"></i> Creer un programme</a>
                    <router-link :to="{name:'admin_candidatures'}" class="btn btn-primary text-white btn-sm"><i class="bi bi-people"></i> LISTE DES CANDIDATURES</router-link>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="row">
                <div v-for="(programme,index) in programmes" :key="index" class="col-md-4 h-100">
                    <router-link :to="{name:'admin_programme', params: {tkn: programme.token}}">
                        <div class="card">
                            <div class="card-header">
                                <h5>{{ programme.name }}</h5>
                            </div>
                            <div class="card-body">
                                <img :src="programme.photo" style="height: 160px; width: 100%;" alt="">
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </main>
<div class="modal fade" id="programmeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <form @submit.prevent="saveProgramme()" method="post">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">NOUVEAU PROGRAMME</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" id="close" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Nom du programme" v-model="programme.name">
        </div>
        <div class="form-group">
            <label for="description">DESCRIPTION</label>
            <textarea  class="form-control" placeholder="Description du programme" v-model="programme.description"></textarea>
        </div>
        <div class="form-group">
            <label class="control-label">PHOTO</label>
            <input id="photo" name="imageUri" @change="handlePhoto($event)"  ref="photo" required="required" type="file"  class="form-control">
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-success">Valider</button>
      </div>
    </div>
    </form>
  </div>
</div>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"IndexProgrammes",
    data(){
        return {
            user:this.$store.state.auth.user,
            programmes:[],
            programme:{},
            toaster: createToaster({ position:'top-right'}),
            photo:null,
        }
    },
    methods:{
        async reload(){
            await axios.get(this.path+'revendeur/programmes')
                        .then((res)=>{
                        //this.setData(res.data)
                            this.programmes = res.data;
                           console.log(res.data);
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        },
        handlePhoto(event){
            this.photo = event.target.files[0];
        },
        async saveProgramme(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            const form = new FormData();
            form.append("photo",this.photo);
            form.append("programme",JSON.stringify(this.programme));    
            await axios.post(this.path+'revendeur/programmes',form)
                        .then(()=>{
                            this.reload();
                        })
                        .catch(()=>{
                            this.toaster.error("Cette operation a echoue !!!");
                        })
                        .finally(()=>{
                            loader.hide();
                            document.getElementById('close').click();
                        })
        },
        async load(){
            await axios.get(this.path+'revendeur/programmes')
                        .then((res)=>this.programmes=res.data)
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.programmes))
        
    }

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    a{
        color: #3d9970;
        text-decoration: none;
    }
</style>