<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Programmes</li>
                </ol>
                <h2>APPELS A CANDIDATURE</h2>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="row">
                <div v-for="(programme,index) in programmes" :key="index" class="col-md-4 h-100">
                    <router-link :to="{name:'owner_programme', params: {tkn: programme.token}}">
                        <div class="card">
                            <div class="card-header">
                                <h5>{{ programme.name }}</h5>
                            </div>
                            <div class="card-body">
                                <img :src="programme.photo" style="height: 160px; width: 100%;" alt="">
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"IndexProgrammes",
    data(){
        return {
            user:this.$store.state.auth.user,
            programmes:[],
            programme:{},
            toaster: createToaster({ position:'top-right'}),
            photo:null,
        }
    },
    methods:{
        async reload(){
            await axios.get(this.path+'owner/programmes')
                        .then((res)=>{
                        //this.setData(res.data)
                            this.programmes = res.data;
                           console.log(res.data);
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        },
        async load(){
            await axios.get(this.path+'owner/programmes')
                        .then((res)=>this.programmes=res.data)
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.programmes))
        
    }

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    a{
        color: #3d9970;
        text-decoration: none;
    }
</style>