<template>
    <div class="card text-center bg-light">
        <div class="card-header">
            <button :value="member.id" @click="remove($event)" class="btn btn-xs btn-danger float-right">Supprimer</button>
        </div>
        <div class="card-body">
            <img :src="member.photo" style="width:120px; height: 120px;" class="img-circle" alt="">
            <h5>{{ member.name }}</h5>
            <p>{{ member.fonction }}</p>
        </div>
        <div class="card-footer">
            <a class="text-success" :href="member.cv">Voir le CV</a>
        </div>
    </div>      
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"TeamMember",
    props: {
        member:{
            type:Object,
            default:()=>({
                name:''
            }),
        },
    },
    data(){
        return {
            id:0,
            toaster: createToaster({ position:'top-right'}),
        }
    }, 
    methods:{
       async remove(event){
        let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            this.id = event.target.value;
            await axios.get(this.path+'member/delete/'+this.id)
                        .then(()=>{
                            this.$emit('deleted');
                        })
                        .catch(()=>{
                            this.toaster.error("Cette operation a echoue !!!");
                        })
                        .finally(()=>{
                            loader.hide();
                        })
        },
    }

}
</script>
  