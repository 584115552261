import SupplierDashboard from '@/components/Supplier/Dashboard.vue'
//Composant de l'admin Fournisseur (Supplier)
import SupplierEntrepriseIndex from "@/components/Supplier/Entreprises/Index.vue"
import SupplierEntrepriseShow from "@/components/Supplier/Entreprises/Show.vue"
import SupplierEntrepriseCreate from "@/components/Supplier/Entreprises/Create.vue"
import SupplierDossierIndex from "@/components/Supplier/Dossiers/Index.vue"
import SupplierDossierShow from "@/components/Supplier/Dossiers/Show.vue"
import SupplierDossierCreate from "@/components/Supplier/Dossiers/Create.vue"

export default [
           //Routes du fournisseur
           {
            name: "supplier_dashboard",
            path: '/supplier/dashboard',
            component: SupplierDashboard,
            meta: {
                title: `Tableau de bord`,
                middleware: "auth"
            }
        },
        {
            name: "supplier_entreprises_index",
            path: '/supplier/entreprises',
            component: SupplierEntrepriseIndex,
            meta: {
                title: `Listes des entreprises`,
                middleware: "auth"
            }
        },
        {
            name: "supplier_entreprise",
            path: '/supplier/entreprise/:tkn',
            component: SupplierEntrepriseShow,
            props: true,
            meta: {
                title: `Details de l'entreprise`,
                middleware: "auth"
            }
        },
        {
            name: "supplier_entreprises_create",
            path: '/supplier/entreprises/create',
            component: SupplierEntrepriseCreate,
            meta: {
                title: `Creation de l'entreprise`,
                middleware: "auth"
            }
        },
        {
            name: "supplier_dossiers_index",
            path: '/supplier/dossiers',
            component: SupplierDossierIndex,
            meta: {
                title: `Listes des dossiers`,
                middleware: "auth"
            }
        },
        {
            name: "supplier_dossier",
            path: '/supplier/dossier/:tkn',
            component: SupplierDossierShow,
            props: true,
            meta: {
                title: `Details du Dossier`,
                middleware: "auth"
            }
        },
        {
            name: "supplier_dossiers_create",
            path: '/supplier/dossiers/create',
            component: SupplierDossierCreate,
            meta: {
                title: `Creation du dossier`,
                middleware: "auth"
            }
        },

        /* ------------------------ End of Supplier section-------------- */

]