<template>
    <template v-if="dossier.type_id==1">
        <ShowDecouvert @success="endSuccess" @error="endError" :dossier="dossier"></ShowDecouvert>
    </template>
    <template v-if="dossier.type_id==2">
        <LigneCredit @success="endSuccess" @error="endError" :dossier="dossier"></LigneCredit>
    </template>
    <template v-if="dossier.type_id==4">
        <CreditCMT @success="endSuccess" @error="endError" :dossier="dossier"></CreditCMT>
    </template>
    <template v-if="dossier.type_id==5">
        <ShowCreditBail @success="endSuccess" @error="endError" :dossier="dossier"></ShowCreditBail>
    </template>
    <template v-if="dossier.type_id==11">
        <Redressement @success="endSuccess" @error="endError" :dossier="dossier"></Redressement>
    </template>
    <div style="width:800px; margin:10px auto;" class="card">
        <div class="card-body">
            <fieldset>
                <legend>PITCHS</legend>
                <div class="d-flex justify-center gap-3">
                    <div v-if="dossier.audio_fr_uri!=null">
                        <a class="btn btn-sm bg-dark text-white" :href="getAudioFrPath">En francais</a>
                    </div>
                    <div v-if="dossier.audio_en_uri!=null">
                        <a class="btn btn-sm bg-red-darken-4 text-white" :href="getAudioEnPath">En anglais</a>
                    </div>
                    <div v-if="dossier.audio_locale_uri!=null">
                        <a class="btn btn-sm bg-green-darken-4" :href="getAudioLocalePath">En {{ dossier.locale }}</a>
                    </div>
                </div>
           </fieldset>
        </div>
    </div>
    <PeachFr :dossier="dossier" @ended="reload" />
    <PeachEn :dossier="dossier" @ended="reload" />
    <PitchLocale :langues="langues" :dossier="dossier" @ended="reload" />
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import ShowCreditBail from "./CreditBail/Show.vue";
import ShowDecouvert from "./Decouvert/Show.vue";
import LigneCredit from "./LigneCredit/Show.vue";
import Redressement from "./Redressement/Show.vue";
import CreditCMT from "./CreditCMT/Show.vue";
import PeachFr from "./PeachFr.vue";
import PeachEn from "./PeachEn.vue";
import PitchLocale from "./PeachLocale.vue";
export default {
    name:"ShowPme",
    props: ['tkn'],
    components:{
        ShowCreditBail,
        ShowDecouvert,
        LigneCredit,
        Redressement,
        CreditCMT,
        PeachFr,
        PeachEn,
        PitchLocale,
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            dossier:{
                type:{},
            },
            toaster: createToaster({ position:'top-right'}),
            langues:[],
        }
    },
    methods:{
        endSuccess(){
            this.reload();
        },
        endError(){
            this.toaster.error("Une erreur est survenue lors de l'enregistrement !");
        },
        async loadLocales(){
            await  axios.get(this.path+'langues')
                .then((res)=>{
                    console.log(res.data)
                    this.langues = res.data;
                })
                .catch((err)=>console.log(err))
        },
        async load(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.get(this.path+'cga/pmes/'+this.tkn)
                        .then((res)=>{
                            this.dossier = res.data;
                            this.loadLocales();
                        })
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        async reload(){
            await axios.get(this.path+'cga/pmes/'+this.tkn)
                        .then((res)=>{
                            this.dossier = res.data;
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.dossier))
    },
    computed:{
        getAudioFrPath(){
            return this.audio_base+this.dossier.audio_fr_uri;
        },
        getAudioEnPath(){
            return this.audio_base+this.dossier.audio_en_uri;
        },
        getAudioLocalePath(){
            return this.audio_base+this.dossier.audio_locale_uri;
        }
    },

}
</script>
<style scoped>
    .vue-pdf-embed{
        height: 360px;
        overflow: scroll;
    }
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: var(--bs-nav-pills-link-active-color);
        background-color: #3d9970;
    }
    a {
        color: #3d9970;
        
    }
    .infos p{
        margin-bottom: 2px;
        font-size: smaller;
    }
    .table thead th{
        font-size: smaller;
    }
</style>